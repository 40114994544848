import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginSuccess, apiError, ssoLoginUser } from "../../store/actions";
import {saveLoggedinUser} from "../../helpers/api_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";

const SSOLogin = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token") ? decodeURIComponent(urlParams.get("token")) : null;
        if (token) {
            dispatch(ssoLoginUser(token, history));   
            //history.push("/dashboard");       
        } else {
            console.error("SSO Login Failed");
            history.push("/login");
        }
    }, [dispatch, history]);

    return <div>Logging in...</div>;
};

export default SSOLogin;
