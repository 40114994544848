import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Widget from "./Widgets";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import ForeCastPreviewCardHeader from "../../Components/Common/ForeCastPreviewCardHeader";
import ServerPerformance from "../ServerPerformance/ServerPerformance";
import { Line, LineColumnArea } from "./MixedCharts";
import BestSellingProducts from "./BestSellingProducts";
import UiContent from "../../Components/Common/UiContent";
import {
  getKIPWidgetData,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { ColorRange } from "./HeatmapCharts";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import { getDateByName, displayDate, convertDateFormate } from "../../helpers/api_helper";
import CustomDatePicker from "../../Components/Common/CustomDatePicker";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import NewWidget from "./NewWidget";

const DashboardEcommerce = () => {
  const dispatch = useDispatch();
  document.title = "KPI Dashboard | " + process.env.REACT_APP_NAME;
  const [defaultCourse, setDefaultCourse] = useState(0);
  const [leftCustomDate, setLeftCustomDate] = useState([]);
  const [leftFilter, setLeftFilter] = useState({
    label: "Yesterday",
    value: "Yesterday",
  });
  const [rightFilter, setRightFilter] = useState({"label":"This Month","value":"ThisMonth"});
  const [rightFilterToggle, setRightFilterToggle] = useState(false);
  const [mealPeriod, setMealPeriod] = useState(0);
  const [ordersTime, setOrdersTime] = useState(false);
  const [comparisonDate, setComparisonDate] = useState([]);
  const [ordersTimeComparison, setOrdersTimeComparison] = useState([]);
  const [filtermealperiod, setFiltermealperiod] = useState([]);
  const [forecastBudget, setForecastBudget] = useState(false);
  const [forecastLastYear, setForecastLastYear] = useState(false);
  const [currency, setCurrency] = useState('$');
  
  //const [forecastHeatmapAjax, setForecastHeatmapAjax] = useState(true);
  //const [forecastHeatmapData, setForecastHeatmapData] = useState({table:[],seat:[]});

  /***********************************************/
  

  /*const [menuItemAjax, setMenuItemAjax] = useState(true);
  const [foodTribeAjax, setFoodTribeAjax] = useState(true);
  const [foodTribeData, setFoodTribeData] = useState({
    Covers: [],
    AvgCheck: [],
    CourseName: [],
  });*/
  /**********************************************/
  
  /*
  const [forecastAjax, setForecastAjax] = useState(true);
  const [forecastData, setForecastData] = useState({
      revenue:[],
      covers:[],
      revenue_cp:[],
      covers_cp:[],
      seat_occupancy:[],
      seat_occupancy_cp:[],
      average_check:[],
      average_check_cp:[],
      revPASH:[],
      revPASH_cp:[],
      revenue_max:0,
      covers_max:0,
      seat_occupancy_max:0,
      average_check_max:0,
      revPASH_max:0,
  });*/
  const [refresh, setRefresh] = useState(false);
  
  const { currentOutlet } = useSelector(state => ({
    currentOutlet: state.UserOutletGroup.currentOutlet
  }));
  
  const changeDefaultComparison = (item) => {
    if(item.value!=='Custom'){
      setRightFilterToggle(false);
    }
    setRightFilter(item);
  };
  const changeLeftFilter = (value) => {
    setLeftFilter(value);
  };
  const changeMealPeriod = (value) => {
    setMealPeriod(value);
  };
  useEffect(()=>{
    if(currentOutlet?.currency!=""){
      setCurrency(currentOutlet?.currency)
    }
  },[currentOutlet])
  
  const {
    filterData,
    filterAjax,
    widgetData,
    widgetAjax,
  } = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
    widgetData: state.Dashboard.widgetData,
    widgetAjax: state.Dashboard.widgetAjax
  }));
  useEffect(() => {
    if (filterData.date) {
      setOrdersTime(filterData.date);
    }
    if (filterData.date_compare) {
      setOrdersTimeComparison(filterData.date_compare);
    }
    if (filterData.mealperiod) {
      setFiltermealperiod(filterData.mealperiod);
    }
  }, [filterAjax, filterData]);
  


  // useEffect(() => {
  //   localStorage.setItem('Selling_items_SortType', '');
  //   localStorage.setItem('Reccomendation_SortType', '');
  //   if(leftFilter.value!=='Custom' || (leftFilter.value==='Custom' && leftCustomDate[0] && leftCustomDate[1]) || refresh){
      
  //   console.log("aaaaa",rightFilter);
  //     dispatch(      
  //       getKIPWidgetData({
  //         leftFilter: leftFilter.value==='Custom'?{from:convertDateFormate(leftCustomDate[0]),to:convertDateFormate(leftCustomDate[1])}:getDateByName(leftFilter.value),
  //         rightFilter: rightFilter.value==='Custom'?{from:convertDateFormate(comparisonDate[0]),to:convertDateFormate(comparisonDate[1])}:getDateByName(rightFilter.value),  
  //         mealPeriod: mealPeriod,
  //         recalculate: refresh,
  //       })
  //     );
  //   }
  // }, [leftFilter,leftCustomDate, rightFilter, mealPeriod,comparisonDate, refresh,  dispatch]);
  
  // useEffect(() => {
  //   postDataAPI({serviceName:POST_KPI_DASHBOARD.serviceName,op:POST_KPI_DASHBOARD.forecast_heatmap_op,mealPeriod: mealPeriod,}).then(response => {
  //     setForecastHeatmapAjax(false);
  //     if(response.status==='ok'){
  //       setForecastHeatmapData(response.data);
  //     }else{
  //       console.log(response);
  //     }
  //   });

  //   postDataAPI({serviceName:POST_KPI_DASHBOARD.serviceName,op:POST_KPI_DASHBOARD.forecast_op,mealPeriod: mealPeriod,}).then(response => {
  //     setForecastAjax(false)
  //     if(response.status==='ok'){
  //       setForecastData(response.data);
  //     }else{
  //       console.log(response);
  //     }
  //   });

  // }, [mealPeriod]);

  /*
  useEffect(() => {
    if (
      (leftFilter.value !== "Custom" ||
        (leftFilter.value === "Custom" &&
          leftCustomDate[0] &&
          leftCustomDate[1])) &&
      (rightFilter.value !== "Custom" ||
        (rightFilter.value === "Custom" &&
          comparisonDate[0] &&
          comparisonDate[1]))
    ) {
      let leftFilterData =
        leftFilter.value === "Custom"
          ? {
              from: convertDateFormate(leftCustomDate[0]),
              to: convertDateFormate(leftCustomDate[1]),
            }
          : getDateByName(leftFilter.value);
      let rightFilterData =
        activeComparisonData && activeComparisonData.datepiker
          ? {
              from: convertDateFormate(comparisonDate[0]),
              to: convertDateFormate(comparisonDate[1]),
            }
          : getDateByName(rightFilter.value);
          
      postDataAPI({
        leftFilter: leftFilterData,
        rightFilter: rightFilterData,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.food_tribes_op,
      }).then((response) => {
        setFoodTribeAjax(false);
        if (response.status === "ok") {
          setFoodTribeData(response.data);
        } else {
          console.log(response);
        }
      });
    }
  }, [
    leftFilter,
    rightFilter,
    mealPeriod,
    comparisonDate,
    activeComparisonData,
    leftCustomDate,
    defaultCourse,
  ]);*/

  const handelForecastBudget = () => {
    setForecastBudget(!forecastBudget);
  };
  const handelForecastLastYear = () => {
    setForecastLastYear(!forecastLastYear);
  };
  const handleRefresh = () => {
    // setRecalculate(!recalculate);
    setRefresh(!refresh);
  }
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Flash" pageTitle="Dashboard" activeDate={leftFilter} customDate={leftCustomDate} showAlert={true} handleRefresh={handleRefresh}   />                              
      
          <form className="mb-3">
            <Row>
              <Col className="mb-2 mb-lg-0" sm={12} lg={4}>
              <CustomDatePicker
                  activeDate={leftFilter}
                  setActiveDate={setLeftFilter}
                  customDate={leftCustomDate}
                  setCustomDate={setLeftCustomDate}
                  posEnd={false}
                />
              </Col>
              <Col className="mb-2 mb-lg-0" sm={12} lg={4}>
                <UncontrolledDropdown isOpen={rightFilterToggle} toggle={() => { setRightFilterToggle(!rightFilterToggle); }}>
                  <DropdownToggle
                    type="button"
                    tag="button"
                    className="btn btn-outline-custom"
                  >
                    {rightFilter ? rightFilter.label+(rightFilter.label==='Custom'?displayDate(comparisonDate):'') : "Select Comparison"}
                    <span className="rightBox">
                      <span></span>
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        className="css-tj5bde-Svg"
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="custom-menu-width" end={true}>
                    <Row>
                      <Col
                        sm={
                          rightFilter
                            ? 4
                            : 12
                        }
                      >
                        {!filterAjax &&
                          ordersTimeComparison.length > 0 &&
                          ordersTimeComparison.map((item) => {
                            return (
                              <button
                                key={item.value}
                                onClick={() => changeDefaultComparison(item)}
                                type="button"
                                tabIndex="0"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                {item.label}
                              </button>
                            );
                          })}
                      </Col>
                      {rightFilter &&
                        rightFilter.label=='Custom' && (
                          <Col sm={8}>
                            <Flatpickr
                              className="form-control border-0 dash-filter-picker shadow mw-auto"
                              placeholder=""
                              value={comparisonDate}
                              onChange={([date, date2]) => {
                                if(date !== undefined && date2 !== undefined){
                                  setRightFilterToggle(false);
                                }
                                setComparisonDate([date, date2]);
                              }}
                              options={{
                                mode: "range",
                                dateFormat: "d M, Y",
                                inline: true,
                              }}
                            />
                          </Col>
                        )}
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col sm={12} lg={4}>
                <select
                  className="form-select"
                  value={mealPeriod}
                  id="mealPeriod"
                  onChange={(event) => changeMealPeriod(event.target.value)}
                >
                  <option value="0">All Meal Periods</option>
                  {!filterAjax &&
                    filtermealperiod.length > 0 &&
                    filtermealperiod.map((item) => {
                      return (
                        <option key={item.sp_id} value={item.sp_id}>
                          {item.title + ' (' + item.start_time + ' To ' + item.end_time +')'}
                        </option>
                      );
                    })}
                </select>
              </Col>
            </Row>
          </form>

          <Row>
            <Col>
              <div className="h-100">
                {/* <Col xl={12}>
                    <NewWidget rightFilter={rightFilter} comparisonDate={comparisonDate} leftFilter={leftFilter} leftCustomDate={leftCustomDate} refresh={refresh} mealPeriod={mealPeriod} />
                </Col> */}
                <Widget rightFilter={rightFilter} comparisonDate={comparisonDate} leftFilter={leftFilter} leftCustomDate={leftCustomDate} refresh={refresh} mealPeriod={mealPeriod} />
                {/* <Row>
                  <Col xl={6}>
                    <Card>
                      <ForeCastPreviewCardHeader
                        title="Forecast - Covers & Revenue"
                        toggleText="Show Table"
                        handelForecastBudget={handelForecastBudget}
                        handelForecastLastYear={handelForecastLastYear}
                      />
                      <CardBody>
                        {forecastAjax && <Loader />}
                        <div className={forecastAjax ? "opacity-0" : ""}>
                          <div className="live-preview">
                            <Line
                              forecastBudget={forecastBudget}
                              forecastLastYear={forecastLastYear}
                              forecastData={forecastData}
                              dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
                            />
                          </div>
                          <div className="d-none code-view">
                            <div className="table-responsive table-card">
                              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead className="text-muted table-light">
                                  {(forecastBudget || forecastLastYear) && (
                                    <tr>
                                      <th scope="col"></th>
                                      <th
                                        scope="col"
                                        className="text-center"
                                        colSpan={2}
                                      >
                                        Forecast
                                      </th>
                                      {forecastBudget && (
                                        <th
                                          scope="col"
                                          className="text-center"
                                          colSpan={2}
                                        >
                                          Budget
                                        </th>
                                      )}
                                      {forecastLastYear && (
                                        <th
                                          scope="col"
                                          className="text-center"
                                          colSpan={2}
                                        >
                                          LastYear
                                        </th>
                                      )}
                                    </tr>
                                  )}
                                  <tr>
                                    <th scope="col">Period</th>
                                    <th scope="col">Revenue</th>
                                    <th scope="col">Covers</th>
                                    {forecastBudget && (
                                      <>
                                        <th scope="col">Revenue</th>
                                        <th scope="col">Covers</th>
                                      </>
                                    )}
                                    {forecastLastYear && (
                                      <>
                                        <th scope="col">Revenue</th>
                                        <th scope="col">Covers</th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {(forecastData.revenue || []).map(
                                    (item, key) => {
                                      date.setDate(date.getDate() + 1);
                                      return (
                                        <tr key={key}>
                                          <td>
                                            {date.toLocaleString("en-us", {
                                              day: "2-digit",
                                            }) +
                                              " " +
                                              date.toLocaleString("en-us", {
                                                month: "short",
                                              })}
                                          </td>
                                          <td>{item}</td>
                                          <td>
                                            {forecastData.covers[key] ?? ""}
                                          </td>
                                          {forecastBudget && (
                                            <>
                                              <td>
                                                {forecastData.revenue_budget[
                                                  key
                                                ] ?? ""}
                                              </td>
                                              <td>
                                                {forecastData.covers_budget[
                                                  key
                                                ] ?? ""}
                                              </td>
                                            </>
                                          )}
                                          {forecastLastYear && (
                                            <>
                                              <td>
                                                {forecastData.revenue_lastyear[
                                                  key
                                                ] ?? ""}
                                              </td>
                                              <td>
                                                {forecastData.covers_lastyear[
                                                  key
                                                ] ?? ""}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card>
                      <PreviewCardHeader
                        title="Forecast - Avg Check, RevPash & Occupancy"
                        toggleText="Show Table"
                      />
                      <CardBody>
                        {forecastAjax && <Loader />}
                        <div className={forecastAjax ? "opacity-0" : ""}>
                          <div className="live-preview">
                            <LineColumnArea
                              forecastData={forecastData}
                              forecastLastYear={forecastLastYear}
                              forecastBudget={forecastBudget}
                              dataColors={[
                                "#405189",
                                "#f7b84b",
                                "#0ab39c",
                                "#299cdb",
                                "#f06548",
                                "#212529",
                                "#40E0D0",
                                "#FF7F50",
                                "#CCCCFF",
                              ]}
                            />
                          </div>
                          <div className="d-none code-view">
                            <div className="table-responsive table-card">
                              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead className="text-muted table-light">
                                  {(forecastBudget || forecastLastYear) && (
                                    <tr>
                                      <th scope="col"></th>
                                      <th
                                        scope="col"
                                        className="text-center"
                                        colSpan={3}
                                      >
                                        Forecast
                                      </th>
                                      {forecastBudget && (
                                        <th
                                          scope="col"
                                          className="text-center"
                                          colSpan={3}
                                        >
                                          Budget
                                        </th>
                                      )}
                                      {forecastLastYear && (
                                        <th
                                          scope="col"
                                          className="text-center"
                                          colSpan={3}
                                        >
                                          LastYear
                                        </th>
                                      )}
                                    </tr>
                                  )}
                                  <tr>
                                    <th scope="col">Period</th>
                                    <th scope="col">Occp.</th>
                                    <th scope="col">Avg Check</th>
                                    <th scope="col">RevPASH</th>
                                    {forecastBudget && (
                                      <>
                                        <th scope="col">Occp.</th>
                                        <th scope="col">Avg Check</th>
                                        <th scope="col">RevPASH</th>
                                      </>
                                    )}
                                    {forecastLastYear && (
                                      <>
                                        <th scope="col">Occp.</th>
                                        <th scope="col">Avg Check</th>
                                        <th scope="col">RevPASH</th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {(forecastData.seat_occupancy || []).map(
                                    (item, key) => {
                                      date.setDate(date.getDate() + 1);
                                      return (
                                        <tr key={key}>
                                          <td>
                                            {date.toLocaleString("en-us", {
                                              day: "2-digit",
                                            }) +
                                              " " +
                                              date.toLocaleString("en-us", {
                                                month: "short",
                                              })}
                                          </td>
                                          <td>{item}</td>
                                          <td>
                                            {forecastData.average_check[key] ??
                                              ""}
                                          </td>
                                          <td>
                                            {forecastData.revPASH[key] ?? ""}
                                          </td>
                                          {forecastBudget && (
                                            <>
                                              <td>
                                                {forecastData
                                                  .seat_occupancy_budget[key] ??
                                                  ""}
                                              </td>
                                              <td>
                                                {forecastData
                                                  .average_check_budget[key] ??
                                                  ""}
                                              </td>
                                              <td>
                                                {forecastData.revPASH_budget[
                                                  key
                                                ] ?? ""}
                                              </td>
                                            </>
                                          )}
                                          {forecastLastYear && (
                                            <>
                                              <td>
                                                {forecastData
                                                  .seat_occupancy_lastyear[
                                                  key
                                                ] ?? ""}
                                              </td>
                                              <td>
                                                {forecastData
                                                  .average_check_lastyear[
                                                  key
                                                ] ?? ""}
                                              </td>
                                              <td>
                                                {forecastData.revPASH_lastyear[
                                                  key
                                                ] ?? ""}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col xl={6}>
                    <Card>
                      <div className="card-header px-2">
                        <h4 className="card-title mb-0">
                          Forecast Table Occupancy
                        </h4>
                      </div>

                      <div className="card-body px-2">
                        { forecastHeatmapAjax && <Loader />}
                        <div className={forecastHeatmapAjax ? "opacity-0" : ""} style={{maxWidth:'500px',margin: '0 auto'}}>
                          <ColorRange forecastHeatmapData={forecastHeatmapData.table}
                            dataText="Average Occ"
                            dataColors='["--vz-danger", "--vz-secondary", "--vz-warning","--vz-success"]'
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card>
                      <div className="card-header px-2">
                        <h4 className="card-title mb-0">
                          Forecast Seat Occupancy
                        </h4>
                      </div>

                      <div className="card-body px-2">
                        { forecastHeatmapAjax && <Loader />}
                        <div className={forecastHeatmapAjax ? "opacity-0" : ""} style={{maxWidth:'450px',margin: '0 auto'}}>
                          <ColorRange forecastHeatmapData={forecastHeatmapData.seat}
                            dataText="Average Occ"
                            showYaxis={false}
                            dataColors='["--vz-danger", "--vz-success"]'
                          />
                          </div>
                      </div>
                    </Card>
                  </Col>
                </Row> */}
                
                <Row>
                  <Col xl={12}>
                    <ServerPerformance currency={currency} showGraph={true} server_id={0} activeDate={leftFilter} customDate={leftCustomDate}  recalculate={refresh} mealPeriod={mealPeriod} />
                  </Col>
                </Row>
                
                <Row>
                  <Col xl={12}>
                    <BestSellingProducts currency={currency} comparisonDate={comparisonDate} activeComparisonData={false} globalDate={leftFilter} rightFilter={rightFilter}  globalCustomDate={leftCustomDate} mealPeriod={mealPeriod} defaultCourse={0} recalculate={refresh}/>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
