import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import ForeCastPreviewCardHeader from "../../Components/Common/ForeCastPreviewCardHeader";
import { Line } from "./MixedCharts";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import {
  getDateByName,
  convertDateFormate,
} from "../../helpers/api_helper";

const ForecastCoverRevenueChart = ({mealPeriod, leftFilter, rightFilter, leftCustomDate, activeComparisonData, comparisonDate}) => {
  const [forecastBudget, setForecastBudget] = useState(false);
  const [forecastLastYear, setForecastLastYear] = useState(false);

  const [forecastAjax, setForecastAjax] = useState(true);
  const [forecastData, setForecastData] = useState({
    revenue: [],
    covers: [],
    revenue_cp: [],
    covers_cp: [],
    seat_occupancy: [],
    seat_occupancy_cp: [],
    average_check: [],
    average_check_cp: [],
    revPASH: [],
    revPASH_cp: [],
    revenue_max: 0,
    covers_max: 0,
    seat_occupancy_max: 0,
    average_check_max: 0,
    revPASH_max: 0,
  });

  useEffect(() => {
    if (
      leftFilter.value !== "Custom" ||
      (leftFilter.value === "Custom" && leftCustomDate[0] && leftCustomDate[1])
    ) {
      setForecastAjax(true);
      postDataAPI({
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.forecast_op,
        leftFilter:
          leftFilter.value === "Custom"
            ? {
                from: convertDateFormate(leftCustomDate[0]),
                to: convertDateFormate(leftCustomDate[1]),
              }
            : getDateByName(leftFilter.value),
        rightFilter:
          activeComparisonData && activeComparisonData.datepiker
            ? {
                from: convertDateFormate(comparisonDate[0]),
                to: convertDateFormate(comparisonDate[1]),
              }
            : getDateByName(rightFilter.value),
        mealPeriod: mealPeriod,
      }).then((response) => {
        setForecastAjax(false);
        if (response.status === "ok") {
          setForecastData(response.data);
        } else {
          console.log(response);
        }
      });
    }
  }, [
    leftFilter,
    leftCustomDate,
    activeComparisonData,
    rightFilter,
    mealPeriod,
    comparisonDate,
  ]);

  const handelForecastBudget = () => {
    setForecastBudget(!forecastBudget);
  };
  const handelForecastLastYear = () => {
    setForecastLastYear(!forecastLastYear);
  };
  let date = new Date();
  date.setDate(date.getDate() - 1);

  let date_second = new Date();
  date_second.setDate(date_second.getDate() - 1);

  return (
    <Card>
    <ForeCastPreviewCardHeader
        title="Forecast - Covers & Revenue"
        toggleText="Show Table"
        handelForecastBudget={handelForecastBudget}
        handelForecastLastYear={handelForecastLastYear}
    />
    <CardBody>
        {forecastAjax && <Loader />}
        <div id="ForecastCoverRevenueChart" className={forecastAjax ? "opacity-0" : ""}>
        <div className="live-preview">
            <Line
            forecastBudget={forecastBudget}
            forecastLastYear={forecastLastYear}
            forecastData={forecastData}
            dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
            />
        </div>
        <div className="d-none code-view">
            <div className="table-responsive table-card">
            <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                {(forecastBudget || forecastLastYear) && (
                    <tr>
                    <th scope="col"></th>
                    <th
                        scope="col"
                        className="text-center"
                        colSpan={2}
                    >
                        Forecast
                    </th>
                    {forecastBudget && (
                        <th
                        scope="col"
                        className="text-center"
                        colSpan={2}
                        >
                        Budget
                        </th>
                    )}
                    {forecastLastYear && (
                        <th
                        scope="col"
                        className="text-center"
                        colSpan={2}
                        >
                        LastYear
                        </th>
                    )}
                    </tr>
                )}
                <tr>
                    <th scope="col">Period</th>
                    <th scope="col">Revenue</th>
                    <th scope="col">Covers</th>
                    {forecastBudget && (
                    <>
                        <th scope="col">Revenue</th>
                        <th scope="col">Covers</th>
                    </>
                    )}
                    {forecastLastYear && (
                    <>
                        <th scope="col">Revenue</th>
                        <th scope="col">Covers</th>
                    </>
                    )}
                </tr>
                </thead>
                <tbody>
                {(forecastData.revenue || []).map(
                    (item, key) => {
                    date.setDate(date.getDate() + 1);
                    return (
                        <tr key={key}>
                        <td>
                            {date.toLocaleString("en-us", {
                            day: "2-digit",
                            }) +
                            " " +
                            date.toLocaleString("en-us", {
                                month: "short",
                            })}
                        </td>
                        <td>{item}</td>
                        <td>
                            {forecastData.covers[key] ?? ""}
                        </td>
                        {forecastBudget && (
                            <>
                            <td>
                                {forecastData.revenue_budget[
                                key
                                ] ?? ""}
                            </td>
                            <td>
                                {forecastData.covers_budget[
                                key
                                ] ?? ""}
                            </td>
                            </>
                        )}
                        {forecastLastYear && (
                            <>
                            <td>
                                {forecastData.revenue_lastyear[
                                key
                                ] ?? ""}
                            </td>
                            <td>
                                {forecastData.covers_lastyear[
                                key
                                ] ?? ""}
                            </td>
                            </>
                        )}
                        </tr>
                    );
                    }
                )}
                </tbody>
            </table>
            </div>
        </div>
        </div>
    </CardBody>
    </Card>
  );
};

export default ForecastCoverRevenueChart;
