import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import UiContent from "../../Components/Common/UiContent";
import FoodCapital from "./FoodCapital";
import ForeCastCovers from "./ForeCastCovers";
import ForeCastRevOcc from "./ForeCastRevOcc";
import ForeCastTotalRev from "./ForeCastTotalRev";
import Revenue from "./Revenue"
import { useDispatch, useSelector } from "react-redux";
import FlashDatePicker from "../../Components/Common/FlashDatePicker";
import TrendAvgCheckCover from "./TrendAvgCheckCover";
import Select, { components } from "react-select";
import WeekPerformance from "./WeekPerformance";

const Flash = () => {
  const dispatch = useDispatch();
  document.title = "Flash | " + process.env.REACT_APP_NAME;
  // const [leftFilter, setLeftFilter] = useState(false);    
  const [mealPeriod, setMealPeriod] = useState([]);
  const [filtermealperiod, setFiltermealperiod] = useState([]);
  const [filterDateList, setFilterDateList] = useState([]);
  const [currency, setCurrency] = useState('$');
  const [selectedMealList, setSelectedMealList] = useState(null);
  const changeMealPeriod = (value) => {
    setSelectedMealList(value);
    let selVal= [];
    value.map((item) => {
      selVal.push(item.value);
    });
    setMealPeriod(selVal);
    //setMealPeriod(value);
  };
  const {
    filterData,
    filterAjax,
    currentOutlet
  } = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
    currentOutlet:state.UserOutletGroup.currentOutlet
  }));

  const [leftFilter, setLeftFilter] = useState(
    filterData.flash_date?.find((date) => date.default) || false
  );

  useEffect(() => {  
    if (filterData.mealperiod) {
      const mealListArr = [];
      filterData.mealperiod.map((item) => {
        mealListArr.push({
          value: item.sp_id,
          label: item.title + ' (' + item.start_time + ' To ' + item.end_time +')'
        });
      });

      setFiltermealperiod(mealListArr);
      //setFiltermealperiod(filterData.mealperiod);
    }
    if (filterData.flash_date) {
      setLeftFilter((prev) =>
        prev || filterData.flash_date.find((date) => date.default)
      );
      setFilterDateList(filterData.flash_date);
    }
  }, [filterAjax, filterData]);  
  useEffect(()=>{
    if(currentOutlet?.currency!=""){
      setCurrency(currentOutlet?.currency)
    }
  },[currentOutlet]);

  let date = new Date();
  date.setDate(date.getDate() - 1);

  const getMealString = (selectedMealList) => {
    if (!selectedMealList || selectedMealList.length === 0) {
      return "All Meal";
    }  
    return selectedMealList.map((meal) => meal.label.split(" (")[0]).join(", ");
  };
  const mealString = getMealString(selectedMealList);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} style={{ verticalAlign: "middle" }} />
        <span style={{ verticalAlign: "middle", lineHeight: 1 }}>{props.data.label}</span >
        </div>
      </components.Option>
    );
  };
  

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Flash" pageTitle="Flash"  />
          <form className="mb-3">
            <Row>
              <Col className="mb-2 mb-lg-0" sm={12} lg={4}>
              <FlashDatePicker
                  filterDateList={filterDateList}
                  activeDate={leftFilter}
                  setActiveDate={setLeftFilter}
                  posEnd={false}
                />
              </Col>
              <Col sm={12} lg={4}>
                {/* <select
                  className="form-select"
                  value={mealPeriod}
                  id="mealPeriod"
                  onChange={(event) => changeMealPeriod(event.target.value)}
                >
                  <option value="0">All Meal Periods</option>
                  {!filterAjax &&
                    filtermealperiod.length > 0 &&
                    filtermealperiod.map((item) => {
                      return (
                        <option key={item.sp_id} value={item.sp_id}>
                          {item.title + ' (' + item.start_time + ' To ' + item.end_time +')'}
                        </option>
                      );
                    })}
                </select> */}
                <Select
                  value={selectedMealList}
                  isMulti={true}
                  onChange={(value) => {
                    changeMealPeriod(value);
                  }}
                  options={filtermealperiod}
                  placeholder="All Meal Periods"
                  closeMenuOnSelect={false}
                  components={{ Option: CustomOption }}
                />
              </Col>
            </Row>
          </form>
          <Row>
            <Col xl={12}>
                <FoodCapital mealPeriod={mealPeriod} leftFilter={leftFilter} currency={currency} />
            </Col>
            <Col xl={12}>
                <ForeCastCovers mealPeriod={mealPeriod} leftFilter={leftFilter} />
            </Col>
            <Col xl={12}>
                <ForeCastRevOcc mealPeriod={mealPeriod} leftFilter={leftFilter} />
            </Col>
            <Col xl={12}>
                <TrendAvgCheckCover mealPeriod={mealPeriod} leftFilter={leftFilter} />
            </Col>
            <Col xl={12}>
                <ForeCastTotalRev mealPeriod={mealPeriod} leftFilter={leftFilter} />
            </Col>  
            {/* <Col xl={12}>
                <WeekPerformance mealPeriod={mealPeriod} leftFilter={leftFilter} currency={currency} mealString={mealString} />
            </Col>            */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Flash;
