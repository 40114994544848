import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import Loader from "../../Components/Common/Loader";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import {
  getDateByName,
  convertDateFormate,
} from "../../helpers/api_helper";

const FoodTribesChart = ({leftFilter, rightFilter, leftCustomDate, mealPeriod, currency, defaultCourse, comparisonDate, activeComparisonData }) => {
  const [foodTribeData, setFoodTribeData] = useState({
    Covers: [],
    AvgCheck: [],
    CourseName: [],
  });
  const [foodTribeAjax, setFoodTribeAjax] = useState(true);

  var chartLineColumnColors = getChartColorsArray(
    '["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning"]'
  );
  const fooedTribesSeries = [
    {
      name: "Avg Check",
      type: "line",
      data: foodTribeData["AvgCheck"],
    },
    {
      name: "Item Sold",
      type: "column",
      data: foodTribeData["Covers"],
    },
  ];
  let chartLineColumnYaxis = [
    {
      title: {
        seriesName: "Avg Check",
        text: "Avg Check",
        style: {
          fontWeight: 600,
        },
      },
      labels: {
          formatter: function (value) {
              return value.toFixed(0);
          },
      },
    },
    {
      opposite: true,
      seriesName: "Item Sold",
      title: {
        text: "Item Sold",
        style: {
          fontWeight: 600,
        },
      },
      decimalsInFloat: 0,
    },
  ];

  if (foodTribeData["AvgCheckPY"]) {
    fooedTribesSeries.push({
      name: "Avg Check PY",
      type: "line",
      data: foodTribeData["AvgCheckPY"],
    });
    chartLineColumnYaxis.push({
      title: {
        seriesName: "Avg Check",
      },
      show: false,
    });
  }
  if (foodTribeData["CoversPY"]) {
    fooedTribesSeries.push({
      name: "Covers PY",
      type: "column",
      data: foodTribeData["CoversPY"],
    });
    chartLineColumnYaxis.push({
      title: {
        seriesName: "Item Sold",
      },
      show: false,
    });
  }
  const fooedTribesOptions = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    stroke: {
      width: [4, 0, 4, 0],
      curve: "smooth",
    },
    title: {
      text: "",
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return value;
      },
    },
    labels: foodTribeData["CourseName"],
    xaxis: {
      type: "category",
    },
    yaxis: chartLineColumnYaxis,
    colors: chartLineColumnColors,
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    tooltip: {
      y: {
          formatter: function (value, { seriesIndex }) {
              // Check the seriesIndex to format the value accordingly
              if (seriesIndex === 0) {
                  return value.toFixed(2); 
              } else {
                return value;
              }
          },
      },
  },
  };

  useEffect(() => {
    if (
      (leftFilter.value !== "Custom" ||
        (leftFilter.value === "Custom" &&
          leftCustomDate[0] &&
          leftCustomDate[1])) &&
      (rightFilter.value !== "Custom" ||
        (rightFilter.value === "Custom" &&
          comparisonDate[0] &&
          comparisonDate[1]))
    ) {
      let leftFilterData =
        leftFilter.value === "Custom"
          ? {
              from: convertDateFormate(leftCustomDate[0]),
              to: convertDateFormate(leftCustomDate[1]),
            }
          : getDateByName(leftFilter.value);
      let rightFilterData =
        activeComparisonData && activeComparisonData.datepiker
          ? {
              from: convertDateFormate(comparisonDate[0]),
              to: convertDateFormate(comparisonDate[1]),
            }
          : getDateByName(rightFilter.value);

      postDataAPI({
        leftFilter: leftFilterData,
        rightFilter: rightFilterData,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.food_tribes_op,
      }).then((response) => {
        setFoodTribeAjax(false);
        if (response.status === "ok") {
          setFoodTribeData(response.data);
        } else {
          console.log(response);
        }
      });
    }
  }, [
    leftFilter,
    rightFilter,
    mealPeriod,
    comparisonDate,
    activeComparisonData,
    leftCustomDate,
    defaultCourse,
  ]);
  return (
    <Card>
        <CardHeader>
        <h4 className="card-title mb-0">Food Tribes</h4>
        </CardHeader>
        <CardBody>
        {foodTribeAjax && <Loader />}
        <div id="FoodTribesChart" className={foodTribeAjax ? "opacity-0" : ""}>
            <ReactApexChart
            options={fooedTribesOptions}
            series={fooedTribesSeries}
            type="line"
            height={350}
            />
        </div>
        </CardBody>
    </Card>
  );
};

export default FoodTribesChart;
