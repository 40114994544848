/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Card,
} from "reactstrap";
import { ColorRange } from "./HeatmapCharts";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_TABLE_SEAT_OPTIMIZATION } from "../../helpers/data_helper";
import Loader from "../../Components/Common/Loader";
import { getDateByName, coverColor } from "../../helpers/api_helper";

const RevPashChart = ({activeDate, customDate, mealPeriod}) => {
  const [occupancyHeatmapData, setOccupancyHeatmapData] = useState({
    table: [],
    seat: [],
    revPASH: [],
  });
  const [occupancyHeatmapAjax, setOccupancyHeatmapAjax] = useState(true);

  useEffect(() => {
    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;
        
    let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);

    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      // setTableRevenueAjax(true);
      setOccupancyHeatmapAjax(true);
      var dateFilter =
        activeDate.value === "Custom"
          ? { from: customDate[0], to: customDate[1] }
          : getDateByName(activeDate.value);
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.occupancy_heatmap_op,
      }).then((response) => {
        setOccupancyHeatmapAjax(false);
        if (response.status === "ok") {
          setOccupancyHeatmapData(response.data);
        } else {
          console.log(response);
        }
      });      
    }
  }, [activeDate, customDate, mealPeriod]); 
  
  return (
    <Card>
      <div className="card-header px-2">
        <h4 className="card-title mb-0">RevPASH</h4>
      </div>

      <div id="RevPashChart" className="card-body px-2">
        {occupancyHeatmapAjax && <Loader />}
        <div
          className={occupancyHeatmapAjax ? "opacity-0" : ""}
        >
          <ColorRange
            dataSeries={occupancyHeatmapData.revPASH}
            dataText="RevPASH"
            dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
          />
        </div>
      </div>
    </Card>
  );
};

export default RevPashChart;
