import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { FETCH_DASHBOARD_DATA, SET_DASHBOARD_DATA } from "./actionType";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { REPORTS } from "../../helpers/data_helper";

function* fetchDashboardSaga() {
    try {
        const response = yield call(postDataAPI, {
            serviceName: REPORTS.serviceName,
            op: REPORTS.op_dashboard_report,
        });

        if (response.status === "ok") {
            yield put({ type: SET_DASHBOARD_DATA, payload: response.data.result.data });
        }
    } catch (error) {
        console.error("Failed to fetch dashboard data", error);
    }
}

function* myDashboardSaga() {
    yield takeEvery(FETCH_DASHBOARD_DATA, fetchDashboardSaga);
}

export default myDashboardSaga;
