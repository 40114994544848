import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
    Collapse,
    Input,
    Modal,ModalHeader,ModalBody,ModalFooter,
    Label,
  } from "reactstrap";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";  
import UiContent from "../../Components/Common/UiContent";
import { REPORTS } from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { useParams } from "react-router-dom";
import CustomDatePicker from "../../Components/Common/CustomDatePicker";
import { getDateByNameV1, displayDate, convertDateFormate, getLoggedinUser } from "../../helpers/api_helper";
import ChartsComponents from "./ChartsComponents"; 


const MyDashboard = () => {
    const [leftCustomDate, setLeftCustomDate] = useState([]);
    const [leftFilter1, setLeftFilter1] = useState([]);
    const [leftFilter, setLeftFilter] = useState({
        label: "Yesterday",
        value: "Yesterday",
    });
    const [currency, setCurrency] = useState('$');
    const [selectedCharts, setSelectedCharts] = useState([]);
    const [rightFilter, setRightFilter] = useState({"label":"This Month","value":"ThisMonth"});

    const {
        currentOutlet,
    } = useSelector((state) => ({
        currentOutlet:state.UserOutletGroup.currentOutlet,
    }));

    useEffect(()=>{
        setLeftFilter1((prev) => ({
          ...prev,
          value: leftFilter.value,
          label: leftFilter.label,
          ...(leftFilter.value === "Custom"
            ? {
                from: convertDateFormate(leftCustomDate[0]),
                to: convertDateFormate(leftCustomDate[1]),
                from_date: convertDateFormate(leftCustomDate[0]),
                to_date: convertDateFormate(leftCustomDate[1])
              }
            : getDateByNameV1(leftFilter.value)
          ),
      }))
    },[leftFilter,leftCustomDate]);
    useEffect(()=>{
          if(currentOutlet?.currency!==""){
            setCurrency(currentOutlet?.currency)
          }
    },[currentOutlet]);

    const [listAjax,setListAjax] = useState(false);
    const [listData, setListData] = useState([]);
    const { id } = useParams()
    useEffect(() => {
        setListAjax(true);
        postDataAPI({
            serviceName: REPORTS.serviceName,
            op: REPORTS.op_get_report_detail,
            export_r_id: id,
        }).then((response) => {
            setListAjax(false);
            if (response.status === "ok") {
                setListData(response.data.result.data);                
                if (response.data.result.data.length>0) {
                    const timeFrame = JSON.parse((response.data.result.data[0].time_frame));
                    setLeftFilter({"label": timeFrame.label, "value": timeFrame.value});
                    setSelectedCharts(JSON.parse((response.data.result.data[0].graphs)));
                } else {
                    setSelectedCharts([]);
                }
            } else {
                console.log(response);
            }
        });
    }, [id]);

    const history = useHistory();
    const handleEditReport = (reportData) => {
        history.push({
            pathname: "/reports",
            state: { editReportData: reportData }
        });
    };

    return (
        <React.Fragment>
        <UiContent />
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={listData.length > 0 ? listData[0].title : "My Dashboard"} pageTitle={"My Dashboard"}  />
                <Row>
                    <Col className="mb-2 mb-lg-0" sm={12} lg={4}>
                    <CustomDatePicker
                        activeDate={leftFilter}
                        setActiveDate={setLeftFilter}
                        customDate={leftCustomDate}
                        setCustomDate={setLeftCustomDate}
                        posEnd={false}
                    />
                    </Col>
                    <Col className="text-end" sm={12} lg={8}>
                        <Button color="primary" className="btn-label" onClick={() => handleEditReport(listData)}> <i className=" ri-pencil-fill label-icon align-middle fs-16 me-2"></i> Edit </Button>
                    </Col>
                </Row><br />
                <Row>
                    <ChartsComponents selectedCharts={selectedCharts} leftFilter1={leftFilter1} rightFilter={rightFilter} currency={currency} leftCustomDate={leftCustomDate} />
                </Row>
            </Container>
        </div>
    </React.Fragment>
    );
};
export default MyDashboard;