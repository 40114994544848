import React, { useState, useEffect } from "react";
import Loader from "../../Components/Common/Loader";
import { Col, Row, Card, CardHeader, CardBody, Button, Dropdown, DropdownItem, DropdownMenu, ButtonGroup, Input, Label, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { FLASH } from "../../helpers/data_helper";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import {getLocalStorageData, displayDateV1, convertDateFormate, getDateByName, getPercentageWithAlertV1} from "../../helpers/api_helper";
import {
    getKIPWidgetData,
  } from "../../store/actions";

const NewWidget = ({ rightFilter, comparisonDate, leftFilter, mealPeriod, refresh, leftCustomDate }) => {
    const userOutletDetails = getLocalStorageData('userOutletDetails');
    const currency=userOutletDetails?.currency??"$";
    const dispatch = useDispatch();

    let rightFilterLabel = (rightFilter && rightFilter.value!=="Custom") ? rightFilter.label : "";
    if (rightFilter && rightFilter.value==="ThisMonth") {
        rightFilterLabel = "Month-to-date";
    } else if (rightFilter && rightFilter.value==="Custom" && comparisonDate.length > 0) {
        rightFilterLabel = displayDateV1(comparisonDate);
    }

     const {
        widgetData,
        widgetAjax,
      } = useSelector((state) => ({
        widgetData: state.Dashboard.widgetData,
        widgetAjax: state.Dashboard.widgetAjax
      }));

    useEffect(() => {
        localStorage.setItem('Selling_items_SortType', '');
        localStorage.setItem('Reccomendation_SortType', '');
        if(leftFilter.value!=='Custom' || (leftFilter.value==='Custom' && leftCustomDate[0] && leftCustomDate[1]) || refresh){
        
          dispatch(      
            getKIPWidgetData({
              leftFilter: leftFilter.value==='Custom'?{from:convertDateFormate(leftCustomDate[0]),to:convertDateFormate(leftCustomDate[1])}:getDateByName(leftFilter.value),
              rightFilter: rightFilter.value==='Custom'?{from:convertDateFormate(comparisonDate[0]),to:convertDateFormate(comparisonDate[1])}:getDateByName(rightFilter.value),  
              mealPeriod: mealPeriod,
              recalculate: refresh,
            })
          );
        }
    }, [leftFilter,leftCustomDate, rightFilter, mealPeriod,comparisonDate, refresh,  dispatch]);
    //console.log(widgetData);
    const ecomWidgets = [
        {
            id: 1,
            label: "Total Covers",
            counter: !widgetAjax ? widgetData.covers:'',
            lyPer: !widgetAjax ? "vLY "+getPercentageWithAlertV1(Number(widgetData?.ly_cover_percent).toFixed(2)) :'',
            budPer: !widgetAjax ? "vBUD "+getPercentageWithAlertV1(Number(widgetData?.bg_cover_percent).toFixed(2)):'',
            fcPer: !widgetAjax ? "vFC "+getPercentageWithAlertV1(Number(widgetData?.fs_cover_percent).toFixed(2)):'',
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: "",
            lybgClass: (widgetData?.ly_cover_percent && Number(widgetData?.ly_cover_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            budbgClass: (widgetData?.bg_cover_percent && Number(widgetData?.bg_cover_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            fcbgClass: (widgetData?.fs_cover_percent && Number(widgetData?.fs_cover_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            lyArrowClass: (widgetData?.ly_cover_percent && Number(widgetData?.ly_cover_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            budArrowClass: (widgetData?.bg_cover_percent && Number(widgetData?.bg_cover_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            fcArrowClass: (widgetData?.fs_cover_percent && Number(widgetData?.fs_cover_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
        },
        {
            id: 2,
            label: "Average Check",
            counter: !widgetAjax ? currency+""+widgetData.average_check:'',
            lyPer: !widgetAjax ? "vLY "+getPercentageWithAlertV1(Number(widgetData?.ly_avg_check_percent).toFixed(2)):'',
            budPer: !widgetAjax ? "vBUD "+getPercentageWithAlertV1(Number(widgetData?.bg_avg_check_percent).toFixed(2)):'',
            fcPer: !widgetAjax ? "vFC "+getPercentageWithAlertV1(Number(widgetData?.fs_avg_check_percent).toFixed(2)):'',
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: "",
            lybgClass: (widgetData?.ly_avg_check_percent && Number(widgetData?.ly_avg_check_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            budbgClass: (widgetData?.bg_avg_check_percent && Number(widgetData?.bg_avg_check_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            fcbgClass: (widgetData?.fs_avg_check_percent && Number(widgetData?.fs_avg_check_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            lyArrowClass: (widgetData?.ly_avg_check_percent && Number(widgetData?.ly_avg_check_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            budArrowClass: (widgetData?.bg_avg_check_percent && Number(widgetData?.bg_avg_check_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            fcArrowClass: (widgetData?.fs_avg_check_percent && Number(widgetData?.fs_avg_check_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
        },
        {
            id: 3,
            label: "Total Revenue",
            counter: !widgetAjax ? currency+""+widgetData.revenue:'',
            lyPer: !widgetAjax ? "vLY "+getPercentageWithAlertV1(Number(widgetData?.ly_rev_percent).toFixed(2)):'',
            budPer: !widgetAjax ? "vBUD "+getPercentageWithAlertV1(Number(widgetData?.bg_rev_percent).toFixed(2)):'',
            fcPer: !widgetAjax ? "vFC "+getPercentageWithAlertV1(Number(widgetData?.fs_rev_percent).toFixed(2)):'',
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: "",
            lybgClass: (widgetData?.ly_rev_percent && Number(widgetData?.ly_rev_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            budbgClass: (widgetData?.bg_rev_percent && Number(widgetData?.bg_rev_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            fcbgClass: (widgetData?.fs_rev_percent && Number(widgetData?.fs_rev_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            lyArrowClass: (widgetData?.ly_rev_percent && Number(widgetData?.ly_rev_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            budArrowClass: (widgetData?.bg_rev_percent && Number(widgetData?.bg_rev_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            fcArrowClass: (widgetData?.fs_rev_percent && Number(widgetData?.fs_rev_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
        },
        {
            id: 4,
            label: "Table Occupancy",
            counter: !widgetAjax ? widgetData?.table_occupancy+"%":'',
            lyPer: !widgetAjax ? "vLY "+getPercentageWithAlertV1(Number(widgetData?.ly_table_occupancy_percent).toFixed(2)):'',
            budPer: '',
            fcPer: !widgetAjax ? "vFC "+getPercentageWithAlertV1(Number(widgetData?.fs_table_occupancy).toFixed(2)):'',
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: "",
            lybgClass: (widgetData?.ly_table_occupancy_percent && Number(widgetData?.ly_table_occupancy_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            budbgClass: "",
            fcbgClass: (widgetData?.fs_table_occupancy && Number(widgetData?.fs_table_occupancy) < 0) ? "badge-soft-danger" : "badge-soft-success",
            lyArrowClass: (widgetData?.ly_table_occupancy_percent && Number(widgetData?.ly_table_occupancy_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            budArrowClass: "",
            fcArrowClass: (widgetData?.fs_table_occupancy && Number(widgetData?.fs_table_occupancy) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
        },
        {
            id: 5,
            label: "Seat Occupancy",
            counter: !widgetAjax ? widgetData?.seat_occupancy+"%":'',
            lyPer: !widgetAjax ? "vLY "+getPercentageWithAlertV1(Number(widgetData?.ly_seat_occupancy_percent).toFixed(2)):'',
            budPer: '',
            fcPer: !widgetAjax ? "vFC "+getPercentageWithAlertV1(Number(widgetData?.fs_seat_occupancy_percent).toFixed(2)):'',
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: "",
            lybgClass: (widgetData?.ly_seat_occupancy_percent && Number(widgetData?.ly_seat_occupancy_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            budbgClass: "",
            fcbgClass: (widgetData?.fs_seat_occupancy_percent && Number(widgetData?.fs_seat_occupancy_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            lyArrowClass: (widgetData?.ly_table_occupancy_percent && Number(widgetData?.ly_table_occupancy_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            budArrowClass: "",
            fcArrowClass: (widgetData?.fs_seat_occupancy_percent && Number(widgetData?.fs_seat_occupancy_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
        },
        {
            id: 6,
            label: "RevPASH",
            counter: !widgetAjax ? currency+""+widgetData?.revpash:'',
            lyPer: !widgetAjax ? "vLY "+getPercentageWithAlertV1(Number(widgetData?.ly_revpash_percent).toFixed(2)):'',
            budPer: '',
            fcPer: !widgetAjax ? "vFC "+getPercentageWithAlertV1(Number(widgetData?.fs_revpash_percent).toFixed(2)):'',
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: "",
            lybgClass: (widgetData?.ly_revpash_percent && Number(widgetData?.ly_revpash_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            budbgClass: "",
            fcbgClass: (widgetData?.fs_revpash_percent && Number(widgetData?.fs_revpash_percent) < 0) ? "badge-soft-danger" : "badge-soft-success",
            lyArrowClass: (widgetData?.ly_revpash_percent && Number(widgetData?.ly_revpash_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
            budArrowClass: "",
            fcArrowClass: (widgetData?.fs_revpash_percent && Number(widgetData?.fs_revpash_percent) < 0) ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line",
        },
    ];
   
    return (
        <>
        <Row id="Widget">
        {ecomWidgets.map((item, key) => (
            <Col xl={4} key={key}>
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">                            
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="fw-medium text-muted text-truncate mb-3">{item.label}</p>
                                <div className="d-flex align-items-center mb-3">
                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                        <span className="counter-value me-1">
                                            {/* <CountUp
                                                start={0}
                                                suffix=""
                                                separator=""
                                                end={Number(item.counter)}
                                                duration={0}
                                            /> */}
                                            {item?.counter}
                                        </span>
                                    </h4>
                                    
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge "+item.lybgClass}><i className={"fs-10 align-middle me-1 "+item.lyArrowClass}></i>{item.lyPer}</span>
                                    </p>
                                    {item.id <=  3 &&
                                        <p className="text-muted mb-0">
                                            <span className={"fs-10 badge "+item.budbgClass}><i className={"fs-10 align-middle me-1 "+item.budArrowClass}></i>{item.budPer}</span>
                                        </p>
                                    }
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge "+item.fcbgClass}><i className={"fs-10 align-middle me-1 "+item.fcArrowClass}></i>{item.fcPer}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        ))}
            {/*<Col xl={4}>
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">                            
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="fw-medium text-muted text-truncate mb-3">Total Covers</p>
                                <div className="d-flex align-items-center mb-3">
                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                        <span className="counter-value me-1">
                                            <CountUp
                                                start={0}
                                                suffix=""
                                                separator=""
                                                end={widgetData?.covers}
                                                duration={0}
                                            />
                                            {widgetData?.covers}
                                        </span>
                                    </h4>
                                    
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vLY +55.02%</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vBUD {"+55.02%"}</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-danger"}><i className={"fs-10 align-middle me-1 ri-arrow-down-s-line"}></i>vFC -55.02%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xl={4}>
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">                            
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="fw-medium text-muted text-truncate mb-3">Average Check</p>
                                <div className="d-flex align-items-center mb-3">
                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                        <span className="counter-value me-1">
                                            <CountUp
                                                start={0}
                                                suffix=""
                                                separator=""
                                                end={!widgetAjax ?  widgetData?.average_check : ''}
                                                duration={0}
                                                prefix="$"
                                            />
                                        </span>
                                    </h4>
                                    
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vLY +55.02%</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vBUD {"+55.02%"}</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-danger"}><i className={"fs-10 align-middle me-1 ri-arrow-down-s-line"}></i>vFC -55.02%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xl={4}>
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">                            
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="fw-medium text-muted text-truncate mb-3">Total Revenue</p>
                                <div className="d-flex align-items-center mb-3">
                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                        <span className="counter-value me-1">
                                            <CountUp
                                                start={0}
                                                suffix=""
                                                separator=""
                                                end="825"
                                                duration={0}
                                                prefix="$"
                                            />
                                        </span>
                                    </h4>
                                    
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vLY +55.02%</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vBUD {"+55.02%"}</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-danger"}><i className={"fs-10 align-middle me-1 ri-arrow-down-s-line"}></i>vFC -55.02%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        
            <Col xl={4}>
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">                            
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="fw-medium text-muted text-truncate mb-3">Table Occupancy</p>
                                <div className="d-flex align-items-center mb-3">
                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                        <span className="counter-value me-1">
                                            <CountUp
                                                start={0}
                                                suffix="%"
                                                separator=""
                                                end="77.50"
                                                duration={0}
                                                prefix=""
                                                decimals={2} 
                                            />
                                        </span>
                                    </h4>
                                    
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vLY +55.02%</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vBUD {"+55.02%"}</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-danger"}><i className={"fs-10 align-middle me-1 ri-arrow-down-s-line"}></i>vFC -55.02%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xl={4}>
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">                            
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="fw-medium text-muted text-truncate mb-3">Seat Occupancy</p>
                                <div className="d-flex align-items-center mb-3">
                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                        <span className="counter-value me-1">
                                            <CountUp
                                                start={0}
                                                suffix="%"
                                                separator=""
                                                end="77.50"
                                                duration={0}
                                                prefix=""
                                                decimals={2} 
                                            />
                                        </span>
                                    </h4>
                                    
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vLY +55.02%</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vBUD {"+55.02%"}</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-danger"}><i className={"fs-10 align-middle me-1 ri-arrow-down-s-line"}></i>vFC -55.02%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xl={4}>
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">                            
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="fw-medium text-muted text-truncate mb-3">RevPASH</p>
                                <div className="d-flex align-items-center mb-3">
                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                        <span className="counter-value me-1">
                                            <CountUp
                                                start={0}
                                                suffix=""
                                                separator=""
                                                end="44.50"
                                                duration={0}
                                                prefix="$"
                                                decimals={2} 
                                            />
                                        </span>
                                    </h4>
                                    
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vLY +55.02%</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-success"}><i className={"fs-10 align-middle me-1 ri-arrow-up-s-line"}></i>vBUD {"+55.02%"}</span>
                                    </p>
                                    <p className="text-muted mb-0">
                                        <span className={"fs-10 badge badge-soft-danger"}><i className={"fs-10 align-middle me-1 ri-arrow-down-s-line"}></i>vFC -55.02%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col> */}
        </Row>
        </>
    );
};

export default NewWidget;
