import React, { useState, useEffect } from "react";
import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
    Collapse,
    Input,
    Modal,ModalHeader,ModalBody,ModalFooter,
    Label,
  } from "reactstrap";
import Select, { components } from "react-select";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, useSortable, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
  
import BreadCrumb from "../../Components/Common/BreadCrumb";  
import UiContent from "../../Components/Common/UiContent";
import { useDispatch, useSelector } from "react-redux";

import ExportPPT from "../../Components/Common/ExportPPT";
import ExportPDF from "../../Components/Common/ExportPDF";
import FoodCampitalImg from "../../assets/images/reports/food-capital.jpeg";
import ForecastAvgImg from "../../assets/images/reports/forecast-avg.jpeg";
import ForecastCoverRevenueImg from "../../assets/images/reports/forecast-cover-revenue.jpeg";
import ForecaseAvgRevpasImg from "../../assets/images/reports/forcast-avg-revpas.jpeg";
import ForecastTableOccImg from "../../assets/images/reports/forecast-table-occ.jpeg";
import ForecastSeatOccImg from "../../assets/images/reports/forcase-seat-occ.jpeg";
import AvgCheckCoverImg from "../../assets/images/reports/avg-check-covers.jpeg";
import TotalRevenueImg from "../../assets/images/reports/total-revenue.jpeg";
import FlashAnalyticsImg from "../../assets/images/reports/flash-analytics.jpeg";
import ServerPerformanceImg from "../../assets/images/reports/server-performance.jpeg";
import SellingItemsImg from "../../assets/images/reports/selling-items.jpeg";
import FoodTribeImg from "../../assets/images/reports/food-tribe.jpeg";
import PortfolioAnalysisImg from "../../assets/images/reports/portfolio-analysis.jpeg";
import DiscountCheckListImg from "../../assets/images/reports/discount-checklist.jpeg";
import DiscountPerformanceImg from "../../assets/images/reports/discount-performance.jpeg";
import TableOccImg from "../../assets/images/reports/table-occ.jpeg";
import SeatOccImg from "../../assets/images/reports/seat-occ.jpeg";
import RevpasImg from "../../assets/images/reports/revpas.jpeg";
import TableMixPartyImg from "../../assets/images/reports/tablemix-party-size.jpeg";
import TableRevenueImg from "../../assets/images/reports/table-revenue.jpeg";
import AvgCheckBySlotImg from "../../assets/images/reports/avgcheckbyslots.jpeg";
import TopBottomPerformanceImg from "../../assets/images/reports/topbottom-performance.jpeg";
import ServerRevenueCourseImg from "../../assets/images/reports/server-revenue-course.jpeg";
import ServerContriMixImg from "../../assets/images/reports/server-contribution-mix.jpeg";

import CustomDatePicker from "../../Components/Common/CustomDatePicker";
import { getDateByNameV1, displayDate, convertDateFormate, getLoggedinUser } from "../../helpers/api_helper";
import Loader from "../../Components/Common/Loader"
import menuItems from "./MenuItems.json";
import { REPORTS } from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import ChartsComponents from "./ChartsComponents";
import { fetchMyDashboardData } from "../../store/reports/action";
import { useLocation, useHistory } from "react-router-dom";

const imageImports = {
  FoodCampitalImg,
  ForecastAvgImg,
  FlashAnalyticsImg,
  FoodTribeImg,
  DiscountCheckListImg,
  AvgCheckBySlotImg,
  TopBottomPerformanceImg,
  ForecastCoverRevenueImg,
  ForecaseAvgRevpasImg,
  ForecastTableOccImg,
  ForecastSeatOccImg,
  AvgCheckCoverImg,
  TotalRevenueImg,
  ServerPerformanceImg,
  SellingItemsImg,
  PortfolioAnalysisImg,
  DiscountPerformanceImg,
  TableOccImg,
  SeatOccImg,
  RevpasImg,
  TableMixPartyImg,
  TableRevenueImg,
  ServerRevenueCourseImg,
  ServerContriMixImg
};

const Reports = () => {
  document.title = "Reports | " + process.env.REACT_APP_NAME;
  const [currency, setCurrency] = useState('$');
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [leftCustomDate, setLeftCustomDate] = useState([]);
  const [leftFilter1, setLeftFilter1] = useState([]);
  const [leftFilter, setLeftFilter] = useState({
    label: "Select",
    value: "",
  });
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [graphList, setGraphList] = useState([]);
  const [rightFilter, setRightFilter] = useState({"label":"This Month","value":"ThisMonth"});
  const [chartTitle, setChartTitle] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [commentary, setCommentary] = useState("no");
  const [selectedPage, setSelectedPage] = useState(0);    
  const [pageCount, setPageCount] = useState(0);
  const [isMyDashboard, setIsMyDashboard] = useState(0);
  const [exportType, setExportType] = useState("");
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(null);

  const userInfo=getLoggedinUser();
  const {
    currentOutlet,
  } = useSelector((state) => ({
    currentOutlet:state.UserOutletGroup.currentOutlet,
  }));

  useEffect(()=>{
    setLeftFilter1((prev) => ({
      ...prev,
      value: leftFilter.value,
      label: leftFilter.label,
      ...(leftFilter.value === "Custom"
        ? {
            from: convertDateFormate(leftCustomDate[0]),
            to: convertDateFormate(leftCustomDate[1]),
            from_date: convertDateFormate(leftCustomDate[0]),
            to_date: convertDateFormate(leftCustomDate[1])
          }
        : getDateByNameV1(leftFilter.value)
      ),
  }))
  },[leftFilter,leftCustomDate]);
 
 
  useEffect(()=>{
      if(currentOutlet?.currency!==""){
        setCurrency(currentOutlet?.currency)
      }
  },[currentOutlet]);

    // const handleSelectChart = (chartId, label, title) => {
    //   setSelectedCharts((prevState) => {
    //     // Check if the chart is already selected
    //     const isSelected = prevState.some((chart) => chart.id === chartId);
        
    //     if (isSelected) {
    //       // If already selected, remove it
    //       return prevState.filter((chart) => chart.id !== chartId);
    //     } else {
    //       // Otherwise, add the chart with its details
    //       return [...prevState, { id: chartId, label, title }];
    //     }
    //   });
    // }; 

  let date = new Date();
  date.setDate(date.getDate() - 1);

  const [previewModal, setPreviewModal] = useState(false);
  const [addChartModal, setAddChartModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const showPreviewModal=() => {
      setPreviewModal(true);
  }
  const hidePreviewgModal=() => {
      setPreviewModal(false);
      setExportType("");
  }
  const showAddChartModal=() => {
      setAddChartModal(true);
  }
  const hideAddChartModal=() => {
      setAddChartModal(false);
      redirectToMyDashboard();
  }

  // useEffect(()=>{
  //   setServerListAjax(true);
  // },[])

 
  useEffect(() => {
    let graphs = [];
    selectedMenu.forEach((menu) => {
      graphs = [...graphs, ...menu.graphs];
      if (menu.subItems) {
        menu.subItems.forEach((subItem) => {
          graphs = [...graphs, ...subItem.graphs];
        });
      }
    });
    setGraphList(graphs);
  }, [selectedMenu]);

  const [selectedOption, setSelectedOption] = useState([]); 
  const handleGraphSelect = (selectedGraphs) => {
    setSelectedOption(selectedGraphs);
    setSelectedCharts((prevCharts) => {
      const selectedGraphIds = new Set(selectedGraphs.map((graph) => graph.id));
      const updatedCharts = prevCharts.filter((chart) => selectedGraphIds.has(chart.id));
      const newlySelectedCharts = selectedGraphs.filter(
        (graph) => !prevCharts.some((prevChart) => prevChart.id === graph.id)
      );
      return [...updatedCharts, ...newlySelectedCharts];
    });
  };
  
  // const handleDeleteChart = (chartId) => {
  //   setSelectedCharts((prevCharts) => {
  //     const updatedCharts = prevCharts.filter((chart) => chart.id !== chartId);
  //     setSelectedOption(updatedCharts);
  //     return updatedCharts;
  //   });
  // };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />
        <label style={{ marginLeft: "8px" }}>{props.data.label}</label>
      </components.Option>
    );
  };

  const handleTitleChange = (event) => {
    setChartTitle(event.target.value);
  };

  const pageLimit = 5;
  const [listAjax,setListAjax] = useState(false);
  const [listData, setListData] = useState([]);
  const [refreshList, setRefreshList] = useState(true);
  useEffect(() => {
      setListAjax(true);
      postDataAPI({
        serviceName: REPORTS.serviceName,
        op: REPORTS.op_list_report,
        page: selectedPage,
        limit: pageLimit
      }).then((response) => {
        setListAjax(false);
        if (response.status === "ok") {
          setListData(response.data.result.data);
          setPageCount(Math.ceil(response.data.result.totalData / pageLimit));
        } else {
          setPageCount(0);
          console.log(response);
        }
      });
  }, [refreshList, selectedPage]);

  const handleRefreshList = () => {
    setRefreshList(!refreshList);
    setChartTitle("");
    setCommentary("no");
    setSelectedMenu([]);
    setSelectedCharts([]);
    setSelectedOption([]);
    setIsMyDashboard(0);
    hidePreviewgModal();
    hideAddChartModal();
    redirectToMyDashboard();
  };

  const handleSelectedRowData = (item) => {
    setSelectedRowData(item);
    if (item.time_frame) {
      let selectedLeftFilter = JSON.parse(item.time_frame);
      setLeftFilter(selectedLeftFilter);
      if (selectedLeftFilter.label === "Custom"){
        setLeftCustomDate([selectedLeftFilter.from, selectedLeftFilter.to]);
      }
    }
    setSelectedMenu(JSON.parse(item.menus));
    setSelectedCharts(JSON.parse(item.graphs));
    setSelectedOption(JSON.parse(item.graphs));
    setChartTitle(item.title);
    setOriginalTitle(item.title);
    setCommentary(item.commentry === "Y" ? "yes" : "no");
    setSelectedId(item.export_r_id);
    setIsMyDashboard(Number(item.is_dashboard));
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!active || !over) return;
  
    if (active.id !== over.id) {
      const oldIndex = selectedCharts.findIndex((chart) => chart.id === active.id);
      const newIndex = selectedCharts.findIndex((chart) => chart.id === over.id);
  
      if (oldIndex === -1 || newIndex === -1) return;
  
      setSelectedCharts(arrayMove(selectedCharts, oldIndex, newIndex));
    }
  };


  const handleDelete = (export_r_id) => {
    postDataAPI({
      serviceName: REPORTS.serviceName,
      op: REPORTS.op_delete_report,
      export_r_id: export_r_id,
    }).then((response) => {
      if (response.status === "ok") {
        setRefreshList(!refreshList);
        dispatch(fetchMyDashboardData());
      } else {
        console.log(response);
      }
    });
  }

  const handleIsDhasboard = (event) => {
    setIsMyDashboard(event.target.checked ? 1 : 0);
  }

  const location = useLocation();
  const history = useHistory();
  const editReportData = location.state?.editReportData || null;

  useEffect(() => {
    if (editReportData && editReportData.length>0) {
      handleSelectedRowData(editReportData[0]);
      showAddChartModal(true);
    }
  }, [editReportData]);

  const redirectToMyDashboard = () => {
    if (editReportData) {
      const id = editReportData[0].export_r_id;
      history.replace("/reports", {});
      if (isMyDashboard) {
        history.push("/my-dashboard/"+id);
      }
    }
  }
  
  return (
    <React.Fragment>
        <UiContent />
        <div className="page-content">
            <Container fluid>
            <BreadCrumb title="Reports" pageTitle="Reports"  />
                
                <Row>
                <Card key="ch-list">
                    <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Report List</h4>
                    <div className="flex-shrink-0">
                      <div className="d-flex">
                        <Button color="primary" className="btn-label" onClick={showAddChartModal}> <i className=" ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Report</Button>
                      </div>
                    </div>
                    </CardHeader>                    
                    <CardBody>
                        {
                            listAjax && <Loader />
                        }
                        <div className={
                            listAjax
                                ? "opacity-0 table-responsive table-card"
                                : "table-responsive table-card"
                        }>
                            <table className="table align-middle table-bordered" style={{width:"100%"}}>
                                <thead className="table-light">
                                    <tr style={{ textAlign: 'center',verticalAlign: "middle" }}>
                                        <th scope="col" style={{width:"15%"}}>Title</th>
                                        <th scope="col" style={{width:"10%"}}>Created By</th>
                                        <th scope="col" style={{width:"10%"}}>Timestamp</th>
                                        {/* <th scope="col" style={{width:"10%"}}>From</th>
                                        <th scope="col" style={{width:"10%"}}>To</th> */}
                                        <th scope="col" style={{width:"35%"}}>Selected Graphs</th>
                                        <th scope="col" colSpan={2} style={{width:"10%"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(listData || []).map(
                                  (item, key) => {
                                    let graphTitles = [];
                                    try {
                                      const graphs = JSON.parse(item.graphs);
                                      graphTitles = graphs.map(graph => graph.title).join(" | ");
                                    } catch (error) {
                                      console.error("Error parsing graphs JSON:", error);
                                    }
                                    return (
                                      <tr key={"item-"+item.export_r_id}>
                                        <td className="text-start">{item.title}</td>
                                        <td className="text-start">{item.username}</td>
                                        <td className="text-center">{item.created_at}</td>
                                        {/* <td className="text-center">{item.from_dt}</td>
                                        <td className="text-center">{item.to_dt}</td> */}
                                        <td className="text-start">{graphTitles}</td>
                                        <td className="text-center">
                                          <Button className="btn-icon"
                                          onClick={() => {
                                            handleSelectedRowData(item);
                                            showAddChartModal(true);
                                          }}> <i className="ri-refresh-line" /> 
                                          </Button>
                                        </td>
                                        {/* <td className="text-center">
                                          <Button className="btn-icon" onClick={() => handleDownload(item.ppt_file)}> <i className="ri-download-2-line" /> </Button>
                                        </td> */}
                                        <td className="text-center">
                                          <Button color="danger" className="btn-icon"
                                            onClick={() => {
                                            handleDelete(item.export_r_id);
                                          }}> <i className="ri-delete-bin-line" /> 
                                          </Button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3 float-end">
                          <nav>
                              <CustomPagination
                                  pageCount={pageCount}
                                  setSelectedPage={setSelectedPage}
                              />
                          </nav>
                        </div>
                    </CardBody>
                </Card>
                </Row>
                
                <Modal
                    size="xl"
                    isOpen={previewModal}
                    toggle={hidePreviewgModal}
                    backdrop={'static'}
                    id="staticBackdrop1"
                    centered
                >
                   
                    <ModalHeader className={"modal-header title-full-width"}>
                        <div style={{"display":"flex","justify-content":"space-around"}}>Preview Charts & Export
                            <Button type="button" color={"link"} className="btn-close"
                                onClick={hidePreviewgModal} aria-label="Close"></Button>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                      <Col className="align-items-left">
                      <span style={{ backgroundColor: "#ffff99", padding: "2px 5px", borderRadius: "5px", marginLeft: "8px" }}>
                      <Input
                          id="is_dashboard"
                          className="form-check-input"
                          type="checkbox"
                          checked={isMyDashboard === 1}
                          onChange={handleIsDhasboard}
                      />  Save as My Dashboard
                      </span>
                      </Col><br />
                      <>
                        <ChartsComponents selectedCharts={selectedCharts} leftFilter1={leftFilter1} rightFilter={rightFilter} currency={currency} leftCustomDate={leftCustomDate} />
                      </>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" onClick={hidePreviewgModal} color="light">Cancel</Button>
                        {(exportType === "" || exportType === "ppt") && (
                          <ExportPPT commentary={commentary} userInfo={userInfo} selectedMenu={selectedMenu} 
                          selectedCharts={selectedCharts} leftFilter={leftFilter1} customDate={leftCustomDate} 
                          outletName={currentOutlet.name || ""} reportTitle={chartTitle} onExportComplete={handleRefreshList}
                          isMyDashboard={isMyDashboard} setExportType={setExportType} originalTitle={originalTitle} selectedId={selectedId}
                        />
                        )}
                        {(exportType === "" || exportType === "pdf") && (
                          <ExportPDF commentary={commentary} userInfo={userInfo} selectedMenu={selectedMenu} 
                          selectedCharts={selectedCharts} leftFilter={leftFilter1} customDate={leftCustomDate} 
                          outletName={currentOutlet.name || ""} reportTitle={chartTitle} onExportComplete={handleRefreshList}
                          isMyDashboard={isMyDashboard} setExportType={setExportType} originalTitle={originalTitle} selectedId={selectedId}
                        />
                        )}
                    </ModalFooter>
                </Modal>

                <Modal
                    size="xl"
                    isOpen={addChartModal}
                    toggle={hideAddChartModal}
                    backdrop={'static'}
                    id="addreport"
                    centered
                >
                   
                    <ModalHeader className={"modal-header title-full-width"}>
                        <div style={{"display":"flex","justify-content":"space-around"}}>Add/Edit Report
                            <Button type="button" color={"link"} className="btn-close"
                                onClick={handleRefreshList} aria-label="Close"></Button>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                    <Card className="ribbon-box border shadow-none">
                          <CardBody>
                              <div className="ribbon ribbon-primary round-shape">Title</div>
                              <div className="ribbon-content mt-4 text-muted">
                                  <Row>
                                    <Col sm={12} lg={6}>
                                      <Input
                                            name="title"
                                            className="form-control"
                                            type="text"
                                            value={chartTitle}
                                            onChange={handleTitleChange}
                                        />
                                    </Col>
                                    <Col sm={12} lg={6}>
                                    <Input
                                      name="commentary"
                                      className="form-control"
                                      type="select"                                   
                                      onChange={(e) => setCommentary(e.target.value)}
                                      value={commentary}
                                  >
                                      <option value="no">Without Commentary</option>
                                      <option value="yes">With Commentary</option>
                                  </Input>
                                  </Col>
                                  </Row>
                              </div>
                          </CardBody>
                      </Card>
                      <Card className="ribbon-box border shadow-none">
                          <CardBody>
                              <div className="ribbon ribbon-primary round-shape">Select Timeframe</div>
                              <div className="ribbon-content mt-4 text-muted">
                                  <Row>
                                    <Col sm={12} lg={6}>
                                    <CustomDatePicker
                                      activeDate={leftFilter}
                                      setActiveDate={setLeftFilter}
                                      customDate={leftCustomDate}
                                      setCustomDate={setLeftCustomDate}
                                      posEnd={false}
                                    />
                                    </Col>
                                  </Row>
                              </div>
                          </CardBody>
                      </Card>

                      {(leftFilter.value!=="") &&
                        <Card className="ribbon-box border shadow-none">
                            <CardBody>
                                <div className="ribbon ribbon-primary round-shape">Select Menu</div>
                                <div className="ribbon-content mt-4 text-muted">
                                    <Row>
                                      <Col sm={12} lg={6}>
                                        <div className="form-group mb-3">                          
                                              <Select
                                                options={menuItems}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.id}
                                                isMulti
                                                value={selectedMenu} // Controlled value for dropdown
                                                onChange={(menus) => setSelectedMenu(menus)} // Update selected menus
                                                placeholder="Select Menu"
                                                closeMenuOnSelect={false}
                                                components={{ Option: CustomOption }}
                                              />                                          
                                        </div>
                                      </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                      }
                      {graphList.length > 0 && (
                          <Card className="ribbon-box border shadow-none">
                            <CardBody>
                                <div className="ribbon ribbon-primary round-shape">Select Graphs</div>
                                <div className="ribbon-content mt-4 text-muted">
                                    <Row>
                                      <Col sm={12} lg={6}>
                                        <div className="form-group mb-3">           
                                              <Select
                                                options={graphList}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.id}
                                                isMulti
                                                value={selectedOption} // Controlled value for dropdown
                                                onChange={handleGraphSelect}
                                                placeholder="Select Graphs"
                                                isClearable={false} // Ensures no clearing of selection
                                                closeMenuOnSelect={false}
                                                components={{ Option: CustomOption }}
                                              />                                        
                                        </div>
                                      </Col>                                      
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                      )}

                      {/* {selectedCharts.length > 0 && (
                        <Card className="ribbon-box border shadow-none">
                          <CardBody>
                              <div className="ribbon ribbon-primary round-shape">Selected Graphs</div>
                              <div className="ribbon-content mt-4 text-muted">
                                  <Row>
                                      {selectedCharts.map((chart) => (
                                        <Col sm={2} lg={3} key={chart.id}>
                                        <label className="d-flex">
                                          <img 
                                            src={imageImports[chart.img]}
                                            alt={chart.title} 
                                            className="ms-2" 
                                            style={{ width: "99%", height: "70px", border: "1px solid grey" }} 
                                          />
                                        </label>
                                        </Col>
                                      ))}
                                    </Row>
                                  </div>
                          </CardBody>
                          </Card>
                        )} */}
<>
                        {selectedCharts.length > 0 && (
                          <Card className="ribbon-box border shadow-none">
                            <CardBody>
                              <div className="ribbon ribbon-primary round-shape">Selected Graphs</div>
                              <div className="ribbon-content mt-4 text-muted">
                              <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                <SortableContext items={selectedCharts} strategy={rectSortingStrategy}>
                                  <Row>
                                    {selectedCharts.map((chart) => (
                                      <SortableItem key={chart.id} chart={chart} />
                                    ))}
                                  </Row>
                                </SortableContext>
                              </DndContext>

                              </div>
                            </CardBody>
                          </Card>
                        )}
                      </>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" onClick={hideAddChartModal} color="light">Cancel</Button>
                        {(selectedCharts.length>0 && chartTitle.trim() !== "") ?
                        <Button
                          className="ms-3 float-end"
                          color="primary"
                          onClick={showPreviewModal}
                        >
                        Preview & Export
                        </Button> : ""
                        }
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    </React.Fragment>
  );
};

const SortableItem = ({ chart }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: chart.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
    width: "250px",  // Adjust image size
    height: "75px",
    border: "1px solid grey",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 10px"
  };

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
      <img src={imageImports[chart.img]}  alt={chart.title} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default Reports;