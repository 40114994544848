import { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import {
    Row,
    Col,
    Table,
    Input
} from "reactstrap"
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import Loader from "../../Components/Common/Loader";

const BudgetTab = (props) => {
    const {filterData, filterAjax, handleToastMessage}=props;

    const [serverPerformanceAjax, setServerPerformanceAjax] = useState(true);
    const [dateWiseData, setDateWiseData] = useState([]);
    const [aggregator, setAggregator] = useState("Month");
    const [year, setYear] = useState(new Date().getFullYear());
    const [mealPeriod, setMealPeriod] = useState(0);
    const [filtermealperiod, setFiltermealperiod] = useState([]);
    const [ChangedCover, setChangedCover] = useState({});
    const [ChangedRevenue, setChangedRevenue] = useState({});
    const [originalData, setOriginalData] = useState(dateWiseData);
    const [hideOldDiv, setHideOldDiv] = useState(false);

    useEffect(() => {

        if (filterData.mealperiod) {
            setFiltermealperiod(filterData.mealperiod);
        }
    }, [filterAjax, filterData]);

    useEffect(() => {
        getInfo(mealPeriod, aggregator, year);
    }, [mealPeriod, aggregator, year]);

    const getInfo = (mealPeriod, aggregator, year) => {
        setServerPerformanceAjax(true);
        setHideOldDiv(false);
        postDataAPI({
            serviceName: POST_KPI_DASHBOARD.serviceName,
            op: POST_KPI_DASHBOARD.budget_forecast_list_op,
            mealPeriod: mealPeriod,
            budgetSelectRange: aggregator,
            budgetYear: year
        }).then(response => {
            if (response.status === 'ok') {
                setDateWiseData(response.data.data);
                setOriginalData(response.data.data);
                setServerPerformanceAjax(false);
            } else {
                // console.log(response);
            }
        });
    }

    const handleaggregator = (event) => {
        setAggregator(event.target.value);
    }

    const handleYear = (event) => {
        setYear(event.target.value);
    }

    const handleMealPeriod = (event) => {
        setMealPeriod(event.target.value);
    }

    const handleInputCover = (event, date) => {
        setHideOldDiv(true);
        const { name, value } = event.target;
        setDateWiseData(dateWiseData.map((data) => {
            if (data.from_date_display == date) {
                data[name] = value;
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedCover({ ...ChangedCover, [date]: true });
        // if (value != '0' && value != '') {
        //     setCheckboxChecked(date, true);
        // } else {
        //     setCheckboxChecked(date, false);
        // }
        setCheckboxChecked(date, true);

        // Calculate Average Check if Revenue is available
        const revenue = dateWiseData.find(data => data.from_date_display == date)?.revenue || 0;
        if (revenue && value != '' && value != '0') {
            const avg_check = parseFloat(revenue) / parseFloat(value);
            // console.log('Average Check:', avg_check);
            setDateWiseData(dateWiseData.map((data) => {
                if (data.from_date_display == date) {
                    data['avg_check'] = avg_check.toFixed(2);
                }
                return data;
            }));
            // Set the Average Check in your state or perform further actions as needed
        }
    }

    const handleInputRevenue = (event, date) => {
        setHideOldDiv(true);
        const { name, value } = event.target;
        setDateWiseData(dateWiseData.map((data) => {
            if (data.from_date_display == date) {
                data[name] = value;
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedRevenue({ ...ChangedRevenue, [date]: true });
        // if (value != '0' && value != '') {
        //     setCheckboxChecked(date, true);
        // } else {
        //     setCheckboxChecked(date, false);
        // }
        setCheckboxChecked(date, true);

        // Calculate Average Check if Covers is available
        const covers = dateWiseData.find(data => data.from_date_display == date)?.covers || 0;
        if (covers && value != '' && value != '0') {
            const avg_check = parseFloat(value) / parseFloat(covers);
            // console.log('Average Check:', avg_check);
            setDateWiseData(dateWiseData.map((data) => {
                if (data.from_date_display == date) {
                    data['avg_check'] = avg_check.toFixed(2);
                }
                return data;
            }));
            // Set the Average Check in your state or perform further actions as needed
        }
    }

    const handleInputAvgCheck = (event, date) => {
        const { name, value } = event.target;
        setDateWiseData(dateWiseData.map((data) => {
            if (data.from_date_display == date) {
                data['avg_check'] = value;
            }
            return data;
        }));   
        const covers = dateWiseData.find(data => data.from_date_display == date)?.covers || 0;
        const revenue = parseFloat(covers) * parseFloat(value); 
        if(covers !== 0){
            setDateWiseData(dateWiseData.map((data) => {
                if (data.from_date_display == date) {
                    data['revenue'] = revenue.toFixed(2);
                }
                return data;
            }));
        }
        // Update the changed inputs state
        setChangedCover({ ...ChangedCover, [date]: true });
        // if (value != '0' && value != '') {
        //     setCheckboxChecked(date, true);
        // } else {
        //     setCheckboxChecked(date, false);
        // }
        setCheckboxChecked(date, true);
    }

    const handleSubmit = () => {
        setServerPerformanceAjax(true);
        const checkedRowsData = [];
        for (let i = 0; i < dateWiseData.length; i++) {

            let valId = 'budget_checkbox_' + i;
            var checkbox = document.getElementById(valId);
            if (checkbox.checked) {
                checkedRowsData.push({
                    "br_id": dateWiseData[i]['br_id'],
                    "covers": dateWiseData[i]['covers'],
                    "revenue": dateWiseData[i]['revenue']
                    // "avg_check": dateWiseData[i]['avg_check']
                });
            } else {
                // console.log('Checkbox is not checked');
            }

        }
        // console.log("Checked Rows Data:", checkedRowsData);
        postDataAPI({
            serviceName: POST_KPI_DASHBOARD.serviceName,
            op: POST_KPI_DASHBOARD.save_budget_data_op,
            mealPeriod: mealPeriod,
            budgetSelectRange: aggregator,
            budgetYear: year,
            budget_data: checkedRowsData
        }).then(response => {
            if (response.status === "ok") {
                handleToastMessage('success', response.message);
                setServerPerformanceAjax(false);
                setTimeout(() => {
                    // window.location.reload();
                }, 2000);
                getInfo(mealPeriod, aggregator, year);
            } else {
                handleToastMessage('error', response.message);
            }
        });
    };

    const setCheckboxChecked = (date, checked) => {
        setDateWiseData(dateWiseData.map((data) => {
            if (data.from_date_display === date) {
                data.isChecked = checked;
            }
            return data;
        }));
    };
    return (
        <>
            <Row>
                <Col lg={3} >
                    <select value={aggregator} onChange={handleaggregator} className="form-select mb-3">
                        <option value="Day">Day</option>
                        <option value="Week">Week</option>
                        <option value="Month">Month</option>
                        <option value="Year">Year</option>
                    </select>
                </Col>
                <Col lg={3}>
                    <select value={year} onChange={handleYear} className="form-select mb-3">
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </select>
                </Col>
                <Col lg={3}>
                    <select
                        className="form-select"
                        value={mealPeriod}
                        id="mealPeriod"
                        onChange={handleMealPeriod}
                    >
                        <option value="0">All Meal Periods</option>
                        {!filterAjax &&
                            filtermealperiod.length > 0 &&
                            filtermealperiod.map((item) => {
                                return (
                                    <option key={item.sp_id} value={item.sp_id}>
                                        {item.title +
                                            " (" +
                                            item.start_time +
                                            " To " +
                                            item.end_time +
                                            ")"}
                                    </option>
                                );
                            })}
                    </select>
                </Col>
                <Col lg={3}>
                    <button className="btn btn-primary"
                        style={{ float: 'right' }}
                        onClick={handleSubmit}>
                        Save
                    </button>
                </Col>
            </Row>
            {serverPerformanceAjax && <Loader />}
            <div
                className={
                    serverPerformanceAjax
                        ? "opacity-0"
                        : ""
                }
            >
                <div className="table-responsive">
                    <Table className="table-bordered table-nowrap text-center align-middle mb-0 table-sm">
                        <thead style={{ position: 'sticky', top: '-2px', background: '#fff', zIndex: '10' }}>
                            <tr>
                                <th className="text-center" scope="col" style={{ width: '20%' }}>{aggregator == 'Month' ? 'Months' : 'Date'}</th>
                                <th className="text-center" scope="col" style={{ width: '26%' }}>Covers</th>
                                <th className="text-center" scope="col" style={{ width: '26%' }}>Revenue</th>
                                <th className="text-center" scope="col" style={{ width: '15%' }}>Avg Check</th>
                                <th className="text-center" scope="col" style={{ width: '10%' }}>Apply</th>
                            </tr>
                        </thead>
                        <tbody style={{ maxHeight: '400px', background: '#fff', display: 'table-caption' }}>
                            {dateWiseData.map((data, index) => {
                                const checkboxId_budget = `budget_checkbox_${index}`;
                                return (
                                    <tr key={data.from_date} style={{ width: '100%', display: 'inline-table' }}>
                                        <td style={{ width: '20%' }}>{data.from_date_display}</td>
                                        <td style={{ width: '26%' }}>
                                            <div style={{ width: '100%', display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Input
                                                        name="covers"
                                                        className="form-control form-control-sm"
                                                        type="number"
                                                        value={data.covers}
                                                        style={{ maxWidth: "150px", margin: "auto", fontSize: '13px' }}
                                                        onChange={(event) => handleInputCover(event, data.from_date_display)}
                                                    // onBlur={(event) => handleInputCover(event, data.from_date_display)}
                                                    />
                                                </div>
                                                {hideOldDiv && (
                                                    <div style={{ width: '50%' }}>
                                                        {ChangedCover[data.from_date_display] && (
                                                            <div style={{ marginLeft: '20px', marginTop: '3px' }}>
                                                                {originalData.find(d => d.from_date_display === data.from_date_display) && originalData.find(d => d.from_date_display === data.from_date_display).oCovers !== '' ? (
                                                                    <div style={{ background: '#f06548', padding: '3px 10px', borderRadius: '4px', color: '#fff', width: 'max-content' }}>
                                                                        Old : {originalData.find(d => d.from_date_display === data.from_date_display).oCovers}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ width: '26%' }}>
                                            <div style={{ width: '100%', display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Input
                                                        name="revenue"
                                                        className="form-control form-control-sm"
                                                        type="number"
                                                        value={data.revenue}
                                                        style={{ maxWidth: "150px", margin: 'auto', fontSize: '13px' }}
                                                        onChange={(event) => handleInputRevenue(event, data.from_date_display)}
                                                    // onBlur={(event) => handleInputRevenue(event, data.from_date_display)}
                                                    />
                                                </div>
                                                {hideOldDiv && (
                                                    <div style={{ width: '50%' }}>
                                                        {ChangedRevenue[data.from_date_display] && (
                                                            <div style={{ marginLeft: '20px', marginTop: '3px' }}>
                                                                {originalData.find(d => d.from_date_display === data.from_date_display) && originalData.find(d => d.from_date_display === data.from_date_display).oRevenue !== '' ? (
                                                                    <div style={{ background: '#f06548', padding: '3px 10px', borderRadius: '4px', color: '#fff', width: 'max-content' }}>
                                                                        Old : {originalData.find(d => d.from_date_display === data.from_date_display).oRevenue}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ width: '15%' }}>
                                            <Input
                                                name="avg_check"
                                                className="form-control form-control-sm"
                                                type="number"
                                                value={data.avg_check}
                                                style={{ maxWidth: "150px", margin: "auto", fontSize: '13px' }}
                                                onChange={(event) => handleInputAvgCheck(event, data.from_date_display)}
                                            // onBlur={(event) => handleInputAvgCheck(event, data.from_date_display)}
                                            />
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            {/* <i className="ri-code-box-line"></i> */}
                                            <div className="flex-shrink-0">
                                                <div className="form-check">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={checkboxId_budget}
                                                        checked={data.isChecked}
                                                        style={{ margin: 'auto', display: 'block', pointerEvents: 'none' }}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>)
}
export default BudgetTab;