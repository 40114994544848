import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SSO_LOGIN_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import {postDataAPI} from "../../../helpers/phpbackend_helper";

import {POST_LOGIN_DATA} from "../../../helpers/data_helper";
import {saveLoggedinUser} from "../../../helpers/api_helper";
let md5 = require('md5');

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postDataAPI, {
      uname: user.email,
      pwd: md5(user.password),
      op:POST_LOGIN_DATA.op,
      serviceName:POST_LOGIN_DATA.serviceName
    });
    saveLoggedinUser(JSON.stringify(response.data));
    if (response.status === "ok") {
      yield put(loginSuccess(response));
      history.push("/dashboard");
    } else {
      yield put(apiError(response));
    }

  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    saveLoggedinUser('');
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* ssoLoginUser({ payload: { token, history } }) {
  try {
    const response = yield call(postDataAPI, {
      serviceName: "user_sso",
      op: "ssologin-result",
      token: token
    });
    saveLoggedinUser(JSON.stringify(response.data));
    if (response.status === "ok") {
      yield put(loginSuccess(response));
      history.push("/dashboard");
    } else {
      yield put(apiError(response));
    }

  } catch (error) {
    yield put(apiError(error));
  }
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(SSO_LOGIN_USER, ssoLoginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
