import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import itemCategory from "../../assets/images/item-category.jpg";
import { Basic } from "./ScatterCharts";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import BestSellingProducts from "../DashboardEcommerce/BestSellingProducts";
import PortfolioAnalysis from "./PortfolioAnalysis";
import Loader from "../../Components/Common/Loader";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import {
  getDateByName,
  displayDate,
  convertDateFormate,
} from "../../helpers/api_helper";
import CustomDatePicker from "../../Components/Common/CustomDatePicker";
import { ToastContainer } from "react-toastify";
import FoodTribesChart from "./FoodTribesChart";
const MenuEngineering = () => {
  document.title = "Menu Engineering | " + process.env.REACT_APP_NAME;
  const [defaultCourse, setDefaultCourse] = useState(0);

  /*const [activeDate, setActiveDate] = useState({
    label: "Today",
    value: "Today",
  });*/
  const [currency, setCurrency] = useState('$');
  const [leftCustomDate, setLeftCustomDate] = useState([]);
  const [leftFilter, setLeftFilter] = useState({
    label: "Yesterday",
    value: "Yesterday",
  });
  const [rightFilter, setRightFilter] = useState(false);
  const [rightFilterToggle, setRightFilterToggle] = useState(false);
  const [mealPeriod, setMealPeriod] = useState("All");
  const [ordersTime, setOrdersTime] = useState(false);
  const [comparisonDate, setComparisonDate] = useState([]);
  const [ordersTimeComparison, setOrdersTimeComparison] = useState([]);
  const [filtermealperiod, setFiltermealperiod] = useState([]);
  const [filtercourses, setFilterCourses] = useState([]);
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [menuItemData, setMenuItemData] = useState({
    Courses: [],
    MenuItems: [],
  });
  const [menuItemAjax, setMenuItemAjax] = useState(true);
  const [foodTribeData, setFoodTribeData] = useState({
    Covers: [],
    AvgCheck: [],
    CourseName: [],
  });
  const [foodTribeAjax, setFoodTribeAjax] = useState(true);
  const [selectedPageBST, setSelectedPageBST] = useState(0);
  const [selectedPage_ProfitAnalysis, setSelectedPage_ProfitAnalysis] = useState(0);
  /*const foodItemsSeries = menuItemData.Courses.filter(function (foodItem) {
    return defaultCourse === 0
      ? true
      : parseInt(foodItem.id) === defaultCourse;
  });*/
  /*let foodBeverageSeries = menuItemData.MenuItems[defaultCourse]??[];
  if(defaultCourse===0){
    Object.keys(menuItemData.MenuItems).map((key) => (
      foodBeverageSeries=[...foodBeverageSeries,...menuItemData.MenuItems[key]]
    ))
  }
  console.log('foodBeverageSeries',foodBeverageSeries);*/
  const ShowModelPopupToggle = () => {
    setShowModelPopup(!showModelPopup);
  };
  const changeDefaultComparison = (item) => {
    if (item.value !== "Custom") {
      setRightFilterToggle(false);
    }
    setRightFilter(item);
  };
  const changeLeftFilter = (value) => {
    setLeftFilter(value);
  };
  const changeMealPeriod = (value) => {
    setMealPeriod(value);
  };

  const { filterData, filterAjax } = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
  }));
  const { currentOutlet } = useSelector(state => ({
    currentOutlet: state.UserOutletGroup.currentOutlet
  }));
  useEffect(()=>{
    if(currentOutlet?.currency!=""){
      setCurrency(currentOutlet?.currency)
    }
  },[currentOutlet])
  var chartLineColumnColors = getChartColorsArray(
    '["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning"]'
  );
  const fooedTribesSeries = [
    {
      name: "Avg Check",
      type: "line",
      data: foodTribeData["AvgCheck"],
    },
    {
      name: "Item Sold",
      type: "column",
      data: foodTribeData["Covers"],
    },
  ];
  let chartLineColumnYaxis = [
    {
      title: {
        seriesName: "Avg Check",
        text: "Avg Check",
        style: {
          fontWeight: 600,
        },
      },
      labels: {
          formatter: function (value) {
              return value.toFixed(0);
          },
      },
    },
    {
      opposite: true,
      seriesName: "Item Sold",
      title: {
        text: "Item Sold",
        style: {
          fontWeight: 600,
        },
      },
      decimalsInFloat: 0,
    },
  ];

  if (foodTribeData["AvgCheckPY"]) {
    fooedTribesSeries.push({
      name: "Avg Check PY",
      type: "line",
      data: foodTribeData["AvgCheckPY"],
    });
    chartLineColumnYaxis.push({
      title: {
        seriesName: "Avg Check",
      },
      show: false,
    });
  }
  if (foodTribeData["CoversPY"]) {
    fooedTribesSeries.push({
      name: "Covers PY",
      type: "column",
      data: foodTribeData["CoversPY"],
    });
    chartLineColumnYaxis.push({
      title: {
        seriesName: "Item Sold",
      },
      show: false,
    });
  }
  const fooedTribesOptions = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    stroke: {
      width: [4, 0, 4, 0],
      curve: "smooth",
    },
    title: {
      text: "",
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return value;
      },
    },
    labels: foodTribeData["CourseName"],
    xaxis: {
      type: "category",
    },
    yaxis: chartLineColumnYaxis,
    colors: chartLineColumnColors,
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    tooltip: {
      y: {
          formatter: function (value, { seriesIndex }) {
              // Check the seriesIndex to format the value accordingly
              if (seriesIndex === 0) {
                  return value.toFixed(2); 
              } else {
                return value;
              }
          },
      },
  },
  };
  const activeComparisonData = ordersTimeComparison.find(
    (time) => rightFilter.value === time.value
  );
  useEffect(() => {
    localStorage.setItem('Selling_items_SortType', '');
    localStorage.setItem('Portfolio_analysis_SortType', '');    
    if (filterData.date) {
      setOrdersTime(filterData.date);
    }
    if (filterData.date_compare) {
      setOrdersTimeComparison(filterData.date_compare);
    }
    if (filterData.mealperiod) {
      setFiltermealperiod(filterData.mealperiod);
    }
    if (filterData.courses) {
      setFilterCourses(filterData.courses);
      filterData.courses.map((course) =>
        course.label === "All" ? setDefaultCourse(parseInt(course.value)) : ""
      );
    }
  }, [filterAjax, filterData]);
  useEffect(() => {
    if (
      (leftFilter.value !== "Custom" ||
        (leftFilter.value === "Custom" &&
          leftCustomDate[0] &&
          leftCustomDate[1])) &&
      (rightFilter.value !== "Custom" ||
        (rightFilter.value === "Custom" &&
          comparisonDate[0] &&
          comparisonDate[1]))
    ) {
      let leftFilterData =
        leftFilter.value === "Custom"
          ? {
              from: convertDateFormate(leftCustomDate[0]),
              to: convertDateFormate(leftCustomDate[1]),
            }
          : getDateByName(leftFilter.value);
      let rightFilterData =
        activeComparisonData && activeComparisonData.datepiker
          ? {
              from: convertDateFormate(comparisonDate[0]),
              to: convertDateFormate(comparisonDate[1]),
            }
          : getDateByName(rightFilter.value);
      postDataAPI({
        leftFilter: leftFilterData,
        rightFilter: rightFilterData,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.item_graph_op,
      }).then((response) => {
        setMenuItemAjax(false);
        if (response.status === "ok") {
          setMenuItemData(response.data);
        } else {
          console.log(response);
        }
      });

      // postDataAPI({
      //   leftFilter: leftFilterData,
      //   rightFilter: rightFilterData,
      //   mealPeriod: mealPeriod,
      //   course: defaultCourse,
      //   serviceName: POST_MENU_OPTIMIZATION.serviceName,
      //   op: POST_MENU_OPTIMIZATION.food_tribes_op,
      // }).then((response) => {
      //   setFoodTribeAjax(false);
      //   if (response.status === "ok") {
      //     setFoodTribeData(response.data);
      //   } else {
      //     console.log(response);
      //   }
      // });
    }
  }, [
    leftFilter,
    rightFilter,
    mealPeriod,
    comparisonDate,
    activeComparisonData,
    leftCustomDate,
    defaultCourse,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Menu Optimization" pageTitle="Dashboard" />
          <form className="mb-3">
            <Row>
              <Col className="mb-2 mb-lg-0" sm={12} lg={4}>
                <CustomDatePicker
                  activeDate={leftFilter}
                  setActiveDate={setLeftFilter}
                  customDate={leftCustomDate}
                  setCustomDate={setLeftCustomDate}
                  posEnd={false}
                />
              </Col>
              {/*
              <Col className="mb-2 mb-lg-0" sm={12} lg={3}>
                <UncontrolledDropdown isOpen={rightFilterToggle} toggle={() => { setRightFilterToggle(!rightFilterToggle); }}>
                  <DropdownToggle
                    type="button"
                    tag="button"
                    className="btn btn-outline-custom"
                  >
                    {rightFilter ? rightFilter.label+(rightFilter.label==='Custom'?displayDate(comparisonDate):'') : "Select Comparison"}
                    <span className="rightBox">
                      <span></span>
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        className="css-tj5bde-Svg"
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="custom-menu-width" end={true}>
                    <Row>
                      <Col
                        sm={
                          rightFilter &&
                          activeComparisonData &&
                          activeComparisonData.datepiker
                            ? 4
                            : 12
                        }
                      >
                        {!filterAjax &&
                          ordersTimeComparison.length > 0 &&
                          ordersTimeComparison.map((item) => {
                            return (
                              <button
                                key={item.value}
                                onClick={() => changeDefaultComparison(item)}
                                type="button"
                                tabIndex="0"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                {item.label}
                              </button>
                            );
                          })}
                      </Col>
                      {rightFilter &&
                        activeComparisonData &&
                        activeComparisonData.datepiker && (
                          <Col sm={8}>
                            <Flatpickr
                              className="form-control border-0 dash-filter-picker shadow mw-auto"
                              placeholder=""
                              value={comparisonDate}
                              onChange={([date, date2]) => {
                                if(date !== undefined && date2 !== undefined){
                                  setRightFilterToggle(false);
                                }
                                setComparisonDate([date, date2]);
                              }}
                              options={{
                                mode: "range",
                                dateFormat: "d M, Y",
                                inline: true,
                              }}
                            />
                          </Col>
                        )}
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              */}
              <Col sm={12} lg={4}>
                <select
                  className="form-select"
                  value={mealPeriod}
                  id="mealPeriod"
                  onChange={(event) => changeMealPeriod(event.target.value)}
                >
                  <option value="0">All Meal Periods</option>
                  {!filterAjax &&
                    filtermealperiod.length > 0 &&
                    filtermealperiod.map((item) => {
                      return (
                        <option key={item.sp_id} value={item.sp_id}>
                          {/* {item.title} */}
                          {item.title + ' (' + item.start_time + ' To ' + item.end_time +')'}
                        </option>
                      );
                    })}
                </select>
              </Col>
              <Col sm={12} lg={4}>
                <select
                  className="form-select"
                  value={defaultCourse}
                  id="course"
                  onChange={(event) =>
                    setDefaultCourse(parseInt(event.target.value))
                  }
                >
                  <option value="0">All</option>
                  {!filterAjax &&
                    filtercourses.length > 0 &&
                    filtercourses.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
              </Col>
            </Row>
          </form>
          
            {/*
          <Row>
            <Col lg={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Course</h4>
                </CardHeader>
                <CardBody>
                  { menuItemAjax && <Loader />}
                  <div className={menuItemAjax ? "opacity-0" : ""}>
                    <Basic
                      series={foodItemsSeries}
                      leftText="Contribution Margin"
                      dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info"]'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Menu Items</h4>
                </CardHeader>
                <CardBody>
                  {menuItemAjax && <Loader />}
                  <div className={menuItemAjax ? "opacity-0" : ""}>
                    <Basic
                      series={menuItemData.MenuItems}
                      leftText="Contribution Margin"
                      dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
            */}
          <Row>
            <Col xl={12}>
              <FoodTribesChart leftFilter={leftFilter} rightFilter={rightFilter} leftCustomDate={leftCustomDate} mealPeriod={mealPeriod} currency={currency} defaultCourse={defaultCourse} comparisonDate={comparisonDate} activeComparisonData={activeComparisonData} />
              {/* <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Food Tribes</h4>
                </CardHeader>
                <CardBody>
                  {foodTribeAjax && <Loader />}
                  <div className={foodTribeAjax ? "opacity-0" : ""}>
                    <ReactApexChart
                      options={fooedTribesOptions}
                      series={fooedTribesSeries}
                      type="line"
                      height={350}
                    />
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          {/* START */}
          <Row>
            <Col xl={12}>
              <PortfolioAnalysis
                globalDate={leftFilter}
                globalCustomDate={leftCustomDate}
                mealPeriod={mealPeriod}
                defaultCourse={defaultCourse}
                selectedPage={selectedPage_ProfitAnalysis}
                setSelectedPage={setSelectedPage_ProfitAnalysis}
                menuItemData={menuItemData}
                currency={currency}
              />
            </Col>
          </Row>
          {/* END */}
          <Row>
            <Col xl={12}>
              <BestSellingProducts
                globalDate={leftFilter}
                globalCustomDate={leftCustomDate}
                mealPeriod={mealPeriod}
                defaultCourse={defaultCourse}
                selectedPage={selectedPageBST}
                setSelectedPage={setSelectedPageBST}
                menuItemData={menuItemData}
                currency={currency}
                rightFilter={rightFilter}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={showModelPopup}
        toggle={() => {
          ShowModelPopupToggle();
        }}
        centered
      >
        <ModalHeader className="justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              ShowModelPopupToggle();
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody>
          <img src={itemCategory} alt="" className="img-fluid" />
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default MenuEngineering;
