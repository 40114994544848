/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { TableCoverLineChar } from "./TableMixByPartySize";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_TABLE_SEAT_OPTIMIZATION } from "../../helpers/data_helper";
import Loader from "../../Components/Common/Loader";
import { getDateByName, coverColor } from "../../helpers/api_helper";

const AvgCheckSpentSlotsChart = ({activeDate, customDate, mealPeriod}) => {
  const [tableMixPartyAvgData, setTableMixPartyAvgData] = useState({
    categories: [],
    series: [],
  });
  const [tableMixPartyAvgAjax, setTableMixPartyAvgAjax] = useState(true);
  const [allHaveCoverZeroAvg, setallHaveCoverZeroAvg] = useState(true);

  useEffect(() => {

    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;
        
    let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);

    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      setTableMixPartyAvgAjax(true)
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.table_cover_avgchk,
      }).then((response) => {
        setTableMixPartyAvgAjax(false);
        if (response.status === "ok") {
          setTableMixPartyAvgData(response.data);
          setallHaveCoverZeroAvg(response.data.series.every(obj => obj.name === "Cover - 0"))
        } else {
          console.log(response);
        }
      });
    }
  }, [activeDate, customDate, mealPeriod]);
  
  return (
    <Card>
        <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
            AVG Check Spent By Slots
            </h4>
        </CardHeader>
        <CardBody>
            {tableMixPartyAvgAjax && <Loader />}
            <div id="AvgCheckSpentSlotsChart" className={tableMixPartyAvgAjax ? "opacity-0" : ""}>
            <TableCoverLineChar
                data={tableMixPartyAvgData}
                dataColors={coverColor}
                allHaveCoverZeroAvg={allHaveCoverZeroAvg}
                // dataColors='["--vz-primary", "--vz-success", "--vz-danger", "--vz-warning"]'
            />
            </div>
        </CardBody>
    </Card>
  );
};

export default AvgCheckSpentSlotsChart;
