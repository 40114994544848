/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef, useState, useMemo } from "react";
import Loader from "../../Components/Common/Loader";
import { Col, Row, Card, CardHeader, CardBody } from "reactstrap";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { DISCOUNT } from "../../helpers/data_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import {
    currencyFormatWithAlert,
} from "../../helpers/api_helper";
import PerformanceExport from "./PerformanceExport";

const DiscountPerformance = ({mealPeriod, leftFilter, discountCodes, serveList}) => {
    const [selectedPage, setSelectedPage] = useState(0);    
    const [pageCount, setPageCount] = useState(0);
    const [discountListAjax, setDiscountListAjax] = useState(true);
    const [itemData, setItemData] = useState([]);
    const [discountItem, setDiscountItem] = useState([]);  
    let itemInd = 1;
    const [maxValues, setMaxValues] = useState({});
    const [sortedData, setSortedData] = useState([]);
    const [limit, setLimit] = useState(10);

    const getPercentage = (value, maxValue) => {
        if (maxValue === 0) return 0;
        return (value / maxValue) * 100;
    };
    
    const getBgColor = (percentage) => {
        if (percentage === 0) return '#F8696B';
        if (percentage > 0 && percentage <= 20) return '#FA8A72';
        if (percentage > 20 && percentage <= 70) return '#FECB7E';
        if (percentage > 70 && percentage < 100) return '#8AC97D';
        if (percentage >= 100) return '#63BE7B';
        return '';
    };
   
    useEffect(() => {
        setDiscountListAjax(true);
        setItemData([]);
        //setSortedData([]);
        postDataAPI({
            serviceName: DISCOUNT.serviceName,
            op: DISCOUNT.op_discount_performance,
            leftFilter: leftFilter,
            mealPeriod: mealPeriod,
            page: selectedPage,
            server_id: serveList,
            discount_id:discountCodes,
            limit: limit
        }).then((response) => {
            setDiscountListAjax(false);
            if (response.status === "ok") {
                //setDiscountItem(response.data.data);  
                //setDiscountItem(() => response.data.data);              
                //setPageCount(Math.ceil(((response.data.data).length) / limitdroddown));

                //Find maxvalues
                const filteredData = response.data.data.slice(4);
                const requiredIndices = [1, 3, 5, 7, 9, 10, 11, 12];
                const maxValuesObj = {};
                requiredIndices.forEach((index) => (maxValuesObj[index] = Number.NEGATIVE_INFINITY));
                filteredData.forEach((row) => {
                    requiredIndices.forEach((index) => {
                        const value = parseFloat(row[index]);
                        if (!isNaN(value)) {
                        maxValuesObj[index] = Math.max(maxValuesObj[index], value);
                        }
                    });
                });
                setMaxValues(maxValuesObj);

                const first4Rows = response.data.data.slice(0, 4);
                //Sorting
                const sorted = filteredData.sort((a, b) => parseFloat(b[7]) - parseFloat(a[7])); // Sort based on item[8]
                
                const sortedData = [...first4Rows, ...sorted];
                setItemData(sortedData);
            }else{
                setPageCount(0);
            }
        });
    }, [mealPeriod, discountCodes, selectedPage, limit]);

    useEffect(() => {
        if (itemData.length === 0) return;

        const filteredData = itemData.slice(4);
        const first4Rows = itemData.slice(0, 4);
        const limitedRows = filteredData.slice(0, limit === "-1" ? itemData.length : limit);
        const sortedData = [...first4Rows, ...limitedRows];
        setDiscountItem(sortedData);

    }, [itemData, limit]);

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
          Discount Performance
        </h4>
        <div className="flex-shrink-0">
        </div>
        <span className="p-2">Top</span>
        <select value={limit} className="form-select w-auto d-inline-block ms-2" style={{ cursor: 'pointer' }} onChange={(e) => setLimit(e.target.value)}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="-1">All</option>
        </select>
        <PerformanceExport
            discountItem={itemData}
            limit={limit}
            getPercentage={getPercentage}
            getBgColor={getBgColor}
            maxValues={maxValues}
        />
      </CardHeader>

      <CardBody>
        {discountListAjax && <Loader />}
        <div id="DiscountPerformance"
            className={
                discountListAjax
                ? "opacity-0 table-responsive table-card"
                : "table-responsive table-card"
            }
            style={{ maxHeight: '690px', overflow: 'auto' }}
        >
        <table className={discountListAjax ? "opacity-0" : "table table-bordered table-nowrap"}>
          <thead>
            <tr style={{ position: 'sticky', top: '-1px', zIndex: 50, backgroundColor: "#6B7ACC"  }}>
              <th scope="col" rowSpan="2" className="align-middle" style={{ width: "20%" }} >
                {limit !== "-1" ? (
                <>
                    Top {limit} <br />
                </>
                ) : ""}
                DISCOUNT PERFORMANCE
              </th>
              <th scope="col" colSpan="8" className="text-center">
                COVERS
              </th>
              <th scope="col" colSpan="4" className="text-center">
                AVERAGE CHECK
              </th>
            </tr>
            {(discountItem || []).map((item, key) => {
                if (key === 0) {
                    return(
                    <tr key={`header-${key}`} style={{ position: 'sticky', top: '44px', zIndex: 45, backgroundColor: "#6B7ACC" }}>
                        <th className="text-center" scope="row">
                            {item[1]}
                        </th>
                        <th className="text-center" scope="row" >
                            {item[2].replace(/'/g, '')}
                        </th>
                        <th className="text-center" scope="row">
                            {item[3]}
                        </th>
                        <th className="text-center" scope="row" >
                            {item[4].replace(/'/g, '')}
                        </th>
                        <th className="text-center" scope="row">
                            {item[5]}
                        </th>
                        <th className="text-center" scope="row" >
                            {item[6].replace(/'/g, '')}
                        </th>
                        <th className="text-center" scope="row">
                            {item[7]}
                        </th>
                        <th className="text-center" scope="row" >
                            {item[8].replace(/'/g, '')}
                        </th>
                        <th className="text-center" scope="row">
                            {item[9]}
                        </th>
                        <th className="text-center" scope="row">
                            {item[10]}
                        </th>
                        <th className="text-center" scope="row">
                            {item[11]}
                        </th>
                        <th className="text-center" scope="row">
                            {item[12]}
                        </th>
                    </tr>
                    )
                }
                return null;
            })}
            </thead>
            <tbody>
            {(discountItem || []).map((item, key) => {
                {itemInd++}
                if (key === 0) return null;
                if (key === 1) {
                    return(
                    <tr key={`body-${key}`} style={{ position: 'sticky', top: '88px', zIndex: 40, backgroundColor: "#6B7ACC" }}>
                        <td>{item[0]}</td>
                        <td className="text-center">
                            {/* <i className=" ri-arrow-right-fill text-warning"></i> */}
                            {item[1]}
                        </td>
                        <td className="text-center">
                            {item[2].replace(/'/g, '')}
                        </td>
                        <td className="text-center">
                            {/* <i className=" ri-arrow-up-fill text-success"></i> */}
                            {item[3]}
                        </td>
                        <td className="text-center">
                            {item[4].replace(/'/g, '')}
                        </td>
                        <td className="text-center">
                            {/* <i className=" ri-arrow-down-fill text-danger"></i> */}
                            {item[5]}
                        </td>
                        <td className="text-center" >
                            {item[6].replace(/'/g, '')}
                        </td>
                        <td className="text-center">
                            {/* <i className=" ri-arrow-up-fill text-success"></i> */}
                            {item[7]}
                        </td>
                        <td className="text-center" >
                            {item[8].replace(/'/g, '')}
                        </td>
                        <td className="text-center">
                            {/* <i className=" ri-arrow-down-fill text-danger"></i> */}
                            {currencyFormatWithAlert(item[9])}
                        </td>
                        <td className="text-center ">
                            {/* <i className=" ri-arrow-down-fill text-danger"></i> */}
                            {currencyFormatWithAlert(item[10])}
                        </td>
                        <td className="text-center">
                            {/* <i className=" ri-arrow-up-fill text-success "></i> */}
                            {currencyFormatWithAlert(item[11])}
                        </td>
                        <td className="text-center">
                            {/* <i className=" ri-arrow-down-fill text-danger"></i> */}
                            {currencyFormatWithAlert(item[12])}
                        </td>
                    </tr>
                    )
                }
                if (key === 2 || key === 3) {
                    return(
                    <tr key={`body1-${itemInd}`} style={{ position: 'sticky', top: key === 2 ? '133px' : '177px', zIndex: key === 2 ? 35 : 30, backgroundColor: "#5592E4" }}>
                        <td className="">{item[0]}</td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-subtract-fill text-warning"></i> */}
                            {item[1]}
                        </td>
                        <td
                            className="text-center"
                        >
                            {item[2]}%
                        </td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-subtract-fill text-warning"></i> */}
                            {item[3]}
                        </td>
                        <td
                            className="text-center"
                        >
                            {item[4]}%
                        </td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-arrow-down-s-fill text-success"></i> */}
                            {item[5]}
                        </td>
                        <td
                            className="text-center"
                        >
                            {item[6]}%
                        </td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-arrow-up-s-fill text-success"></i> */}
                            {item[7]}
                        </td>
                        <td
                            className="text-center"
                        >
                            {item[8]}%
                        </td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-subtract-fill text-warning"></i> */}
                            {currencyFormatWithAlert(item[9])}
                        </td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-subtract-fill text-warning"></i> */}
                            {currencyFormatWithAlert(item[10])}
                        </td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-arrow-down-s-fill text-success"></i> */}
                            {currencyFormatWithAlert(item[11])}
                        </td>
                        <td
                            className="text-center"
                        >
                            {/* <i className="ri-arrow-up-s-fill text-success"></i> */}
                            {currencyFormatWithAlert(item[12])}
                        </td>
                    </tr>
                    )
                }
                if (key > 3) {
                    const value1 = Number(item[1]);
                    const percentage1 = getPercentage(value1, maxValues[1]);
                    const bgColor1 = getBgColor(percentage1);

                    const value3 = Number(item[3]);
                    const percentage3 = getPercentage(value3, maxValues[3]);
                    const bgColor3 = getBgColor(percentage3);

                    const value5 = Number(item[5]);
                    const percentage5 = getPercentage(value5, maxValues[5]);
                    const bgColor5 = getBgColor(percentage5);

                    const value7 = Number(item[7]);
                    const percentage7 = getPercentage(value7, maxValues[7]);
                    const bgColor7 = getBgColor(percentage7);

                    const value9 = Number(item[9]);
                    const percentage9 = getPercentage(value9, maxValues[9]);
                    const bgColor9 = getBgColor(percentage9);

                    const value10 = Number(item[10]);
                    const percentage10 = getPercentage(value10, maxValues[10]);
                    const bgColor10 = getBgColor(percentage10);

                    const value11 = Number(item[11]);
                    const percentage11 = getPercentage(value11, maxValues[11]);
                    const bgColor11 = getBgColor(percentage11);

                    const value12 = Number(item[12]);
                    const percentage12 = getPercentage(value12, maxValues[12]);
                    const bgColor12 = getBgColor(percentage12);
                    return(
                    <tr key={`body2-${itemInd}`}>
                        <td style={{ backgroundColor: "#BDBBF1" }}>
                            {item[0]}
                        </td>
                        <td className="text-center" style={{ backgroundColor: bgColor1 }}>{item[1]}</td>
                        <td className="text-center bg-light">
                            {item[2]}%
                        </td>
                        <td className="text-center" style={{ backgroundColor: bgColor3 }}>{item[3]}</td>
                        <td className="text-center bg-light">
                            {item[4]}%
                        </td>
                        <td
                            className="text-center"  style={{ backgroundColor: bgColor5 }}
                        >
                            {item[5]}
                        </td>
                        <td className="text-center bg-light">
                            {item[6]}%
                        </td>
                        <td className="text-center"  style={{ backgroundColor: bgColor7 }}>{item[7]}</td>
                        <td className="text-center bg-light">
                            {item[8]}%
                        </td>
                        <td
                            className="text-center"  style={{ backgroundColor: bgColor9 }}
                        >
                            {currencyFormatWithAlert(item[9])}
                        </td>
                        <td
                            className="text-center"  style={{ backgroundColor: bgColor10 }}
                        >
                            {currencyFormatWithAlert(item[10])}
                        </td>
                        <td className="text-center"  style={{ backgroundColor: bgColor11 }}>
                            {currencyFormatWithAlert(item[11])}
                        </td>
                        <td className="text-center"  style={{ backgroundColor: bgColor12 }}>
                            {currencyFormatWithAlert(item[12])}
                        </td>
                    </tr>
                    )
                }
                return null;     
            })}
          </tbody>
        </table>
        </div>
      </CardBody>
    </Card>
  )
}

export default DiscountPerformance
