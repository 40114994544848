import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
//import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import ServerPerformanceTable from "./ServerPerformance";
import CustomDatePicker from "../../Components/Common/CustomDatePicker";
import Server from "./Server";
import ServerTopBottomPerformanceTable from "./ServerTopBottomPerformanceTable";
import ServerPerformanceMixChart from "./ServerPerformanceMixChart";
/*var chartLineAreaMultiColors = getChartColorsArray('["--vz-primary", "--vz-success", "--vz-danger"]');
const revenueSeries = [
    {
        name: "Revenue",
        type: "column",
        data: [1505, 4200, 3500, 3450, 1700],
    },
    {
        name: "Server Average Check",
        type: "line",
        data: [240, 440, 430, 500, 300],
    },
    {
        name: "Team Average",
        type: "line",
        data: [448, 448, 448, 448, 448],
    }
];
const revenueOptions = {
    chart: {
        stacked: !1,
        toolbar: {
            show: !1,
        },
    },
    stroke: {
        width: [0,3, 3],
        curve: 'smooth',
        dashArray: [0, 0, 5]
    },
    plotOptions: {
        bar: {
            columnWidth: "40%",
        },
    },
    labels: [
        "Server 1",
        "Server 2",
        "Server 3",
        "Server 4",
        "Server 5"
    ],
    markers: {
        style: 'inverted',
        size: 4
    },
    xaxis: {
        type: "category",
    },
    yaxis: [
        {
            title: {
                text: "Server Revenue"
            },
        },
        {
            opposite: true,
            title: {
                text: "Average Check"
            },
        }
    ],
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function (y) {
                if (typeof y !== "undefined") {
                    return y.toFixed(0) + " points";
                }
                return y;
            },
        },
    },
    colors: chartLineAreaMultiColors,
};

const turnTimeSeries = [
    {
        name: "Turn Time",
        type: "column",
        data: [70, 80, 87, 87, 78],
    },
    {
        name: "Server Average Check",
        type: "line",
        data: [240, 440, 430, 500, 300],
    },
    {
        name: "Team Average",
        type: "line",
        data: [448, 448, 448, 448, 448],
    }
];
const turnTimeOptions = {
    chart: {
        stacked: !1,
        toolbar: {
            show: !1,
        },
    },
    stroke: {
        width: [0,3, 3],
        curve: 'smooth',
        dashArray: [0, 0, 5]
    },
    plotOptions: {
        bar: {
            columnWidth: "40%",
        },
    },
    labels: [
        "Server 1",
        "Server 2",
        "Server 3",
        "Server 4",
        "Server 5"
    ],
    markers: {
        style: 'inverted',
        size: 4
    },
    xaxis: {
        type: "category",
    },
    yaxis: [
        {
            title: {
                text: "Server Turn Time"
            },
        },
        {
            opposite: true,
            title: {
                text: "Average Check"
            },
        }
    ],
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function (y) {
                if (typeof y !== "undefined") {
                    return y.toFixed(0) + " points";
                }
                return y;
            },
        },
    },
    colors: chartLineAreaMultiColors,
};
*/

const ServerPerformance = () => {
  document.title = "Server Performance | " + process.env.REACT_APP_NAME;

  const [leftCustomDate, setLeftCustomDate] = useState([]);
  const [leftFilter, setLeftFilter] = useState({
    label: "Yesterday",
    value: "Yesterday",
  });
  const [filtermealperiod, setFiltermealperiod] = useState([]);
  const [mealPeriod, setMealPeriod] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [currency, setCurrency] = useState('$');
  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedPageST, setSelectedPageST] = useState(0);
  const [filterEmployees, setFilterEmployees] = useState([]);
  const { filterData, filterAjax, currentOutlet} = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
    currentOutlet: state.UserOutletGroup.currentOutlet
  }));
  useEffect(() => {
    localStorage.setItem('Server_Perf_SortType', '');
    localStorage.setItem('Singleitem_SortType', '');
    if (filterData.mealperiod) {
      setFiltermealperiod(filterData.mealperiod);
    }
    if (filterData.serverList) {
      setFilterEmployees(filterData.serverList);
    }
  }, [filterAjax, filterData]);
  useEffect(()=>{
    if(currentOutlet?.currency!=""){
      setCurrency(currentOutlet?.currency)
    }
  },[currentOutlet])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Server Performance" pageTitle="Dashboard" />
          <Row className="mb-4">
            {/* justify-content-sm-end */}
            <Col sm={12} lg={4}>
              <CustomDatePicker
                activeDate={leftFilter}
                setActiveDate={setLeftFilter}
                customDate={leftCustomDate}
                setCustomDate={setLeftCustomDate}
                posEnd={false}
              />
            </Col>
            {/* <Col sm={12} lg={4}> */}
              {/* <select
                    className="form-select"
                    value={employees}
                    id="employees"
                    onChange={(event) => setEmployees(event.target.value)}
                    >
                    <option value="0">Select Employee</option>
                    {!filterAjax &&
                        filterEmployees.length > 0 &&
                        filterEmployees.map((item) => {
                        return (
                            <option key={item.value} value={item.value}>
                            {item.label}
                            </option>
                        );
                    })}
                    </select> */}
              {/* <Select
                isClearable
                options={filterEmployees}
                onChange={(option) => {
                  setEmployees(option?option.value:0);
                }}
              /> */}
            {/* </Col> */}
            <Col sm={12} lg={4}>
              <select
                className="form-select"
                value={mealPeriod}
                id="mealPeriod"
                onChange={(event) => setMealPeriod(event.target.value)}
              >
                <option value="0">All Meal Periods</option>
                {!filterAjax &&
                  filtermealperiod.length > 0 &&
                  filtermealperiod.map((item) => {
                    return (
                      <option key={item.sp_id} value={item.sp_id}>
                        {item.title +
                          " (" +
                          item.start_time +
                          " To " +
                          item.end_time +
                          ")"}
                      </option>
                    );
                  })}
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
                <ServerTopBottomPerformanceTable
                mealPeriod={mealPeriod}
                server_id={employees}
                activeDate={leftFilter}
                customDate={leftCustomDate}
                selectedPageST={selectedPageST}
                setSelectedPageST={setSelectedPageST}
                />
            </Col>
          </Row>
          <Row>
            <Col>
              <ServerPerformanceTable
                mealPeriod={mealPeriod}
                server_id={employees}
                activeDate={leftFilter}
                customDate={leftCustomDate}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </Col>
          </Row>
          {/*
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Server Revenue</h4>
                        </CardHeader>

                        <CardBody>
                            <ReactApexChart
                                options={revenueOptions}
                                series={revenueSeries}
                                type="line"
                                height={350}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Server Turn Time</h4>
                        </CardHeader>

                        <CardBody>
                            <ReactApexChart
                                options={turnTimeOptions}
                                series={turnTimeSeries}
                                type="line"
                                height={350}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>*/}
          <Row>
            <Col lg={12}>
              <Server                
                mealPeriod={mealPeriod}
                server_id={employees}
                activeDate={leftFilter}
                customDate={leftCustomDate}
                selectedPageST={selectedPageST}
                setSelectedPageST={setSelectedPageST}
                currency={currency}
              />

              <ServerPerformanceMixChart
                    mealPeriod={mealPeriod}
                    server_id={employees}
                    activeDate={leftFilter}
                    customDate={leftCustomDate}
                />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServerPerformance;
