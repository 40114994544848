import { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import {
    Row,
    Col,
    Table,
    Input
} from "reactstrap"
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import Loader from "../../Components/Common/Loader";

const LastYearTab = (props) => {
    const {filterData, filterAjax, handleToastMessage}=props;
    const [serverPerformanceAjax_lastyear, setServerPerformanceAjax_lastyear] = useState(true);
    const [dateWiseData_lastyear, setDateWiseData_lastyear] = useState([]);
    const [aggregator_lastyear, setAggregator_lastyear] = useState("Month");
    const [year_lastyear, setYear_lastyear] = useState(new Date().getFullYear());
    const [mealPeriod_lastyear, setMealPeriod_lastyear] = useState(0);
    const [filtermealperiod_lastyear, setFiltermealperiod_lastyear] = useState([]);
    const [ChangedCover_lastyear, setChangedCover_lastyear] = useState({});
    const [ChangedRevenue_lastyear, setChangedRevenue_lastyear] = useState({});
    const [originalData_lastyear, setOriginalData_lastyear] = useState(dateWiseData_lastyear);
    const [hideOldDiv_lastyear, setHideOldDiv_lastyear] = useState(false);
    const [lockedCheckboxState, setLockedCheckboxState] = useState({});
    
    useEffect(() => {
        if (filterData.mealperiod) {
            setFiltermealperiod_lastyear(filterData.mealperiod);
        }
    }, [filterAjax, filterData]);

    useEffect(()=>{
        getInfo_lastyear(mealPeriod_lastyear, aggregator_lastyear, year_lastyear);
    },[mealPeriod_lastyear,aggregator_lastyear,year_lastyear])

    const getInfo_lastyear = (mealPeriod_lastyear, aggregator_lastyear, year_lastyear) => {
        setServerPerformanceAjax_lastyear(true);
        setHideOldDiv_lastyear(false);
        setLockedCheckboxState({});
        postDataAPI({
            serviceName: POST_KPI_DASHBOARD.serviceName,
            op: POST_KPI_DASHBOARD.lastyear_list_op,
            mealPeriod: mealPeriod_lastyear,
            budgetSelectRange: aggregator_lastyear,
            budgetYear: year_lastyear
        }).then(response => {
            if (response.status === 'ok') {
                setDateWiseData_lastyear(response.data.data);
                setOriginalData_lastyear(response.data.data);
                setServerPerformanceAjax_lastyear(false);

                const initialCheckboxState = {};
                response.data.data.forEach((data, index) => {
                    initialCheckboxState[index] = data.is_locked === "1"; // Set initial checked value
                });
                setLockedCheckboxState(initialCheckboxState);
            } else {
                // console.log(response);
            }
        });
    }

    const handleaggregator_lastyear = (event) => {
        setAggregator_lastyear(event.target.value);
    }

    const handleYear_lastyear = (event) => {
        setYear_lastyear(event.target.value);
    }

    const handleMealPeriod_lastyear = (event) => {
        setMealPeriod_lastyear(event.target.value);
    }

    const handleInputCover_lastyear = (event, date) => {
        setHideOldDiv_lastyear(true);
        const { name, value } = event.target;
        setDateWiseData_lastyear(dateWiseData_lastyear.map((data) => {
            if (data.from_date_display == date) {
                data[name] = value;
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedCover_lastyear({ ...ChangedCover_lastyear, [date]: true });
        // if (value != '0' && value != '') {
        //     setCheckboxChecked_lastyear(date, true);
        // } else {
        //     setCheckboxChecked_lastyear(date, false);
        // }
        setCheckboxChecked_lastyear(date, true);

        // Calculate Average Check if Revenue is available
        const lastyear_revenue = dateWiseData_lastyear.find(data => data.from_date_display == date)?.lastyear_revenue || 0;
        if (lastyear_revenue && value != '' && value != '0') {
            const lastyear_avg_check = parseFloat(lastyear_revenue) / parseFloat(value);
            // console.log('Average Check:', lastyear_avg_check);
            setDateWiseData_lastyear(dateWiseData_lastyear.map((data) => {
                if (data.from_date_display == date) {
                    data['lastyear_avg_check'] = lastyear_avg_check.toFixed(2);
                }
                return data;
            }));
            // Set the Average Check in your state or perform further actions as needed
        }
    }

    const handleInputRevenue_lastyear = (event, date) => {
        setHideOldDiv_lastyear(true);
        const { name, value } = event.target;
        setDateWiseData_lastyear(dateWiseData_lastyear.map((data) => {
            if (data.from_date_display == date) {
                data[name] = value;
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedRevenue_lastyear({ ...ChangedRevenue_lastyear, [date]: true });
        // if (value != '0' && value != '') {
        //     setCheckboxChecked_lastyear(date, true);
        // } else {
        //     setCheckboxChecked_lastyear(date, false);
        // }
        setCheckboxChecked_lastyear(date, true);

        // Calculate Average Check if Covers is available
        const lastyear_covers = dateWiseData_lastyear.find(data => data.from_date_display == date)?.lastyear_covers || 0;
        if (lastyear_covers && value != '' && value != '0') {
            const lastyear_avg_check = parseFloat(value) / parseFloat(lastyear_covers);
            // console.log('Average Check:', lastyear_avg_check);
            setDateWiseData_lastyear(dateWiseData_lastyear.map((data) => {
                if (data.from_date_display == date) {
                    data['lastyear_avg_check'] = lastyear_avg_check.toFixed(2);
                }
                return data;
            }));
            // Set the Average Check in your state or perform further actions as needed
        }
    }

    const handleInputlastyear_avg_check = (event, date) => {
        const { name, value } = event.target;
        setDateWiseData_lastyear(dateWiseData_lastyear.map((data) => {
            if (data.from_date_display == date) {
                data['lastyear_avg_check'] = value;
            }
            return data;
        }));   
        const lastyear_covers = dateWiseData_lastyear.find(data => data.from_date_display == date)?.lastyear_covers || 0;
        const lastyear_revenue = parseFloat(lastyear_covers) * parseFloat(value); 
        setDateWiseData_lastyear(dateWiseData_lastyear.map((data) => {
            if (data.from_date_display == date) {
                data['lastyear_revenue'] = lastyear_revenue.toFixed(2);
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedCover_lastyear({ ...ChangedCover_lastyear, [date]: true });
        // if (value != '0' && value != '') {
        //     setCheckboxChecked_lastyear(date, true);
        // } else {
        //     setCheckboxChecked_lastyear(date, false);
        // }
        setCheckboxChecked_lastyear(date, true);
    }
        
    const handleSubmit_lastyear = () => {
        setServerPerformanceAjax_lastyear(true);
        const checkedRowsData_lastyear = [];
        const checkedRowsData_lastyear_locked = [];
        for (let i = 0; i < dateWiseData_lastyear.length; i++) {

            let valId = 'lastyear_checkbox_' + i;
            var checkbox = document.getElementById(valId);
            let valId1 = 'lastyear_checkbox_locked_' + i;
            var checkbox1 = document.getElementById(valId1);
            if (checkbox.checked) {
                checkedRowsData_lastyear.push({
                    "br_id": dateWiseData_lastyear[i]['br_id'],
                    "covers": dateWiseData_lastyear[i]['lastyear_covers'],
                    "revenue": dateWiseData_lastyear[i]['lastyear_revenue'],
                    "edited": (checkbox1.checked) ? "lac-locked" : dateWiseData_lastyear[i]['edited']
                });
            } else {
                // console.log('Checkbox is not checked');
            }

        }
        // console.log("Checked Rows Data:", checkedRowsData_lastyear);
        postDataAPI({
            serviceName: POST_KPI_DASHBOARD.serviceName,
            op: POST_KPI_DASHBOARD.save_lastyear_data_op,
            mealPeriod_lastyear: mealPeriod_lastyear,
            budgetSelectRange: aggregator_lastyear,
            budgetYear: year_lastyear,
            lastyear_data: checkedRowsData_lastyear,
            lastyear_data_locked: checkedRowsData_lastyear_locked
        }).then(response => {
            if (response.status === "ok") {
                handleToastMessage('success', response.message);
                setServerPerformanceAjax_lastyear(false);
                setTimeout(() => {
                    // window.location.reload();
                }, 2000);
                getInfo_lastyear(mealPeriod_lastyear, aggregator_lastyear, year_lastyear);
            } else {
                handleToastMessage('error', response.message);
            }
        });
    };

    const setCheckboxChecked_lastyear = (date, checked) => {
        setDateWiseData_lastyear(dateWiseData_lastyear.map((data) => {
            if (data.from_date_display === date) {
                data.isChecked_lastyear = checked;
            }
            return data;
        }));
    };

    // console.log(dateWiseData);

    const handleLockedChange = (index, id, date) => {
        var checkbox1 = document.getElementById(id);
        if (checkbox1.checked) {
            setCheckboxChecked_lastyear(date,true);
        }
        setLockedCheckboxState((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle checked state
        }));
    }

    return (
        <>
        <Row>
            <Col lg={3} >
                <select value={aggregator_lastyear} onChange={handleaggregator_lastyear} className="form-select mb-3">
                    <option value="Day">Day</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                    <option value="Year">Year</option>
                </select>
            </Col>
            <Col lg={3}>
                <select value={year_lastyear} onChange={handleYear_lastyear} className="form-select mb-3">
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </Col>
            <Col lg={3}>
                <select
                    className="form-select"
                    value={mealPeriod_lastyear}
                    id="mealPeriod_lastyear"
                    onChange={handleMealPeriod_lastyear}
                >
                    <option value="0">All Meal Periods</option>
                    {!filterAjax &&
                        filtermealperiod_lastyear.length > 0 &&
                        filtermealperiod_lastyear.map((item) => {
                            return (
                                <option key={item.sp_id} value={item.sp_id}>
                                    {item.title +
                                        " (" +
                                        item.start_time +
                                        " To " +
                                        item.end_time +
                                        ")"}
                                </option>
                            );
                        })}
                </select>
            </Col>
            <Col lg={3}>
                <button className="btn btn-primary"
                    style={{ float: 'right' }}
                    onClick={handleSubmit_lastyear}>
                    Save
                </button>
            </Col>
        </Row>
        {serverPerformanceAjax_lastyear && <Loader />}
        <div
            className={
                serverPerformanceAjax_lastyear
                    ? "opacity-0"
                    : ""
            }
        >
            <div className="table-responsive">
                <Table className="table-bordered table-nowrap text-center align-middle mb-0 table-sm">
                    <thead style={{ position: 'sticky', top: '-2px', background: '#fff', zIndex: '10' }}>
                        <tr>
                            <th className="text-center" scope="col" style={{ width: '20%' }}>{aggregator_lastyear == 'Month' ? 'Months' : 'Date'}</th>
                            <th className="text-center" scope="col" style={{ width: '20%' }}>Covers</th>
                            <th className="text-center" scope="col" style={{ width: '20%' }}>Revenue</th>
                            <th className="text-center" scope="col" style={{ width: '20%' }}>Avg Check</th>
                            <th className="text-center" scope="col" style={{ width: '10%' }}>Apply</th>
                            <th className="text-center" scope="col" style={{ width: '10%' }}>Locked</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: '400px', background: '#fff', display: 'table-caption' }}>
                        {dateWiseData_lastyear.map((data, index) => {
                            const checkboxId_lastyear = `lastyear_checkbox_${index}`;
                            const checkboxId_lastyear_locked = `lastyear_checkbox_locked_${index}`;
                            return (
                                <tr key={data.from_date_display} style={{ width: '100%', display: 'inline-table' }}>
                                    <td style={{ width: '20%' }}>{data.from_date_display}</td>
                                    <td style={{ width: '20%' }}>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div style={{ width: '50%' }}>
                                                <Input
                                                    name="lastyear_covers"
                                                    className="form-control form-control-sm"
                                                    type="number"
                                                    value={data.lastyear_covers}
                                                    style={{ maxWidth: "150px", margin: "auto", fontSize: '13px' }}
                                                    onChange={(event) => handleInputCover_lastyear(event, data.from_date_display)}
                                                // onBlur={(event) => handleInputCover_lastyear(event, data.from_date_display)}
                                                    readOnly={data.is_disable_row === "1" || (lockedCheckboxState[index]) || false}
                                                />
                                            </div>
                                            {hideOldDiv_lastyear && (
                                                <div style={{ width: '50%' }}>
                                                    {ChangedCover_lastyear[data.from_date_display] && (
                                                        <div style={{ marginLeft: '20px', marginTop: '3px' }}>
                                                            {originalData_lastyear.find(d => d.from_date_display === data.from_date_display) && originalData_lastyear.find(d => d.from_date_display === data.from_date_display).oCovers !== '' ? (
                                                                <div style={{ background: '#f06548', padding: '3px 10px', borderRadius: '4px', color: '#fff', width: 'max-content' }}>
                                                                    Old : {originalData_lastyear.find(d => d.from_date_display === data.from_date_display).oCovers}
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div style={{ width: '50%' }}>
                                                <Input
                                                    name="lastyear_revenue"
                                                    className="form-control form-control-sm"
                                                    type="number"
                                                    value={data.lastyear_revenue}
                                                    style={{ maxWidth: "150px", margin: 'auto', fontSize: '13px' }}
                                                    onChange={(event) => handleInputRevenue_lastyear(event, data.from_date_display)}
                                                // onBlur={(event) => handleInputRevenue_lastyear(event, data.from_date_display)}
                                                    readOnly={data.is_disable_row === "1" || (lockedCheckboxState[index]) || false}
                                                />
                                            </div>
                                            {hideOldDiv_lastyear && (
                                                <div style={{ width: '50%' }}>
                                                    {ChangedRevenue_lastyear[data.from_date_display] && (
                                                        <div style={{ marginLeft: '20px', marginTop: '3px' }}>
                                                            {originalData_lastyear.find(d => d.from_date_display === data.from_date_display) && originalData_lastyear.find(d => d.from_date_display === data.from_date_display).oRevenue !== '' ? (
                                                                <div style={{ background: '#f06548', padding: '3px 10px', borderRadius: '4px', color: '#fff', width: 'max-content' }}>
                                                                    Old : {originalData_lastyear.find(d => d.from_date_display === data.from_date_display).oRevenue}
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        <Input
                                            name="lastyear_avg_check"
                                            className="form-control form-control-sm"
                                            type="number"
                                            value={data.lastyear_avg_check}
                                            style={{ maxWidth: "150px", margin: "auto", fontSize: '13px' }}
                                            onChange={(event) => handleInputlastyear_avg_check(event, data.from_date_display)}
                                        // onBlur={(event) => handleInputlastyear_avg_check(event, data.from_date_display)}
                                            readOnly={data.is_disable_row === "1" || (lockedCheckboxState[index]) || false}
                                        />
                                    </td>
                                    <td style={{ width: '10%' }}>
                                        {/* <i className="ri-code-box-line"></i> */}
                                        <div className="flex-shrink-0">
                                            <div className="form-check">
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={checkboxId_lastyear}
                                                    checked={data.isChecked_lastyear}
                                                    style={{ margin: 'auto', display: 'block', pointerEvents: 'none' }}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '10%' }}>
                                        {/* <i className="ri-code-box-line"></i> */}
                                        <div className="flex-shrink-0">
                                            <div className="form-check">
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={checkboxId_lastyear_locked}
                                                    checked={lockedCheckboxState[index] || false}
                                                    style={{ margin: 'auto', display: 'block' }}
                                                    onChange={()=>handleLockedChange(index, checkboxId_lastyear_locked, data.from_date_display)}
                                                    disabled={(data.period_type !== "day") ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    </>)
}
export default LastYearTab