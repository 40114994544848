import {
  FETCH_DASHBOARD_DATA,
  SET_DASHBOARD_DATA,
  ADD_REPORT_TO_DASHBOARD,
} from "./actionType";

export const fetchMyDashboardData = () => ({
    type: FETCH_DASHBOARD_DATA
});

export const setMyDashboardData = (data) => ({
    type: SET_DASHBOARD_DATA,
    payload: data
});

export const addReportToMyDashboard = (report) => ({
    type: ADD_REPORT_TO_DASHBOARD,
    payload: report
});
