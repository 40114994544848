import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const TrendAvgCheckCoverChart = ({ forecastData, dataColors }) => {
    var chartLineColumnColors = getChartColorsArray(dataColors);
    var option_enabledOnSeries=[2,3];
    const series = [
        {
            name: "Covers Act",
            type: "column",
            data: forecastData.covers_actual,
        },
        {
            name: "Covers LY",
            type: "column",
            data: forecastData.covers_ly,
        },
        {
            name: "Avg Check Act",
            type: "line",
            data: forecastData.avg_check_actual,
        },
        {
            name: "Avg Check LY",
            type: "line",
            data: forecastData.avg_check_ly,
        },
    ];

    var options = {
        chart: {
            stacked: !1,
            toolbar: {
                show: !1,
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: option_enabledOnSeries,
        },
        stroke: {
            width: [0,0,2,2],
        },
        title: {
            align: "left",
            offsetX: 110,
            style: {
                fontWeight: 600,
            },
        },
        xaxis: {
            categories: forecastData.months,
        },
        yaxis: [
            {
                seriesName: "Covers Act",
                title: {
                    text: "Covers",
                    style: {
                    fontWeight: 600,
                    },
                },
                min: 0, // Starting value for Avg Checks
                max: forecastData.covers_max,
                tickAmount: 8,
                labels: {
                    formatter: function (value) {
                        return value.toFixed(0);
                    },
                },
            },
            {
                title: {
                    text: "Covers LY", // Title for bar-related data
                },
                show: false,
                min: 0, // Starting value for Avg Checks
                max: forecastData.covers_max,
                tickAmount: 8,
                labels: {
                    formatter: function (value) {
                        return value.toFixed(0);
                    },
                },
            },
            {
                opposite: true, // Align line data on the right Y-axis
                title: {
                    text: "Average Check", // Title for line-related data
                },
                min: 0, // Starting value for Avg Checks
                max: forecastData.avg_check_max,
                tickAmount: 8,
                labels: {
                    formatter: function (value) {
                        return value.toFixed(0);
                    },
                },
            },
        ],
        tooltip: {
            enabled: true,
            shared: true,  // Ensure the tooltip is shared for the different series
            followCursor: true,  // Tooltip follows the cursor
            intersect: false,  // Ensure the tooltip appears even when the mouse isn't directly over a bar
            style: {
                fontSize: '14px',
            },
            y: {
                formatter: function (value, { seriesIndex }) {
                    // Check the seriesIndex to format the value accordingly
                    if (seriesIndex === 0 || seriesIndex === 1) {
                        // No decimal for Covers Act and Covers LY
                        return value.toFixed(0); 
                    } else {
                        // Decimal for Avg Check series
                        return value.toFixed(2);
                    }
                },
            },
        },
        colors: chartLineColumnColors,
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export {
    TrendAvgCheckCoverChart
};
