import {
  FETCH_DASHBOARD_DATA,
  SET_DASHBOARD_DATA,
  ADD_REPORT_TO_DASHBOARD
} from "./actionType"

const initialState = {
  dashboardData: []
};

const MyDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
      case SET_DASHBOARD_DATA:
          return { ...state, dashboardData: action.payload };

      case ADD_REPORT_TO_DASHBOARD:
          return { ...state, dashboardData: [...state.dashboardData, action.payload] };

      default:
          return state;
  }
};

export default MyDashboardReducer;
