/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef, useState, useMemo} from "react";
import { 
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label
} from "reactstrap";
import {
  currencyFormatWithAlert,
  currencyFormatWithoutDecimal,
  numberFormat
} from "../../helpers/api_helper";
import Loader from "../../Components/Common/Loader";
import { getDateByName } from "../../helpers/api_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import ServerModal from "./ServerModal";
const itemsPerPage = 50;
let sorttypefinalvalue;
const ServerPerformance = ({
  mealPeriod,
  activeDate,
  customDate,
  server_id,
  recalculate,
  showGraph,
  currency
}) => {
  const [selectedPage, setSelectedPage] = useState(0);
  const [serverPerformances, setServerPerformances] = useState([]);
  const [serverPerformanceAjax, setServerPerformanceAjax] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [showDetailsModal, setShowDetailsModal] = useState(false);    
  useEffect(() => {
    console.log("showDetailsModal");
  },[showDetailsModal]);
  //   const pageCount = Math.ceil(serverPerformances.length / itemsPerPage);

  useEffect(() => {  
    // console.log('activeDate:', activeDate);
    // console.log('customDate:', customDate);   
    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      setServerPerformanceAjax(true);
      handleSort(sortColumn, 'initial');      
    }
  }, [selectedPage, recalculate, activeDate,customDate,mealPeriod]);

  /******** Sorting **** Starts*****/  
  const [sortColumn, SetSortColumn] = useState('');
  const [selectedPageST, setSelectedPageST] = useState(0);
  const columnDef = [
    {
      name: 'Server Name',
      key: 'name',
      sort: true,
      className: "text-start",
    },
    {
      name: 'Checks',
      key: 'check_count',
      sort: true,
    },
    {
      name: 'Covers',
      key: 'covers',
      sort: true,
    },
    {
      name: 'Revenue',
      key: 'revenue',
      sort: true,
    },
    {
      name: 'Avg. Check',
      key: 'avgCheck',
    },
    {
      name: 'vs Team Average',
      key: 'teamAvg',
    },
    {
      name: 'Cover %',
      key:'cover_percentage'
    },
    {
      name: 'Check%',
      key: 'check_percentage',
    },
    {
      name: 'Revenue %',
      key: 'revenue_percentage',
    },
    {
      name: 'ACI',
      key: 'aci',
    },
    {
      name: 'Lost Opportunity',
      key: 'lost_opportunity',
    },
  ]
  const [showColumns,setShowColumns]=useState(columnDef.map((column)=>column.key));
  
  const [isOpen, setIsOpen] = useState(false);
  const columnDropdownRef=createRef();
  const toggleDropdown = (e) => {
    if (columnDropdownRef.current.contains(e.target)) return;
    
    setIsOpen(!isOpen);
  };
  const handleColumnHideShow =(event,columnName) => {
    event.stopPropagation();
    if(showColumns.includes(columnName)){
      setShowColumns(showColumns.filter((column)=>column!=columnName))
    }else{
      setShowColumns([...showColumns,columnName]);
    }
    console.log(event.target.value)
  }
  const handleSort = (value, callfrom) => {
    if(callfrom === 'initial'){
      sorttypefinalvalue = localStorage.getItem('Server_Perf_SortType');
    }else{
      if(sortColumn!=value){
        localStorage.setItem('Server_Perf_SortType', 'DESC');
        sorttypefinalvalue = 'DESC';
      }else{
        if(localStorage.getItem('Server_Perf_SortType') === ''){
            localStorage.setItem('Server_Perf_SortType', 'ASC');
            sorttypefinalvalue = 'ASC';                
        }else{
          if(localStorage.getItem('Server_Perf_SortType') === 'ASC'){   
            localStorage.setItem('Server_Perf_SortType', 'DESC');
            sorttypefinalvalue = 'DESC';
          }else{
            localStorage.setItem('Server_Perf_SortType', 'ASC');
            sorttypefinalvalue = 'ASC';
          }
        }      
      }
          
    }

    SetSortColumn(value);

        
    
    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;
        
    let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);
            
    postDataAPI({
      dateFilter: dateval,
      serviceName: POST_KPI_DASHBOARD.serviceName,
      op: POST_KPI_DASHBOARD.server_performance_op,
      server_id: server_id,
      mealPeriod: mealPeriod,
      page: selectedPage,
      recalculate: recalculate,
      sort_column: value,
      sort_type: sorttypefinalvalue,
    }).then((response) => {
      setServerPerformanceAjax(false);
      if (response.status === "ok") {
        // eslint-disable-next-line no-debugger
        //  debugger;
        setServerPerformances(response.data.data);
        setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
      } else {
        console.log(response);
        setPageCount(0);
      }
    });
    
  };
  /************END Sorting**************/

  /********** Handle Export ********/
  const handleExport = (e) => {

    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;
        
    let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);
            
    postDataAPI({
      dateFilter: dateval,
      serviceName: POST_KPI_DASHBOARD.serviceName,
      op: POST_KPI_DASHBOARD.server_performance_op,
      server_id: server_id,
      mealPeriod: mealPeriod,
      page: selectedPage,
      recalculate: recalculate,
      sort_column: '',
      sort_type: '',
      export: 1,
      exportColumns:showColumns
    }).then((response) => {
      setServerPerformanceAjax(false);
      if (response.status === "ok") {
        // eslint-disable-next-line no-debugger
        //  debugger;
        window.open(response.data.data.filename);
      } else {
        console.log(response);
      }
    });

  };

  const filteredColumns = useMemo(() => columnDef.filter(column => showColumns.includes(column.key)), [showColumns, columnDef]);
//console.log(pageCount)
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Server performance</h4>
          <div className="flex-shrink-0">
            <div className="d-flex">
              <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-3" style={{borderRadius:'0.25rem'}}>
                <ButtonGroup>
                <Button color="info">Columns</Button>
                <DropdownToggle tag="button" className="btn btn-info" split></DropdownToggle>
                </ButtonGroup>
                <DropdownMenu className="dropdown-menu-end" end={true}>
                  <div ref={columnDropdownRef}>
                    {columnDef.map((column) => (
                          <DropdownItem onClick={(event)=>{handleColumnHideShow(event,column.key)}}  key={column.key}>
                            <div className="form-check mb-1">
                                <Input className="form-check-input" type="checkbox" id={`column${column.key}`} checked={showColumns.includes(column.key)} value={column.key}/>
                                <Label className="form-check-label" for="formCheck6">{column.name}</Label>
                            </div>
                          </DropdownItem>
                      ))}
                    </div>
                </DropdownMenu>
              </Dropdown>
              {showGraph && 
              <Button
                className="ms-3"
                color="primary"
                onClick={() => setShowDetailsModal(true)}
              >
                Details
              </Button>
              }
              {/* <ExportExcel fileName="ServerPerformance" excelData={serverPerformances} /> */}
              <Button
                className="ms-3"
                color="primary"
                onClick={handleExport}
              >
                Export
              </Button>
            </div>
          </div>    
        </CardHeader>

        <CardBody>
          {serverPerformanceAjax && <Loader />}
          <div id="ServerPerformance"
            className={
              serverPerformanceAjax
                ? "opacity-0 table-responsive table-card"
                : "table-responsive table-card"
            }
            style={{maxHeight: '500px', overflow: 'auto'}}
          >
            <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
              <thead className="text-muted table-light">
                <tr style={{position: 'sticky',top: '-2px', zIndex: 100}}>
                  {filteredColumns.map((column) => (
                      showColumns.includes(column.key) && <th className={column.className} key={column.key} scope="col">{column.name} {column.sort && <i className="las la-arrow-down" style={{ cursor: 'pointer', transform: (sortColumn==column.key && sorttypefinalvalue=='DESC' ? 'rotate(180deg)' : 'rotate(0deg)') }} onClick={() => handleSort(column.key, 'sortcall')}></i>}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(serverPerformances || []).map((item, key) => (
                  <tr key={key}>                    
                    {showColumns.includes('name') && <td className="text-start">{item.name}</td> }
                    {showColumns.includes('check_count') && <td>{ numberFormat(item.check_count)}</td> }
                    {showColumns.includes('covers') && <td>{ numberFormat(item.covers)}</td> }
                    {showColumns.includes('revenue') && <td> {currencyFormatWithoutDecimal(item.revenue,currency)}</td>}
                    {showColumns.includes('avgCheck') && <td>{currencyFormatWithAlert(item.avgCheck,currency)}</td>}
                    {showColumns.includes('teamAvg') && <td>{currencyFormatWithAlert(item.teamAvg,currency)}</td>}
                    {showColumns.includes('cover_percentage') && <td>{numberFormat(item.cover_percentage)}</td>}
                    {showColumns.includes('check_percentage') &&  <td>{numberFormat(item.check_percentage)}</td>}
                    {showColumns.includes('revenue_percentage') &&  <td>{numberFormat(item.revenue_percentage)}</td> }
                    {showColumns.includes('aci') && <td>{numberFormat(item.aci)}</td>}
                    {showColumns.includes('lost_opportunity') && <td> {currencyFormatWithAlert(item.lost_opportunity,currency)}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 float-end">
            <nav>
              <CustomPagination
                pageCount={pageCount}
                setSelectedPage={setSelectedPage}
              />
            </nav>
          </div>
        </CardBody>
      </Card>
      {(showGraph && showDetailsModal) && <ServerModal
              mealPeriod={mealPeriod}
              server_id={''}
              activeDate={activeDate}
              customDate={customDate}   
              selectedPage={selectedPage}  
              showDetailsModal={showDetailsModal}
              setShowDetailsModal={setShowDetailsModal}        
              selectedPageST={selectedPageST}
              setSelectedPageST={setSelectedPageST}
              currency={currency}
            />
      }
    </React.Fragment>
  );
};

export default ServerPerformance;
