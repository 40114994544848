/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

import {
    Card,
    CardHeader,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    Table,
    Col,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import Loader from "../../Components/Common/Loader"
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import {getDateByName, convertDateFormate, currencyFormatWithAlert, courseColor} from "../../helpers/api_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import ValueAlert from "../../Components/Common/ValueAlert"
import CourseBadge from "../../Components/Common/CourseBadge"
import PerformanceBadge from "../../Components/Common/PerformanceBadge";
import classnames from "classnames";
var chartStackedBarColors = getChartColorsArray('["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info"]');
const itemsPerPage = 50;

const ServerPerformanceMixChart = ({activeDate, customDate, mealPeriod, server_id, selectedPageST}) => {

    const [serverData_mix, setServerData_mix] = useState([]);
    const [serverModalAjax, setServerModalAjax] = useState(true);
    const [mixgraph_display, setmixgraph_display] = useState(false);
    
    const serverOptionData_Mix = [];
    const serverSeries_mix = serverData_mix.serverSeries;

    for (const key in serverData_mix.serverOptions) {
        serverOptionData_Mix.push(serverData_mix.serverOptions[key]['name']);
    }
    
    useEffect(() => {
        if(activeDate.value!=='Custom' || (activeDate.value==='Custom' && customDate[0] && customDate[1])){

            // Mix Graph
            setServerModalAjax(true);
            postDataAPI({
                dateFilter:activeDate.value==='Custom'?{from:convertDateFormate(customDate[0]),to:convertDateFormate(customDate[1])}:getDateByName(activeDate.value),
                serviceName: POST_KPI_DASHBOARD.serviceName,
                op: POST_KPI_DASHBOARD.server_performance_contribution_mix_op,
                mealPeriod:mealPeriod,
                server_id:server_id,
                page: selectedPageST,
            }).then((response) => {
                setServerModalAjax(false);
                if (response.status === "ok") {           
                    if(response.data != null){
                        setServerData_mix(response.data);                         
                        setmixgraph_display(true);                                      
                    }else{
                        setmixgraph_display(false);
                    }
                } else {
                    console.log(response);
                }
            });
            
        }
    },[activeDate,customDate, mealPeriod,server_id])
    
    /*********Mix Graph********/            
    const coverColor = [
        "#405189", "#0ab39c", "#f7b84b", "#f06548", "#33DAFF", "#299cdb", "#FFA233", "#C1FF33",
        "#BE33FF", "#FF33A5", "#33FF8D", "#3577f1", "#FF80C9", "#FFB3DE", "#FF8085", "#FFB3B6",
        "#FF80E6", "#FFB3F0", "#DE80FF", "#EBB3FF", "#AC80FF", "#CDB3FF", "#B3D7FF", "#80DEFF",
        "#B3EBFF", "#80FFB0", "#AEFF80", "#EEFF66", "#FFA34D", "#FFCB99", "#658b63"
    ];
    
    let mix_color_array = [];
    const colorPairs = serverSeries_mix !== undefined ? Math.ceil(serverSeries_mix.length / 2) : 0;
    
    if(serverSeries_mix !== undefined){
        for (let i = 0; i < colorPairs; i++) {
            let color = coverColor[i % coverColor.length];
            mix_color_array.push(color, color);
        }
        
        // Ensure mix_color_array has the same length as serverSeries_mix
        mix_color_array = mix_color_array.slice(0, serverSeries_mix.length);
    }else{
        mix_color_array = coverColor;
    }    
    
    var serverOptions_mix = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          dataLabels: {
            formatter: (val) => {            
                return val <= 3 && val > (-3) ? val : val + "%"
            }
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: serverOptionData_Mix,
            labels: {
              formatter: (val) => {
                return val
              }
            }
          },
          fill: {
            opacity: 1,
          },          
          colors: mix_color_array,
          legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            offsetX: 40
          },
          tooltip: {
            y: {
                formatter: function(val) {
                    return val + "%";
                }
            }
        },
        yaxis: {
            labels: {
                maxWidth: 250
            },
        },
    };
    return (
        <div>
            {/* Mix Graph */}
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Server performance contribution mix</h4>
                </CardHeader>

                <CardBody id="ServerPerformanceMixChart">
                        {
                            serverModalAjax && <Loader />
                        }
                        {
                        !serverModalAjax && serverSeries_mix && mixgraph_display ? (
                            <ReactApexChart
                            options={serverOptions_mix}
                            series={serverSeries_mix}
                            type="bar"
                            height={500}
                            />
                        ) : (
                            <p>No data</p>
                        )
                        }
                </CardBody>
            </Card>
        </div>
    )            
}
export default ServerPerformanceMixChart;