import React, { useEffect } from "react";
import CountUp from "react-countup";
import Placeholders from "../../Components/Common/Placeholders"
import { Card, CardBody, Col, Row } from 'reactstrap';
import {getLocalStorageData, displayDateV1, convertDateFormate, getDateByName} from "../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import {
    getKIPWidgetData,
  } from "../../store/actions";

const Widgets = ({ rightFilter, comparisonDate, leftFilter, mealPeriod, refresh, leftCustomDate}) => {
    const userOutletDetails = getLocalStorageData('userOutletDetails');
    const currency=userOutletDetails?.currency??"$";
    const dispatch = useDispatch();

    let rightFilterLabel = (rightFilter && rightFilter.value!=="Custom") ? rightFilter.label : "";
    if (rightFilter && rightFilter.value==="ThisMonth") {
        rightFilterLabel = "Month-to-date";
    } else if (rightFilter && rightFilter.value==="Custom" && comparisonDate.length > 0) {
        rightFilterLabel = displayDateV1(comparisonDate);
    }

     const {
        widgetData,
        widgetAjax,
      } = useSelector((state) => ({
        widgetData: state.Dashboard.widgetData,
        widgetAjax: state.Dashboard.widgetAjax
      }));

    useEffect(() => {
        localStorage.setItem('Selling_items_SortType', '');
        localStorage.setItem('Reccomendation_SortType', '');
        if(leftFilter.value!=='Custom' || (leftFilter.value==='Custom' && leftCustomDate[0] && leftCustomDate[1]) || refresh){
          
        console.log("aaaaa",rightFilter);
          dispatch(      
            getKIPWidgetData({
              leftFilter: leftFilter.value==='Custom'?{from:convertDateFormate(leftCustomDate[0]),to:convertDateFormate(leftCustomDate[1])}:getDateByName(leftFilter.value),
              rightFilter: rightFilter.value==='Custom'?{from:convertDateFormate(comparisonDate[0]),to:convertDateFormate(comparisonDate[1])}:getDateByName(rightFilter.value),  
              mealPeriod: mealPeriod,
              recalculate: refresh,
            })
          );
        }
    }, [leftFilter,leftCustomDate, rightFilter, mealPeriod,comparisonDate, refresh,  dispatch]);

    const ecomWidgets = [
        {
            id: 1,
            label: "Covers",
            percentage: !widgetAjax ? widgetData.covers.variance:'',
            counter: !widgetAjax ? widgetData.covers.value:'',
            bgcolor: "bg-danger",
            textcolor: "text-white",
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: ",",
        },
        {
            id: 2,
            label: "Table Occupancy",
            percentage: !widgetAjax ? widgetData.table_occupancy.variance:'',
            counter: !widgetAjax ? widgetData.table_occupancy.value:'',
            bgcolor: "bg-primary",
            textcolor: "text-white",
            decimals: 2,
            prefix: "",
            separator: "",
            suffix: "%"
        },
        {
            id: 3,
            label: "Seat Occupancy",
            percentage: !widgetAjax ? widgetData.seat_occupancy.variance:'',
            counter: !widgetAjax ? widgetData.seat_occupancy.value:'',
            bgcolor: "bg-danger",
            textcolor: "text-white",
            decimals: 2,
            prefix: "",
            suffix: "%"
        },
        {
            id: 4,
            label: "Average Check",
            percentage: !widgetAjax ? widgetData.average_check.variance:'',
            counter: !widgetAjax ? widgetData.average_check.value:'',
            bgcolor: "bg-primary",
            textcolor: "text-white",
            decimals: 2,
            prefix: currency,
            suffix: ""
        },
        {
            id: 5,
            label: "Revenue",
            percentage: !widgetAjax ? widgetData.revenue.variance:'',
            counter: !widgetAjax ? widgetData.revenue.value:'',
            bgcolor: "bg-danger",
            textcolor: "text-white",
            decimals: 0,
            prefix: currency,
            suffix: "",
            separator: ",",
        },
        {
            id: 6,
            label: "Revpash",
            percentage: !widgetAjax ? widgetData.revpash.variance:'',
            counter: !widgetAjax ? widgetData.revpash.value:'',
            bgcolor: "bg-primary",
            textcolor: "text-white",
            decimals: 2,
            prefix: currency,
            suffix: ""
        },
    ];
    
    return (
        <Row id="Widget">
            {ecomWidgets.map((item, key) => (
                <Col className='mb-4' xxl={2} lg={4} sm={6} key={key}>
                    <Card className={"card-animate mb-0 h-100 " + item.bgcolor}>                        
                        <CardBody>
                            {
                                widgetAjax && <Placeholders/>
                            }
                            <div className={widgetAjax?'opacity-0':''}>
                                <div className="d-flex align-items-center">                                
                                    <div className="flex-grow-1">
                                        <p className={"text-uppercase fw-medium text-truncate text-center mb-0 " + item.textcolor}>{item.label}</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-3 mb-3">
                                    <div>
                                        <h4 className={"fs-22 fw-semibold ff-secondary mb-0 "+ item.textcolor}><span className="counter-value" data-target="559.25">
                                            <CountUp
                                                start={0}
                                                prefix={item.prefix}
                                                suffix={item.suffix}
                                                separator={item.separator}
                                                end={item.counter}
                                                decimals={item.decimals}
                                                duration={1}
                                            />
                                        </span></h4>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <p className={"mb-0 text-center fs-12 " + item.textcolor}>
                                    {rightFilterLabel} : <span className="badge bg-light text-success mb-0">{ item.percentage }</span>
                                    </p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>)
            )}
        </Row>

    );
};

export default Widgets;