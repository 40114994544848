import React from "react";
import {
    Col,
  } from "reactstrap";
import FlashDatePicker from "../../Components/Common/FlashDatePicker";
import FoodCapital from "../Flash/FoodCapital";
import ForeCastCovers from "../Flash/ForeCastCovers";
import ForeCastRevOcc from "../Flash/ForeCastRevOcc";
import ForeCastTotalRev from "../Flash/ForeCastTotalRev";
import TrendAvgCheckCover from "../Flash/TrendAvgCheckCover";
//import Widget from "../DashboardEcommerce/Widgets";
import Widget from "../DashboardEcommerce/NewWidget";
import ServerPerformance from "../ServerPerformance/ServerPerformance";
import BestSellingProducts from "../DashboardEcommerce/BestSellingProducts";
import DiscountDetails from "../Discount/DiscountDetails";
import DiscountPerformance from "../Discount/DiscountPerformance";
import { RevenueTable } from "../TableSeatOptimization/RevenueTable";
import ServerTopBottomPerformanceTable from "../ServerPerformance/ServerTopBottomPerformanceTable";
import Server from "../ServerPerformance/Server";
import ServerPerformanceMixChart from "../ServerPerformance/ServerPerformanceMixChart";
import ForecastCoverRevenueChart from "../DashboardEcommerce/ForecastCoverRevenueChart";
import ForecastAvgRevOccChart from "../DashboardEcommerce/ForecastAvgRevOccChart";
import ForcastTableOccChart from "../DashboardEcommerce/ForcastTableOccChart";
import ForcastSeatOccChart from "../DashboardEcommerce/ForcastSeatOccChart";
import FoodTribesChart from "../MenuEngineering/FoodTribesChart";
import PortfolioAnalysis from "../MenuEngineering/PortfolioAnalysis";
import TableOccChart from "../TableSeatOptimization/TableOccChart";
import SeatOccChart from "../TableSeatOptimization/SeatOccChart";
import RevPashChart from "../TableSeatOptimization/RevPashChart";
import TableMixPartySizeChart from "../TableSeatOptimization/TableMixPartySizeChart";
import AvgCheckSpentSlotsChart from "../TableSeatOptimization/AvgCheckSpentSlotsChart";

const ChartsComponents = React.memo(({selectedCharts, leftFilter1, rightFilter, currency, leftCustomDate}) => {
    return (
        <>
        {selectedCharts.map((chart) => {
        const ChartComponent = chart.id;
        if (ChartComponent==="FoodCapital") {
          return <FoodCapital leftFilter={{ ...leftFilter1, default: true }} key={ChartComponent} mealPeriod={[]} />
        } else if (ChartComponent==="ForeCastCovers") {
          return <ForeCastCovers leftFilter={{ ...leftFilter1, default: true }} key={ChartComponent} mealPeriod={[]} />
        } else if (ChartComponent==="ForeCastRevOcc") {
          return <ForeCastRevOcc leftFilter={{ ...leftFilter1, default: true }} key={ChartComponent} mealPeriod={[]} />
        } else if (ChartComponent==="TrendAvgCheckCover") {
          return <TrendAvgCheckCover leftFilter={{ ...leftFilter1, default: true }} key={ChartComponent} mealPeriod={[]} />
        } else if (ChartComponent==="ForeCastTotalRev") {
          return <ForeCastTotalRev leftFilter={{ ...leftFilter1, default: true }} key={ChartComponent} mealPeriod={[]} />
        } else if (ChartComponent==="Widget") {
          return <Widget leftFilter={leftFilter1} key={ChartComponent} rightFilter={rightFilter}  />
        } else if (ChartComponent==="ServerPerformance") {
          return <ServerPerformance  currency={currency} showGraph={true} server_id={0} activeDate={leftFilter1} recalculate={true} mealPeriod={0} customDate={leftCustomDate} key={ChartComponent}  />
        } else if (ChartComponent==="BestSellingProducts") {
          return <BestSellingProducts currency={currency} showGraph={true} server_id={0} globalDate={leftFilter1} recalculate={true} mealPeriod={0} globalCustomDate={leftCustomDate} activeComparisonData={false} key={ChartComponent} defaultCourse={0}  />
        } else if (ChartComponent==="ForecastCoverRevenueChart") {
          return <ForecastCoverRevenueChart leftFilter={leftFilter1} leftCustomDate={leftCustomDate}  mealPeriod={0} rightFilter={rightFilter} key={ChartComponent}  />
        } else if (ChartComponent==="ForecastAvgRevOccChart") {
          return <ForecastAvgRevOccChart leftFilter={leftFilter1} leftCustomDate={leftCustomDate}  mealPeriod={0} rightFilter={rightFilter} key={ChartComponent}  />
        } else if (ChartComponent==="ForcastTableOccChart") {
          return <ForcastTableOccChart leftFilter={leftFilter1} leftCustomDate={leftCustomDate}  mealPeriod={0} rightFilter={rightFilter} key={ChartComponent}  />
        } else if (ChartComponent==="ForcastSeatOccChart") {
          return <ForcastSeatOccChart leftFilter={leftFilter1} leftCustomDate={leftCustomDate}  mealPeriod={0} rightFilter={rightFilter} key={ChartComponent}  />
        } else if (ChartComponent==="FoodTribesChart") {
          return <FoodTribesChart leftFilter={leftFilter1} leftCustomDate={leftCustomDate}  mealPeriod={0} rightFilter={rightFilter} key={ChartComponent}  />
        } else if (ChartComponent==="PortfolioAnalysis") {
          return <PortfolioAnalysis globalDate={leftFilter1} globalCustomDate={leftCustomDate}  mealPeriod={0} rightFilter={rightFilter} key={ChartComponent}  />
        } else if (ChartComponent==="DiscountDetails") {
          return <DiscountDetails leftFilter={leftFilter1} mealPeriod={0} key={ChartComponent} currency={currency}  />
        } else if (ChartComponent==="DiscountPerformance") {
          return <DiscountPerformance leftFilter={leftFilter1} mealPeriod={0} key={ChartComponent} currency={currency} />
        } else if (ChartComponent==="TableOccChart") {
          return <Col xl={4} key={ChartComponent}><TableOccChart activeDate={leftFilter1} mealPeriod={0} customDate={leftCustomDate} /></Col>
        } else if (ChartComponent==="SeatOccChart") {
          return <Col xl={4} key={ChartComponent}><SeatOccChart activeDate={leftFilter1} mealPeriod={0} customDate={leftCustomDate} /></Col>
        } else if (ChartComponent==="RevPashChart") {
          return <Col xl={4} key={ChartComponent}><RevPashChart activeDate={leftFilter1} mealPeriod={0} customDate={leftCustomDate} /></Col>
        } else if (ChartComponent==="TableMixPartySizeChart") {
          return <TableMixPartySizeChart activeDate={leftFilter1} mealPeriod={0} customDate={leftCustomDate} key={ChartComponent}  />
        } else if (ChartComponent==="AvgCheckSpentSlotsChart") {
          return <AvgCheckSpentSlotsChart activeDate={leftFilter1} mealPeriod={0} customDate={leftCustomDate} key={ChartComponent}  />
        } else if (ChartComponent==="RevenueTable") {
          return <RevenueTable activeDate={leftFilter1} mealPeriod={0} customDate={leftCustomDate} currency={currency} key={ChartComponent}  />
        } else if (ChartComponent==="ServerTopBottomPerformanceTable") {
          return <ServerTopBottomPerformanceTable currency={currency} activeDate={leftFilter1} customDate={leftCustomDate} mealPeriod={0} server_id={0} key={ChartComponent}  />
        } else if (ChartComponent==="Server") {
          return <Server currency={currency} activeDate={leftFilter1} customDate={leftCustomDate} mealPeriod={0} key={ChartComponent}  />
        } else if (ChartComponent==="ServerPerformanceMixChart") {
          return <ServerPerformanceMixChart currency={currency} activeDate={leftFilter1} customDate={leftCustomDate} mealPeriod={0} key={ChartComponent}  />
        } else {
          return ""
        }
        })}
        </>
    );
});
ChartsComponents.displayName = "ChartsComponents";
export default ChartsComponents;
