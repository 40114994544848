/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { TableMixByPartySize } from "./TableMixByPartySize";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_TABLE_SEAT_OPTIMIZATION } from "../../helpers/data_helper";
import Loader from "../../Components/Common/Loader";
import { getDateByName, coverColor } from "../../helpers/api_helper";

const TableMixPartySizeChart = ({activeDate, customDate, mealPeriod}) => {  
  const [tableMixPartyData, setTableMixPartyData] = useState({
    categories: [],
    series: [],
  });
  const [tableMixPartyAjax, setTableMixPartyAjax] = useState(true);
  const [allHaveCoverZero, setallHaveCoverZero] = useState(true);
  const [datacolorArray, setdatacolorArray] = useState([]);

  useEffect(() => {

    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;
        
    let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);

    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      setTableMixPartyAjax(true);
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.table_mixparty_op,
      }).then((response) => {
        setTableMixPartyAjax(false);
        if (response.status === "ok") {
          setTableMixPartyData(response.data);              
          setallHaveCoverZero(response.data.series.every(obj => obj.name === "Cover - 0"));

          for(let i = 0; i < response.data.series.length; i++){
            // clearArray();
            datacolorArray.push(getRandomColor());
          }


        } else {
          console.log(response);
        }
      });
    }
  }, [activeDate, customDate, mealPeriod]);

  function getRandomColor() {
    // Generate a random number between 0 and 7FFFFF (decimal equivalent of #7F7F7F in hexadecimal)
    var randomColor = Math.floor(Math.random() * 0x7FFFFF).toString(16);
  
    // Pad the string with zeros to ensure it is always six digits long
    return '#' + '0'.repeat(6 - randomColor.length) + randomColor;
  }      
  
  return (
    <Card>
        <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
            Table Mix By Party Size
        </h4>
        </CardHeader>
        <CardBody>
        {tableMixPartyAjax && <Loader />}
        <div id="TableMixPartySizeChart" className={tableMixPartyAjax ? "opacity-0" : ""}>
            <TableMixByPartySize
            data={tableMixPartyData}
            dataColors={coverColor}
            // dataColors='["--vz-primary", "--vz-success", "--vz-danger", "--vz-warning"]'
            allHaveCoverZero={allHaveCoverZero}
            />
        </div>
        </CardBody>
    </Card>
  );
};

export default TableMixPartySizeChart;
