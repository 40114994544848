/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody, Button,Modal,ModalHeader,ModalBody,FormFeedback,Form,Input,Label, ModalFooter } from "reactstrap";
import UiContent from "../../Components/Common/UiContent";
import Loader from "../../Components/Common/Loader"
import { currencyFormatWithAlert,convertDateFormate,getLocalStorageData } from "../../helpers/api_helper"
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector } from "react-redux";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServerUpselling = () => {
    const userOutletDetails = getLocalStorageData('userOutletDetails');
    const currency=userOutletDetails?.currency??"$";
    const [serverUpsellingAjax, setServerUpsellingAjax] = useState(false);
    const [filterEmployees, setFilterEmployees] = useState([]);
    const [filterEmployees_add, setFilterEmployees_add] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [filterItems_add, setFilterItems_add] = useState([]);
    const [courseItems, setCourseItems] = useState([]);
    const [courseItemAjax, setCourseItemAjax] = useState(false);
    const [server, setServer] = useState(0);    
    const [item, setItem] = useState(0);
    const [addUpsellingModal, setAddUpsellingModal] = useState(false);
    const [filtercourses, setFilterCourses] = useState([]);
    const [addItemAjax, setAddItemAjax] = useState(false);
    const [Qty_label, setQty_label] = useState("Revenue");
    const customStyles = {
        control: (provided) => ({
            ...provided,
            // width: 260,
        }),
    };
    const [modalConfirm, setModalConfirm] = useState(false);
    const [targetData, setTargetData] = useState({});
    const [firstLoaded, setFirstLoaded] = useState(true);

    const [selectedServers, setselectedServers] = useState("0");
    const handleServer = (singleOption) => {        
        setselectedServers(singleOption);
        setServer(singleOption.value);
    };
    
    const [selectedItems, setselectedItems] = useState("0");
    const handleItem = (singleOption) => {        
        setselectedItems(singleOption);
        setItem(singleOption.value);
    };

    const { serverList } = useSelector((state) => ({
        serverList: state.Dashboard.filterData.serverList,
    }));
    const { courses } = useSelector((state) => ({
        courses: state.Dashboard.filterData.courses,
    }));    

    const hideConfirmModal=() => {
        setModalConfirm(false);
    }
    const handleToastMessage = (type,message) => {
        switch(type){
          case "error":
            toast.error(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" });
            break;
          case "success":
            toast.success(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
            break;
        }
        
      }
    const [serverData,setServerData]=useState([])
    const loadUpSellingItems=()=>{
        // console.log("loadUpSellingItems");
        setServerUpsellingAjax(true);
        postDataAPI({
            serviceName: "item_data",
            op: 'upselling_list',
            item_id:item,
            server_id:server
        }).then((response) => {
            setServerUpsellingAjax(false);
            // console.log(response);
            if (response.status === "ok") {
                setServerData(response.data);
                if (firstLoaded) {
                    const serv_data = [];
                    const item_data = [];
                    response.data.forEach((dataItem) => {
                        if (dataItem.server_id && dataItem.server_name) {
                            serv_data.push({
                                value: dataItem.server_id,
                                label: dataItem.server_name
                            });
                        }

                        // Check if items array exists in the dataItem
                        if (dataItem.items && dataItem.items.length>0) {
                            dataItem.items.forEach((item) => {
                                if (item.item_id && item.item_name) {
                                    const existingItem = item_data.find(i => i.value === item.item_id);
                                    if (!existingItem && item.item_id !== "ALL") {
                                        item_data.push({
                                            value: item.item_id,
                                            label: item.item_name
                                        });
                                    }
                                }
                            });
                        }
                    });                
                    const updatedServerList = [{
                        "label": "All Servers",
                        "value": "0"
                    }, ...serv_data];
                    setFilterEmployees(updatedServerList);

                    item_data.sort((a, b) => a.label.localeCompare(b.label));
                    // console.log("ITEMS")
                    // console.log(item_data);
                    const updatedItemList = [{
                        "label": "All Items",
                        "value": "0"
                    }, ...item_data];
                    setFilterItems(updatedItemList);

                    setFirstLoaded(false);
                }

            }
        });
    }
    useEffect(()=>{
        loadUpSellingItems();
        
        if(selectedServers===false){            
            if(server){
                setselectedServers(server);        
            }else{                
                setselectedServers(filterEmployees[0]);                
            }
        }

        if(selectedItems===false){            
            if(item){
                setselectedItems(item);        
            }else{                
                setselectedItems(filterItems[0]);                
            }
        }

    },[server,item])
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
          server_id: { value: 0, label: "Select Server" },
          from:"",
          to: "",
          course_id:"",
          item_id: { value: 0, label: "Select Item", avg_check: 0 },
          target_type: "revenue",
          target: "",
          incentive_type:"",
          incentive_value:"",
          target_commission:"",
          target_revenue: "",
          roi_on_target: ""
        },
        validationSchema: Yup.object({
        //   server_id: Yup.string().required("Please select server"),
          server_id: Yup.object().shape({
            value: Yup.number().notOneOf([0], "Please select server"),
            label: Yup.string().required("Please select server")
          }).nullable().required("Please select server"),
          from: Yup.string().required("Please enter from date"),
          to: Yup.string().required("Please enter to date"),
          course_id: Yup.string().required("Please select course"),
            //   item_id: Yup.string().required("Please select item"),
            item_id: Yup.object().shape({
            value: Yup.number().notOneOf([0], "Please select item"),
            label: Yup.string().required("Please select item"),
            avg_check: Yup.number(),
          }).nullable().required("Please select server"),
          target_type: Yup.string().required("Please enter target type"),
          target: Yup.string().required("Please enter target"),
          incentive_type: Yup.string().required("Please select incentive type"),
          incentive_value: Yup.string().required("Please enter incentive"),
          target_commission:Yup.string()
          .when('incentive_type', {
            is: (incentive_type) => incentive_type=='percentage',
            then: Yup.string()
              .required('Please enter target commission')            
          }),
          target_revenue: Yup.string(),
          roi_on_target: Yup.string(),
        }),
        onSubmit: (values) => {   
            // Convert incentive_value to a number
            const updatedValues = {
                ...values,
                incentive_value: values.incentive_value ? parseFloat(values.incentive_value) : 0,
            };
            // console.log(updatedValues); 
            let res = values;
            res.server_id = res.server_id.value;
            res.item_id = res.item_id.value;
            setAddItemAjax(true);        
            postDataAPI({
                serviceName: "item_data",
                op: 'upselling_add',
                ...res
            }).then((response) => {
                // console.log(response);
                setAddItemAjax(false);
                if (response.status === "ok") {
                    handleToastMessage('success',response.message)                
                    hideAddUpsellingModal();
                    loadUpSellingItems();
                }else{
                    handleToastMessage('error',response.message)
                }
            });
        },
    });
    const showAddUpsellingModal=() => {
        setAddUpsellingModal(true);
    }
    const hideAddUpsellingModal=() => {
        validation.resetForm();
        setAddUpsellingModal(false);
    }
    const convertValue=(value)=>{
        if(value==''){
            return 0;
        }else{
            return parseFloat(value);
        }
    }
    useEffect(()=>{        
        if(validation.values.incentive_type=='fix'){
            let target_commission=(convertValue(validation.values.incentive_value)*convertValue(validation.values.target)).toFixed(2);
            validation.setFieldValue('target_commission',target_commission.toString());
        }   
        if (validation.values.target === "") {
            validation.setFieldValue('incentive_value',"");
        }

        //Calculation for Target Commission
        if (validation.values.target_type === "revenue") {
            if (validation.values.incentive_type === "lumpsum_amount") {
                validation.setFieldValue('target_commission',validation.values.incentive_value);
            }
            if (validation.values.incentive_type === "no_incentive") {
                validation.setFieldValue('target_commission',"0");
            }
            if (validation.values.incentive_type === "percentage") {
                const target_commission_per = (validation.values.target !== 0) ? ((validation.values.target/validation.values.incentive_value)).toFixed(2) : 0;
                validation.setFieldValue('target_commission',target_commission_per);
            }

            validation.setFieldValue('target_revenue',validation.values.target);
        }

        if (validation.values.target_type === "quantity") {
            let target_revenue=(convertValue(validation.values.item_id.avg_check)*convertValue(validation.values.target)).toFixed(2);
            if (validation.values.incentive_type === "percentage") {
                const incentivePercentage = parseFloat(validation.values.incentive_value) || 0; // Ensure it's a number
                const target_commission_per = target_revenue > 0 
                    ? parseFloat(((incentivePercentage / 100) * target_revenue).toFixed(2)) // Correct formula
                    : 0;
                validation.setFieldValue('target_commission',target_commission_per);
                //validation.setFieldValue('target_commission',"0");
            }
            if (validation.values.incentive_type === "lumpsum_amount") {
                validation.setFieldValue('target_commission',validation.values.incentive_value);
            }
            if (validation.values.incentive_type === "no_incentive") {
                validation.setFieldValue('target_commission',"0");
            }
            
            validation.setFieldValue('target_revenue',target_revenue);
        }

    },[validation.values.incentive_type,validation.values.incentive_value,validation.values.target,validation.values.target_type])
    
    useEffect(() => {
        const targetCommission = Number(validation.values.target_commission);
        const targetRevenue = Number(validation.values.target_revenue);
        if (targetCommission > 0 && targetRevenue > 0) {    
            const roi_val = (targetRevenue / targetCommission).toFixed(0);
            validation.setFieldValue('roi_on_target', roi_val);
        } else {
            validation.setFieldValue('roi_on_target', "0");
        }
    }, [validation.values.target_commission, validation.values.target_revenue]);
    

    const handleConfirmDeleteTarget=()=>{
        setAddItemAjax(true);        
        postDataAPI({
            serviceName: "item_data",
            op: 'upselling_delete',
            target_id:targetData.target_id
        }).then((response) => {
            setAddItemAjax(false);
            if (response.status === "ok") {
                handleToastMessage('success',response.message)                
                const serverFilterData=serverData.map((server)=>{
                    if(server.server_id==targetData.server_id){
                        server.items=server.items.filter((item)=>item.target_id!=targetData.target_id);
                    }
                    return server;          
                });
                setServerData(serverFilterData.filter((server)=>server.items.length>0));
                setModalConfirm(false);
            }else{
                handleToastMessage('error',response.message)
            }
        });        
    }
    const handleDeleteTarget=(server_id,target_id)=>{
        setTargetData({server_id:server_id,target_id:target_id})
        setModalConfirm(true);
    }
    useEffect(()=>{
        if(validation.values.course_id){
            getItems(validation.values.course_id, validation.values.server_id.value);
        }else{
            setCourseItems([]);
            setFilterItems_add([{
                "value": "0",
                "label": "Select Item",
                "avg_check": 0                
            }]);
            validation.setFieldValue('from',"");
            validation.setFieldValue('to',"");
            validation.setFieldValue('target',"");
            validation.setFieldValue('incentive_value',"");
        }
    },[validation.values.course_id])
    useEffect(()=>{
        setFilterCourses(courses);
    },[courses])
    useEffect(() => {
        if (serverList) {
            // const updatedServerList = [{
            //     "label": "All Servers",
            //     "value": "0"
            // }, ...serverList];
            // setFilterEmployees(updatedServerList);

            const updatedServerList_add = [{
                "label": "Select Server",
                "value": "0"
            }, ...serverList];
            setFilterEmployees_add(updatedServerList_add);        

            // Ensure that selectedServers is set to "0" on page load
            setselectedServers("0");
        }        
                        
    }, [serverList]);

    const getItems =(course_id, server_id) => {
        setCourseItemAjax(true);
        postDataAPI({
            serviceName: "item_data",
            op: 'fetch_item_dropdown',
            course_id:course_id,
            server_id:server_id
        }).then((response) => {
            //console.log(response);
            setCourseItemAjax(false);
            if (response.status === "ok" && response.data) {
                
                setCourseItems(response.data);
                setFilterItems_add([]);

                setTimeout(function() {
                    // Assuming response.data is fetched dynamically and you want to include the same two rows
                    let finalUpdatedResponse;
                    if (response.data.length > 0) {
                        finalUpdatedResponse = [
                            ...response.data
                        ];
                    } else {
                        finalUpdatedResponse = [
                            {
                                "value": "0",
                                "label": "Select Item",
                                "avg_check": 0
                            },
                            ...response.data
                        ];
                        validation.setFieldValue('item_id', {
                            "value": "0",
                            "label": "Select Item",
                            "avg_check": 0
                        });
                    }
            
                    // Set the final updated array to filterItems_add
                    setFilterItems_add(finalUpdatedResponse);
                }, 1000);
                
            }
        });
    }    
    useEffect(()=>{
        setServerUpsellingAjax(true);
        postDataAPI({
            serviceName: "item_data",
            op: 'fetch_item_dropdown',
            course_id:0,
            server_id:0
        }).then((response) => {
            setServerUpsellingAjax(false);
            if (response.status === "ok" && response.data) {
                //setFilterItems(response.data);

                if (response.data) {
                    // const updatedcourses = [...response.data];
                    // setFilterItems(updatedcourses);
        
                    const updatedcourses_add = [{
                        "value": "0",
                        "label": "Select Item"                
                    }, ...filterItems_add];
                    setFilterItems_add(updatedcourses_add);            
        
                    // Ensure that selectedCourses is set to "0" on page load
                    setselectedItems("0");
                }
            }
        });
    },[])
    
    // console.log('FilterItems',filterItems);

    const handleServerChange = (selectedOption) => {
        validation.setFieldValue('server_id', selectedOption);
    };

    const handleItemChange = (selectedOption) => {
        validation.setFieldValue('item_id', selectedOption);
        if (validation.values.target_type==="quantity") {
            validation.setFieldValue('target_revenue', (selectedOption.avg_check).toFixed(2));
        }
    };

    const hasError_server = validation.touched.server_id && validation.errors.server_id;    
    const customStyles_error_server = (hasError) => ({
        control: (base) => ({
            ...base,
            borderColor: hasError ? '#f06548' : base.borderColor,
            '&:hover': {
                borderColor: hasError ? '#f06548' : base['&:hover'].borderColor
            }
        })
    });
    const hasError_item = validation.touched.server_id && validation.errors.item_id;
    const customStyles_error_item = (hasError_item) => ({
        control: (base) => ({
            ...base,
            borderColor: hasError_item ? '#f06548' : base.borderColor,
            '&:hover': {
                borderColor: hasError_item ? '#f06548' : base['&:hover'].borderColor
            }
        })
    });
    
    const handleTargetTypeChange = (value) => {
        if (value === "revenue") {
            setQty_label("Revenue");
        }else {
            setQty_label("Quantity");
        }
    };

    const handleIncentiveTypeChange = (value) => {
        if (value === "no_incentive") {
            validation.setFieldValue("incentive_value", '0');
        } else {
            validation.setFieldValue("incentive_value", "");
        }
    };  

    console.log(validation.values.item_id);
    return <React.Fragment>
        <UiContent />
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Upselling" pageTitle="Dashboard" />
                <Row className="mb-4">
                    <Col sm={12} lg={4}>
                        {/* <select
                            className="form-select"
                            value={server}
                            id="server"
                            onChange={(event) => setServer(event.target.value)}
                        >
                            <option value="0">All Server</option>
                            {filterEmployees.length > 0 &&
                            filterEmployees.map((server) => {
                                return (
                                <option key={server.value} value={server.value}>
                                    {server.label}
                                </option>
                                );
                            })}
                        </select> */}
                        <Select
                            value={filterEmployees.find(option => option.value === selectedServers)}
                            isMulti={false}                            
                            onChange={handleServer}
                            options={filterEmployees}
                            styles={customStyles}
                        />
                    </Col>
                    <Col sm={12} lg={4}>
                        {/* <select
                            className="form-select"
                            value={item}
                            id="item"
                            onChange={(event) => setItem(event.target.value)}
                        >
                            <option value="0">All Item</option>
                            {filterItems.length > 0 &&
                            filterItems.map((item) => {
                                return (
                                <option key={item.item_id} value={item.item_id}>
                                    {item.item_name}
                                </option>
                                );
                            })}
                        </select> */}
                        <Select
                            value={filterItems.find(option => option.value === selectedItems)}
                            isMulti={false}                            
                            onChange={handleItem}
                            options={filterItems}
                            styles={customStyles}
                        />
                    </Col>
                    <Col sm={12} lg={4} className="text-end">
                        <Button color="primary" className="btn-label" onClick={showAddUpsellingModal}> <i className=" ri-add-fill label-icon align-middle fs-16 me-2"></i> Add </Button>
                    </Col>
                </Row>
                {
                    serverData.length === 0 ? (
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">No data !</h4>
                            </CardHeader>  
                        </Card>
                        ) : (
                            serverData.map((server)=>{                        
                                return <Card key={server.server_id}>
                                    <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{server.server_name}</h4>
                                    </CardHeader>                    
                                    <CardBody>
                                        {
                                            serverUpsellingAjax && <Loader />
                                        }
                                        <div className={
                                            serverUpsellingAjax
                                                ? "opacity-0 table-responsive table-card"
                                                : "table-responsive table-card"
                                        }>
                                            <table className="table align-middle table-nowrap table-bordered mb-0">
                                                <thead className="table-light">
                                                    <tr style={{ textAlign: 'center',verticalAlign: "middle" }}>
                                                        <th scope="col" rowSpan={2}>Item</th>
                                                        <th scope="col" rowSpan={2}>FROM</th>
                                                        <th scope="col" rowSpan={2}>TO</th>
                                                        <th scope="col" rowSpan={2}>Course</th>
                                                        <th scope="col" rowSpan={2}>Incentive</th>
                                                        <th scope="col" colSpan={4}>Target</th>
                                                        <th scope="col" colSpan={3}>Actual</th>
                                                        <th scope="col" rowSpan={2}>Action</th>
                                                    </tr>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th scope="col">Qty </th>
                                                        <th scope="col">Commission</th>
                                                        <th scope="col">Revenue</th>
                                                        <th scope="col">ROI</th>
                                                        <th scope="col">Qty</th>
                                                        <th scope="col">Revenue</th>
                                                        <th scope="col">Commission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        server.items.map((item)=>{
                                                            return <tr key={item.target_id}>
                                                                <td className="text-start">{item.item_name}</td>
                                                                <td className="text-center">{item.from}</td>
                                                                <td className="text-center">{item.to}</td>
                                                                <td className="text-center">{item.course_name}</td>
                                                                <td className="text-center">{item.incentive}</td>
                                                                <td className="text-center">{item.target}</td>
                                                                <td className="text-center">{currencyFormatWithAlert(item.target_commission,currency)}</td>
                                                                <td className="text-center">{currencyFormatWithAlert(item.target_revenue,currency)}</td>
                                                                <td className="text-center">{item.roi_on_target}</td>
                                                                <td className="text-center">{item.actual_sold}</td>
                                                                <td className="text-center">{currencyFormatWithAlert(item.item_revenue,currency)}</td>
                                                                <td className="text-center">{currencyFormatWithAlert(item.actual_commission,currency)}</td>
                                                                <td className="text-center">
                                                                <Button onClick={(event)=>{handleDeleteTarget(server.server_id,item.target_id)}} color="danger" className="btn-icon waves-effect waves-light"> <i className="ri-delete-bin-5-line" /> </Button>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card> 
                            })
                        )
                }
            </Container>
        </div>
        <Modal
            size="xl"
            isOpen={addUpsellingModal}
            toggle={hideAddUpsellingModal}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <Form
            className="form-horizontal"
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
            >
                <ModalHeader className={"modal-header title-full-width"}>
                    <div style={{"display":"flex","justify-content":"space-around"}}>
                        Add Upselling
                        <Button type="button" color={"link"} className="btn-close"
                            onClick={hideAddUpsellingModal} aria-label="Close"></Button>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Card className="ribbon-box border shadow-none">
                        <CardBody>
                            <div className="ribbon ribbon-primary round-shape">Define Who & What to Upsell</div>
                            <div className="ribbon-content mt-4 text-muted">
                                <Row>
                                <Col sm={12} lg={4}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Server
                                            <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                            name="server_id"
                                            className="form-control"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.server_id || ""}
                                            invalid={
                                            validation.touched.server_id &&
                                            validation.errors.server_id
                                                ? true
                                                : false
                                            }
                                        >
                                                <option value="">Select server</option>
                                                {filterEmployees.length > 0 &&
                                                filterEmployees.map((server) => {
                                                    return (
                                                    <option key={server.value} value={server.value}>
                                                        {server.label}
                                                    </option>
                                                    );
                                                })}
                                        </Input>
                                        {validation.touched.server_id &&
                                        validation.errors.server_id ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.server_id}
                                            </FormFeedback>
                                        ) : null} */}

                                        <Select
                                                value={validation.values.server_id}
                                                onChange={handleServerChange}
                                                options={filterEmployees_add}
                                                styles={customStyles_error_server(hasError_server)}
                                                onBlur={() => validation.setFieldTouched('server_id', true)}
                                            />
                                            {validation.touched.server_id && validation.errors.server_id ? (
                                                <div className="error_add">{validation.errors.server_id.label || validation.errors.server_id.value}</div>
                                            ) : null}

                                    </div>
                                </Col>
                                <Col sm={12} lg={4}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Course
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="course_id"
                                            className="form-control"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.course_id || ""}
                                            invalid={
                                            validation.touched.course_id &&
                                            validation.errors.course_id
                                                ? true
                                                : false
                                            }
                                        >
                                            <option value="">Select course</option>
                                            {filtercourses.length > 0 &&
                                            filtercourses.map((course) => {
                                                return (
                                                <option key={course.value} value={course.value}>
                                                    {course.label}
                                                </option>
                                                );
                                            })}
                                        </Input>
                                        {validation.touched.course_id &&
                                        validation.errors.course_id ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.course_id}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col sm={12} lg={4}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Item
                                            <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                            name="item_id"
                                            className="form-control"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.item_id || ""}
                                            invalid={
                                            validation.touched.item_id &&
                                            validation.errors.item_id
                                                ? true
                                                : false
                                            }
                                        >
                                            {courseItemAjax?<option value="">Loading items</option>:<>
                                            <option value="">Select item</option>
                                            {courseItems.length > 0 && <option value="0">All items</option> }
                                            {courseItems.length > 0 &&
                                            courseItems.map((item) => {
                                                return (
                                                <option key={item.item_id} value={item.item_id}>
                                                    {item.item_name}
                                                </option>
                                                );
                                            })}
                                            </>}
                                        </Input>
                                        {validation.touched.item_id &&
                                        validation.errors.item_id ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.item_id}
                                            </FormFeedback>
                                        ) : null} */}

                                        <Select
                                            value={validation.values.item_id}
                                            onChange={handleItemChange}
                                            options={filterItems_add}
                                            styles={customStyles_error_server(hasError_server)}
                                            onBlur={() => validation.setFieldTouched('item_id', true)}
                                        />
                                        {validation.touched.item_id && validation.errors.item_id ? (
                                            <div className="error_add">{validation.errors.item_id.label || validation.errors.item_id.value}</div>
                                        ) : null}

                                    </div>
                                </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>

                    {(validation.values.server_id.value !== 0) && validation.values.course_id && (validation.values.item_id.value !== 0) && (
                    <Card className="ribbon-box border shadow-none">
                        <CardBody>
                            <div className="ribbon ribbon-primary round-shape">Define the Target Timeframe</div>
                            <div className="ribbon-content mt-4 text-muted">
                                <Row>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            From
                                            <span className="text-danger">*</span>
                                        </Label>

                                        <Flatpickr
                                            name="from"
                                            className={`form-control flatpickr-input ${(validation.touched.from &&
                                                validation.errors.from
                                                    ? "is-invalid"
                                                    : "")}`}
                                            options={{
                                                dateFormat: "d M, Y",
                                                mode:"single",
                                                minDate: "today",
                                            }}
                                            onChange={([date])=>{
                                                validation.setFieldValue('from',convertDateFormate(date));
                                            }}
                                        />
                                        
                                        {validation.touched.from &&
                                        validation.errors.from ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.from}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            To
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Flatpickr
                                            name="to"
                                            className={`form-control flatpickr-input ${(validation.touched.to &&
                                                validation.errors.to
                                                    ? "is-invalid"
                                                    : "")}`}
                                            options={{
                                                dateFormat: "d M, Y",
                                                minDate: "today",
                                            }}
                                            onChange={([date])=>{
                                                validation.setFieldValue('to',convertDateFormate(date));
                                            }}
                                        />
                                        {validation.touched.to &&
                                        validation.errors.to ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.to}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                    )}
                    
                    {validation.values.from && validation.values.to && (
                    <Card className="ribbon-box border shadow-none">
                        <CardBody>
                            <div className="ribbon ribbon-primary round-shape">Define Target Type</div>
                            <div className="ribbon-content mt-4 text-muted">
                                <Row>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Target Type
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="target_type"
                                            className="form-control"
                                            type="select"
                                            onChange={(e) => {
                                                // Call the validation handler
                                                validation.handleChange(e);
                                                
                                                // Call the additional function
                                                handleTargetTypeChange(e.target.value);
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.target_type || ""}
                                            invalid={
                                            validation.touched.target_type &&
                                            validation.errors.target_type
                                                ? true
                                                : false
                                            }
                                        >
                                            <option value="revenue">Revenue</option>
                                            <option value="quantity">Quantity</option>
                                        </Input>
                                        {validation.touched.target_type &&
                                        validation.errors.target_type ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.target_type}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>

                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Target {Qty_label}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="target"
                                            className="form-control"
                                            type="number"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.target || ""}
                                            invalid={
                                            validation.touched.target &&
                                            validation.errors.target
                                                ? true
                                                : false
                                            }
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        {validation.touched.target &&
                                        validation.errors.target ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.target}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                    )}

                    {validation.values.target_type && validation.values.target && (
                    <Card className="ribbon-box border shadow-none">
                        <CardBody>
                            <div className="ribbon ribbon-primary round-shape">Define Incentive For the Server</div>
                            <div className="ribbon-content mt-4 text-muted">
                                <Row>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Incentive Type
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="incentive_type"
                                            className="form-control"
                                            type="select"                                   
                                            onChange={(e) => {
                                                validation.handleChange(e);
                                                handleIncentiveTypeChange(e.target.value);
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.incentive_type || ""}
                                            invalid={
                                            validation.touched.incentive_type &&
                                            validation.errors.incentive_type
                                                ? true
                                                : false
                                            }
                                        >
                                            <option value="">Select Incentive Type</option>
                                            {validation.values.target_type !== "revenue" && (
                                                <option value="fix">Incentive per item</option>
                                            )}
                                            <option value="percentage">Percentage of revenue</option>
                                            <option value="lumpsum_amount">Lumpsum amount</option>
                                            <option value="no_incentive">No Incentive</option>
                                        </Input>
                                        {validation.touched.incentive_type &&
                                        validation.errors.incentive_type ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.incentive_type}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Incentive
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <div  className={
                                                validation.touched.incentive_value &&
                                                validation.errors.incentive_value
                                                    ? "input-group is-invalid"
                                                    : "input-group"
                                                }>
                                            {/* {validation.values.incentive_type=='fix' && <span className="input-group-text">{currency}</span>} */}
                                            {["fix", "lumpsum_amount", "no_incentive"].includes(
                                                validation.values.incentive_type
                                            ) && <span className="input-group-text">{currency}</span>}
                                            <Input
                                                name="incentive_value"
                                                className="form-control"
                                                type="number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.incentive_value || ""}         
                                                invalid={
                                                    validation.touched.incentive_value &&
                                                    validation.errors.incentive_value
                                                        ? true
                                                        : false
                                                }  
                                                readOnly={validation.values.incentive_type === "no_incentive"}    
                                                onWheel={(e) => e.target.blur()}                         
                                            />
                                            {validation.values.incentive_type==='percentage' ? <span className="input-group-text">%</span> : ""}
                                        </div>                                
                                        {validation.touched.incentive_value &&
                                        validation.errors.incentive_value ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.incentive_value}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col> 
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                    )}

                    {validation.values.target_type && validation.values.target &&  (
                    <Card className="ribbon-box border shadow-none">
                        <CardBody>
                            <div className="ribbon ribbon-primary round-shape">Expected Outcome</div>
                            <div className="ribbon-content mt-4 text-muted">
                                <Row>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Target Commission
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <div  className={
                                                validation.touched.target_commission &&
                                                validation.errors.target_commission
                                                    ? "input-group is-invalid"
                                                    : "input-group"
                                                }>
                                            <span className="input-group-text">{currency}</span>
                                            <Input
                                                name="target_commission"
                                                className="form-control"
                                                type="number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.target_commission || ""}
                                                invalid={
                                                validation.touched.target_commission &&
                                                validation.errors.target_commission
                                                    ? true
                                                    : false
                                                }
                                                readOnly={true}
                                            />
                                        </div>
                                        {validation.touched.target_commission &&
                                        validation.errors.target_commission ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.target_commission}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            Target Revenue
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <div  className={
                                                validation.touched.target_revenue &&
                                                validation.errors.target_revenue
                                                    ? "input-group is-invalid"
                                                    : "input-group"
                                                }>
                                            <span className="input-group-text">{currency}</span>
                                            <Input
                                                name="target_revenue"
                                                className="form-control"
                                                type="number"
                                                value={validation.values.target_revenue || ""}
                                                readOnly={true}
                                            />
                                        </div>
                                        {validation.touched.target_revenue &&
                                        validation.errors.target_revenue ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.target_revenue}
                                            </FormFeedback>
                                        ) : null}
                                        {validation.values.item_id.avg_check <= 0 ? (<span className="text-danger">Price for the selected item is zero.</span>) : ""}
                                    </div>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">
                                            ROI on target
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <div  className={
                                                validation.touched.roi_on_target &&
                                                validation.errors.roi_on_target
                                                    ? "input-group is-invalid"
                                                    : "input-group"
                                                }>
                                            <Input
                                                name="roi_on_target"
                                                className="form-control"
                                                type="number"
                                                value={validation.values.roi_on_target || ""}
                                                readOnly={true}
                                            />
                                        </div>
                                        {validation.touched.roi_on_target &&
                                        validation.errors.roi_on_target ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.roi_on_target}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                    )}
                   
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={hideAddUpsellingModal} color="light">Cancel</Button>
                    <Button type="submit" color="primary" disabled={addItemAjax}>Add</Button>
                    {addItemAjax && <Loader />}
                </ModalFooter>
            </Form>
        </Modal>
        <Modal
                isOpen={modalConfirm}
                toggle={hideConfirmModal}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center">
                    <h1 style={{fontSize:"90px",lineHeight:"0.5"}}><i className="ri-information-line text-warning"></i></h1>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure you want to delete?</h4>
                        <h5 className="text-danger">Please be aware, this action CAN NOT be undone.</h5>
                        <div className="hstack gap-2 justify-content-center mt-4">
                            <Button color="light" onClick={hideConfirmModal}>Close</Button>
                            <Button onClick={handleConfirmDeleteTarget} className="btn btn-danger">Delete</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        <ToastContainer />
    </React.Fragment>
}

export default ServerUpselling;