import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "reactstrap";
import {
    currencyFormatWithAlert,
} from "../../helpers/api_helper";

const PerformanceExport = ({ discountItem, limit, getPercentage, getBgColor, maxValues }) => {
  const exportToExcel = () => {
    if (discountItem.length === 0) return; // Prevent error if no data

    const wb = XLSX.utils.book_new();

    // Extract Headers Dynamically from `discountItem[0]`
    const headers = discountItem[0].map((header) => header.replace(/'/g, ''));

    // Prepare Data Rows (Skipping the first row since it's used as headers)
    const wsData = [
      [
        limit !== "all" ? `Top ${limit} \n DISCOUNT PERFORMANCE` : "DISCOUNT PERFORMANCE",
        "COVERS", "", "", "", "", "", "",
        "AVERAGE CHECK", "", "", "", ""
      ],
      headers, 
      ...discountItem.slice(1).map((row) =>
        row.map((cell, index) => {
          if (index === 9 || index === 10 || index === 11 || index === 12) {
            return currencyFormatWithAlert(cell);
          } else if (index === 2 || index === 4 || index === 6 || index === 8) {
            return isNaN(cell) ? cell.replace(/'/g, '') : cell.replace(/'/g, '') + '%';
          }
          return cell.replace(/'/g, '');
        })
      ),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Merge Header Cells
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, // DISCOUNT PERFORMANCE merged
      { s: { r: 0, c: 1 }, e: { r: 0, c: 7 } }, // COVERS merged
      { s: { r: 0, c: 8 }, e: { r: 0, c: 12 } }, // AVERAGE CHECK merged
    ];

    // Apply Main Header Style
    ws["A1"].s = { 
      fill: { fgColor: { rgb: "FF0000" } }, // Red background
      font: { bold: true, color: { rgb: "FFFF00" } }, // Yellow text
      alignment: { horizontal: "center", vertical: "center" }
    };

    // Apply Conditional Background Colors and Center Alignment for rows after the 3rd row
    for (let rowIndex = 2; rowIndex < wsData.length; rowIndex++) {  // Skip first 2 rows (Header + Column Names)
      for (let colIndex = 1; colIndex < headers.length; colIndex++) { // Start from column 1 (ignoring first column)
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });

        if (!ws[cellRef]) continue; // Skip if no cell exists

        const value = Number(wsData[rowIndex][colIndex]) || 0;
        const percentage = getPercentage(value, maxValues[colIndex] || 1);
        const bgColor = getBgColor(percentage).replace("#", ""); // Remove '#' for Excel format

        // Ensure cell exists before applying styles
        if (!ws[cellRef]) ws[cellRef] = {};

        // Apply background color, font color, and text alignment
        ws[cellRef].s = {
          fill: { fgColor: { rgb: bgColor } }, // Apply background color
          font: { bold: false, color: { rgb: "000000" } }, // Text color black
          alignment: { horizontal: "center", vertical: "center" }, // Center text
        };
      }
    }

    // Add Sheet to Workbook
    XLSX.utils.book_append_sheet(wb, ws, "Discount Performance");

    // Save File
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(new Blob([excelBuffer], { type: "application/octet-stream" }), "discount_performance.xlsx");
  };

  return (
    <Button className="ms-3" color="primary" onClick={exportToExcel}>
      Export
    </Button>
  );
};

export default PerformanceExport;
