import React, { useState, useEffect } from "react";
import {
  Card,
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import { ColorRange } from "./HeatmapCharts";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import {
  getDateByName,
  convertDateFormate,
} from "../../helpers/api_helper";

const ForcastSeatOccChart = ({mealPeriod, leftFilter, rightFilter, leftCustomDate, activeComparisonData, comparisonDate}) => {
  const [forecastHeatmapAjax, setForecastHeatmapAjax] = useState(true);
  const [forecastHeatmapData, setForecastHeatmapData] = useState({
    table: [],
    seat: [],
  });

  useEffect(() => {
    if (
      leftFilter.value !== "Custom" ||
      (leftFilter.value === "Custom" && leftCustomDate[0] && leftCustomDate[1])
    ) {
      setForecastHeatmapAjax(true);
      postDataAPI({
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.forecast_heatmap_op,
        leftFilter:
          leftFilter.value === "Custom"
            ? {
                from: convertDateFormate(leftCustomDate[0]),
                to: convertDateFormate(leftCustomDate[1]),
              }
            : getDateByName(leftFilter.value),
        rightFilter:
          activeComparisonData && activeComparisonData.datepiker
            ? {
                from: convertDateFormate(comparisonDate[0]),
                to: convertDateFormate(comparisonDate[1]),
              }
            : getDateByName(rightFilter.value),
        mealPeriod: mealPeriod,
      }).then((response) => {
        setForecastHeatmapAjax(false);
        if (response.status === "ok") {
          setForecastHeatmapData(response.data);
        } else {
          console.log(response);
        }
      });
    }
  }, [
    leftFilter,
    leftCustomDate,
    activeComparisonData,
    rightFilter,
    mealPeriod,
    comparisonDate,
  ]);

  return (
    <Card>
      <div className="card-header px-2">
        <h4 className="card-title mb-0">
          Forecast Seat Occupancy
        </h4>
      </div>

      <div id="ForcastSeatOccChart" className="card-body px-2">
        {forecastHeatmapAjax && <Loader />}
        <div
          className={forecastHeatmapAjax ? "opacity-0" : ""}
          style={{ maxWidth: "600px", margin: "0 auto" }}
        >
          <ColorRange
            forecastHeatmapData={forecastHeatmapData.seat}
            dataText="Average Occ"
            // showYaxis={false}
            dataColors='["--vz-danger", "--vz-secondary", "--vz-warning","--vz-success"]'
          />
        </div>
      </div>
    </Card>
  );
};

export default ForcastSeatOccChart;
